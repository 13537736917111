import React from 'react'

import { FrankieUtilityImg } from 'shared/frankie-image'
import { PdfPreview } from 'shared/pdf-preview/ui/pdf-preview'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

type ActiveItem = {
  id: string
  type: 'PHOTO' | 'VIDEO' | 'PDF'
  url: string
}

type BiometricMediaProps = {
  activeItem: ActiveItem
}

export function BiometricMedia({
  activeItem,
}: React.PropsWithChildren<BiometricMediaProps>) {
  switch (activeItem.type) {
    case 'PHOTO':
      return (
        <FrankieUtilityImg key={activeItem.id} gallery={[activeItem.url]} />
      )
    case 'VIDEO':
      return (
        <video
          loop
          controls
          onPlay={() => {
            trackingManager.track(
              TrackingEventsTypes.WorkflowEventsBiometricsComparePlay,
            )
          }}
          key={activeItem.id}
          className="aspect-square mx-auto bg-tertiary-grey-500 rounded-sm"
        >
          <track kind="captions" />
          <source src={activeItem.url} type="video/mp4" />
        </video>
      )
    case 'PDF':
      return (
        <PdfPreview
          url={activeItem.url}
          documentProps={{ loading: null }}
          pageProps={{ pageNumber: 1 }}
        />
      )
    default:
      return null
  }
}
