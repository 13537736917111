import { useMemo } from 'react'

import { type ServiceProfile } from 'entities/entity'
import { WORKFLOW_EXECUTION_ID_QUERY_PARAM } from 'entities/workflow'

export const useActiveNav = (
  searchParams: URLSearchParams,
  workflowSummaries?: ServiceProfile['workflowSummaries'],
) =>
  useMemo(() => {
    const executionId = searchParams.get(WORKFLOW_EXECUTION_ID_QUERY_PARAM)

    if (!executionId) return workflowSummaries?.at(0)?.workflowName

    const found = workflowSummaries?.find(
      summary => summary.workflowExecutionId === executionId,
    )

    return found?.workflowName ?? workflowSummaries?.at(0)?.workflowName
  }, [workflowSummaries, searchParams])
