import React, { useMemo, useState } from 'react'

import classNames from 'classnames'

import { DocumentAttachment, DocumentInformation } from 'entities/entity'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { GalleryImage } from 'shared/document-thumbs/ui/document-thumbs'
import { getDataFileUrl } from 'shared/file'
import { FrankieUtilityImg } from 'shared/frankie-image'
import { useI18n } from 'shared/i18n'
import { GalleryItem, ImageGallery } from 'shared/image-gallery'
import { TrackingEventsTypes } from 'shared/tracking'

import { DOCUMENT_F2_KEY, documentF2En } from '../../locale/document-f2.en'
import { documentIdTypesOption } from '../../model/document-id-types.model'

type Props = {
  document: DocumentInformation
  className?: string
  extraData: { label: string; value?: string }[]
  trackingEvents?: {
    gallery: TrackingEventsTypes[]
    rotate: TrackingEventsTypes
    zoom: TrackingEventsTypes
    show: TrackingEventsTypes
  }
}

type DocumentOverlayProps = {
  document: Props['document']
  extraData: Props['extraData']
  trackingEvents: Props['trackingEvents']
  urls: GalleryImage[]
  index?: number
  documentList?: DocumentInformation[]
}

export function DocumentOverlay(props: DocumentOverlayProps) {
  const {
    document: _document,
    index,
    extraData,
    urls,
    trackingEvents,
    documentList,
  } = props

  const [activeIndex, setActiveIndex] = useState(
    () =>
      documentList?.findIndex(doc => doc.documentId === _document.documentId) ??
      0,
  )
  const document = useMemo(
    () => documentList?.[activeIndex] ?? _document,
    [activeIndex, _document, documentList],
  )

  const documentGallery = useMemo(() => {
    if (!documentList) return []
    const attachmentToDataUrl = (attachment?: DocumentAttachment) => {
      if (!attachment?.data.base64 || !attachment.mimeType) return ''
      return getDataFileUrl(attachment.mimeType, attachment.data.base64)
    }

    return documentList
      .map(doc => {
        const attachment = doc.attachments?.at(0)
        if (!attachment) return null
        return {
          id: attachment.attachmentId,
          url: attachmentToDataUrl(attachment),
          type: attachment.type,
          side: attachment.side,
          createdAt: attachment.createdAt,
        } as GalleryItem
      })
      .filter(Boolean) as GalleryItem[]
  }, [documentList])

  const t = useI18n(DOCUMENT_F2_KEY, { keys: documentF2En })

  const { tKey: documentLabelTypes = '' } =
    documentIdTypesOption.find(({ value }) => value === document.type) ?? {}

  return (
    <div
      className={classNames(
        'flex flex-wrap max-w-[850px] gap-x-4 gap-y-4 min-h-[450px]',
        {
          'w-[600px]': extraData.length === 0,
        },
      )}
    >
      <h2 className="text-xl basis-full font-bold leading-7">
        {documentLabelTypes ? t(documentLabelTypes) : document.type}
      </h2>

      <div className="flex flex-col gap-y-3">
        <p className="text-tertiary-grey-500 text-sm leading-5">
          {t('uploadedAt', {
            date: formatDate(
              document.createdAt ?? new Date().toUTCString(),
              DateFormatTypes.DateNumbersSlash,
            ),
            time: formatDate(
              document.createdAt ?? new Date().toUTCString(),
              DateFormatTypes.Time24HoursWithSeconds,
            ),
          })}
        </p>
        <FrankieUtilityImg
          defaultIdx={index}
          gallery={urls as GalleryItem[]}
          className="!w-[610px] !h-[360px]"
          trackingEvents={trackingEvents}
        />
      </div>
      {extraData.length > 0 && (
        <div className="w-[220px] max-h-[495px] overflow-y-auto">
          {extraData.map(({ label, value }) => (
            <React.Fragment key={label}>
              <p className="mt-3 font-medium text-sm text-tertiary-grey-800">
                {label}
              </p>
              <p
                data-hj-suppress
                className="font-normal text-tertiary-grey-700"
              >
                {value ?? '-'}
              </p>
            </React.Fragment>
          ))}
        </div>
      )}
      <ImageGallery
        gallery={documentGallery}
        selected={activeIndex}
        onChange={idx => {
          setActiveIndex(idx)
        }}
      />
    </div>
  )
}
