import React, { useState } from 'react'

import { UseFormReturn, useFieldArray } from 'react-hook-form'

import { FrankieButton, FrankiePopover } from 'frankify/src'

import { AddressCategoryTypes } from 'entities/applicant'

import { useI18n } from 'shared/i18n'

import { SingleAddressForm } from './single-address-form/single-address-form'
import { INDIVIDUAL_PROFILE_F2_KEY } from '../../individual-profile-f2.key'
import { individualProfileF2En } from '../../locale/individual-profile-f2.en'
import { IIndividualProfileF2FormType } from '../../model/individual-profile-f2-form.model'
import {
  AddressFormF2Qa,
  addressOptionQa,
} from '../../qa/individual-profile-f2.qa'

type Props = {
  form: UseFormReturn<IIndividualProfileF2FormType>
  isOptional?: boolean
  country?: string
}

export function AddressForms({ form, isOptional, country }: Props) {
  const [open, setOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState<null | number>(null)
  const t = useI18n(INDIVIDUAL_PROFILE_F2_KEY, { keys: individualProfileF2En })

  const { control } = form

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'addresses',
  })

  const filteredOptions = [
    {
      value: AddressCategoryTypes.previous,
      label: t('RESIDENTIAL2'),
    },
    {
      value: AddressCategoryTypes.mailing,
      label: t('POSTAL'),
    },
  ].filter(option => !fields.find(field => field.type === option.value))

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleSelectOption = (option: (typeof filteredOptions)[0]) => () => {
    setOpen(false)

    // setTimeout is used to prevent the following error:
    // If I left the current residential address blank then I added another address, the newly added address already shows an error even if I haven’t clicked on the textbox yet. To resolve this, when I click the add new address, make sure that the focus is in that current textbox.
    setTimeout(
      () =>
        append({
          country,
          type: option.value,
        }),
      0,
    )
  }

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLLIElement>,
  ) => {
    switch (e.key) {
      case 'ArrowUp':
      case 'ArrowDown':
        e.preventDefault()
        if (open) {
          setSelectedIndex(prevIndex => {
            let newIdx = 0
            if (prevIndex !== null) {
              newIdx =
                e.key === 'ArrowDown'
                  ? Math.min(filteredOptions.length - 1, prevIndex + 1)
                  : Math.max(0, prevIndex - 1)
            }

            const selectedOption = document.querySelector(
              `.frankie-select-option-${newIdx}`,
            )

            selectedOption?.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
            })

            return newIdx
          })
        } else handleOpen()

        break
      case 'Enter':
        if (selectedIndex !== null) {
          // const { value, label } = filteredOptions[selectedIndex]
          // onChange({ target: { value } })
          // setFilterText(label)
          handleSelectOption(filteredOptions[selectedIndex])()
          handleClose()
          setSelectedIndex(null)
        }
        break
      case 'Escape':
        handleClose()
        break
      default:
        e.preventDefault()
        break
    }
  }

  const handleToggle = () => {
    setOpen(o => !o)
  }

  const showRemoveAddress = (type?: AddressCategoryTypes) =>
    type !== AddressCategoryTypes.current

  return (
    <div data-qa={AddressFormF2Qa.wrapper}>
      <div className="flex flex-col gap-8">
        {fields.map((field, idx) => (
          <div key={field.id} className="flex flex-col w-full">
            <h2 className="text-lg  text-tertiary-grey-800  font-bold mb-4">
              {field.type && t(field.type)}
            </h2>

            <SingleAddressForm idx={idx} form={form} isOptional={isOptional} />

            {showRemoveAddress(field.type) && (
              <div className="flex items-start justify-end">
                <FrankieButton
                  onClick={() => remove(idx)}
                  noStyles
                  className="text-primary-800"
                >
                  {t('removeAddress')}
                </FrankieButton>
              </div>
            )}
          </div>
        ))}
      </div>

      {filteredOptions.length > 0 && (
        <FrankiePopover
          open={open}
          onOpenChange={setOpen}
          popoverRest={{ placement: 'bottom-start' }}
          trigger={
            <FrankieButton
              onClick={handleToggle}
              noStyles
              testId={{ button: AddressFormF2Qa.addAddress }}
              className="!text-primary-800 mt-4"
            >
              {t('addressForm.addAnotherAddress')}
            </FrankieButton>
          }
        >
          <ul className=" bg-mono-white shadow-md rounded-sm min-w-[180px] max-h-[193px] py-1 overflow-y-auto scrollbar-sm ">
            {filteredOptions.map((option, index) => (
              <li
                role="option"
                aria-selected={index === selectedIndex}
                key={option.value}
                data-qa={addressOptionQa(option.value)}
                onClick={handleSelectOption(option)}
                onKeyDown={handleKeyDown}
                className="text-sm text-tertiary-grey-800 py-2 px-4 cursor-pointer hover:bg-primary-50"
              >
                {option.label}
              </li>
            ))}
          </ul>
        </FrankiePopover>
      )}
    </div>
  )
}
