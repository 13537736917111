import { useCallback, useEffect } from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  NavigateOptions,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

import {
  TPath,
  Vue2ReactMigrationFlagTypes,
  useGetPathValue,
} from 'entities/session'

type ApplicantPathData = {
  applicantId: string
  applicantPath: string
}

const useReactOnlyRoutes =
  window.location.hostname === 'localhost' ||
  window.location.hostname.endsWith('frankie.one')

// TODO: Need to manage the paths in a better way
export const mainNavPaths = {
  applicant: '/entities',
  needsReview: '/needs-review',
  searchEntities: '/search-results',
  customViews: '/custom-views',
  blocklist: {
    fallbackValue: '/blocklist',
    protectedValue: useReactOnlyRoutes ? '/blocklist' : '/new/blocklist',
    reactMigrationFlag: Vue2ReactMigrationFlagTypes.ReactBlocklistPage,
  },
} satisfies Record<string, TPath>

type Args = {
  applicantIdParamKey?: string
}

const ApplicantLocationQueryKey = ['applicant-location-query-key']
type OriginatedPathQueryData = {
  originatedPath: string
  previousOriginatedPath?: string
}

const useOriginatedPathQuery = () => {
  const queryClient = useQueryClient()

  const { data: originatedPathData, refetch: refetchOriginatedPath } =
    useQuery<OriginatedPathQueryData>({
      queryKey: ApplicantLocationQueryKey,
      queryFn: () => {
        const data = queryClient.getQueryData<OriginatedPathQueryData>(
          ApplicantLocationQueryKey,
        )

        if (data) return data

        return {
          originatedPath: '',
        } as OriginatedPathQueryData
      },
    })

  const updateOriginatedPathData = useCallback(
    (originatedPath: string) => {
      const originatedPathData =
        queryClient.getQueryData<OriginatedPathQueryData>(
          ApplicantLocationQueryKey,
        )

      const hasPreviousOriginatedPathChanged =
        originatedPath.split('?')[0] !==
        originatedPathData?.originatedPath.split('?')[0]

      const previousOriginatedPath = hasPreviousOriginatedPathChanged
        ? originatedPathData?.originatedPath
        : originatedPathData.previousOriginatedPath

      queryClient.setQueryData<OriginatedPathQueryData>(
        ApplicantLocationQueryKey,
        { originatedPath, previousOriginatedPath },
      )
      void refetchOriginatedPath()
    },
    [queryClient, refetchOriginatedPath],
  )

  return { originatedPathData, refetchOriginatedPath, updateOriginatedPathData }
}

const ApplicantsPathDataQueryKey = ['applicant-path-data-query-key']
type PreviousApplicantsPathQueryData = {
  previousApplicantPath: ApplicantPathData[]
}

const usePerviousApplicantPathQuery = () => {
  const queryClient = useQueryClient()

  const { data: applicantsPathData, refetch: refetchApplicantPath } =
    useQuery<PreviousApplicantsPathQueryData>({
      queryKey: ApplicantsPathDataQueryKey,
      queryFn: () => {
        const data = queryClient.getQueryData<PreviousApplicantsPathQueryData>(
          ApplicantsPathDataQueryKey,
        )

        if (data) return data

        return {
          previousApplicantPath: [],
        } as PreviousApplicantsPathQueryData
      },
    })

  const updateApplicantsPerviousPathData = useCallback(
    (previousApplicantPath: ApplicantPathData[]) => {
      queryClient.setQueryData<PreviousApplicantsPathQueryData>(
        ApplicantsPathDataQueryKey,
        { previousApplicantPath },
      )

      void refetchApplicantPath()
    },
    [queryClient, refetchApplicantPath],
  )

  return {
    applicantsPathData,
    updateApplicantsPerviousPathData,
  }
}

export const useSetOriginatedPath = () => {
  const location = useLocation()
  const { getPathValue } = useGetPathValue()
  const { updateOriginatedPathData } = useOriginatedPathQuery()

  const setOriginatedPath = (originatedPath?: string) => {
    const locationPath = location.pathname
    const isValidOriginatedPath =
      [
        mainNavPaths.applicant,
        mainNavPaths.needsReview,
        mainNavPaths.blocklist,
        mainNavPaths.blocklist,
        mainNavPaths.searchEntities,
        mainNavPaths.customViews,
      ]
        .map(path => getPathValue(path))
        .findIndex(path =>
          originatedPath
            ? originatedPath.split('?')[0] === path
            : locationPath === path,
        ) !== -1

    if (isValidOriginatedPath) {
      const resultOriginatedPath = originatedPath ?? locationPath
      updateOriginatedPathData(resultOriginatedPath)
    }
  }

  useEffect(() => {
    setOriginatedPath(`${location.pathname}${location.search}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return { setOriginatedPath }
}

export const useUpdatePreviousApplicantPath = ({
  applicantIdParamKey,
}: Args) => {
  const param = useParams()
  const location = useLocation()

  const { applicantsPathData, updateApplicantsPerviousPathData } =
    usePerviousApplicantPathQuery()

  // Attaching the search query to the originatedPath and adding default value if path matches
  useEffect(() => {
    if (!applicantIdParamKey) return

    const applicantId = param[applicantIdParamKey]

    if (applicantId) {
      const prevPaths = applicantsPathData?.previousApplicantPath ?? []
      const lastPushedApplicant = prevPaths.pop()

      const newApplicantPath: ApplicantPathData = {
        applicantId,
        applicantPath: location.pathname,
      }

      const isGoingBack = prevPaths.at(-1)?.applicantId === applicantId

      // No need to push if going pack
      if (isGoingBack) {
        updateApplicantsPerviousPathData(prevPaths)
        return
      }

      // Either updating path or pushing new path
      if (lastPushedApplicant) {
        updateApplicantsPerviousPathData([
          ...prevPaths,
          ...(lastPushedApplicant.applicantId === applicantId
            ? [newApplicantPath]
            : [lastPushedApplicant, newApplicantPath]),
        ])

        return
      }

      // Pushing the first path
      updateApplicantsPerviousPathData([
        { applicantId, applicantPath: location.pathname },
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  // Clearing out the applicant path data when the applicant layout is unmounted
  useEffect(
    () => () => {
      updateApplicantsPerviousPathData([])
    },
    [updateApplicantsPerviousPathData],
  )
}

export const useHandleBack = () => {
  const navigate = useNavigate()

  const { originatedPathData } = useOriginatedPathQuery()

  const { applicantsPathData } = usePerviousApplicantPathQuery()

  const handleBack = useCallback(
    (options?: NavigateOptions) => {
      if (
        applicantsPathData &&
        applicantsPathData.previousApplicantPath.length >= 2
      ) {
        const previousApplicantPathData =
          applicantsPathData.previousApplicantPath
        navigate(
          previousApplicantPathData[previousApplicantPathData.length - 2]
            .applicantPath,
          options,
        )
      } else if (originatedPathData?.originatedPath)
        navigate(originatedPathData.originatedPath, options)
      else navigate(mainNavPaths.applicant, options)
    },
    [applicantsPathData, originatedPathData, navigate],
  )

  return { handleBack }
}

export const useGetPreviousPath = () => {
  const { originatedPathData } = useOriginatedPathQuery()

  if (originatedPathData?.previousOriginatedPath)
    return originatedPathData.previousOriginatedPath
  return mainNavPaths.applicant
}
