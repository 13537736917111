export interface IAppVersionInfo {
  version: string
  hash?: string
  buildTime?: string
}

// This is the type for the webpack DefinePlugin VERSION variable
declare const VERSION: IAppVersionInfo

/**
 * Initializes the `window.__PORTAL_VERSION__` object with the current version
 * of the application.
 *
 * The object contains the following properties:
 * - `version`: the semantic version of the application
 * - `hash`: the git hash of the application
 * - `buildTime`: the timestamp of the application build
 *
 * `hash` only available in development, staging, and latest environemnt, and
 * `buildTime` will be stripped during development.
 */
export function initAppInfo() {
  try {
    if (window.__PORTAL_VERSION__ === undefined) {
      window.__PORTAL_VERSION__ = Object.fromEntries(
        Object.entries({
          version: VERSION.version,
          hash: VERSION.hash,
          buildTime: VERSION.buildTime,
        }).filter(([, value]) => value),
      ) as unknown as IAppVersionInfo
    }
  } catch {
    // noop
  }
}
