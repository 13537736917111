import { ApplicantsSearchParamsTypes } from 'features/applicants-filter'
import { ProfileSearchParamsTypes } from 'features/profiles-filter'

import { ApplicantEntityTypes } from 'entities/applicant'

import { hasElement } from 'shared/array'
import { Serializable } from 'shared/local-storage'
import { Nullable } from 'shared/typescript'

export const GLOBAL_SEARCH_VALUE_MAX_LENGTH = 100
export const GLOBAL_SEARCH_HISTORY_MAX_LENGTH = 5

export const GLOBAL_SEARCH_SEARCH_BY_PARAM = 'globalSearchBy'

export enum SearchByTypes {
  IndividualName = 'individualName',
  OrganisationName = 'organisationName',
  CustomerId = 'customerId',
  FrankieId = 'frankieId',
  AbnOrAcn = 'abnOrAcn',
}

const SearchByTypesFlat: string[] = Object.values(SearchByTypes)

export const searchByI18nMap: (
  isFrankie2Customer: boolean,
) => Record<SearchByTypes, string> = isFrankie2Customer => ({
  [SearchByTypes.IndividualName]: 'searchByIndividualName',
  [SearchByTypes.OrganisationName]: 'searchByOrganisationName',
  [SearchByTypes.CustomerId]: isFrankie2Customer
    ? 'searchByCustomerIdF2'
    : 'searchByCustomerId',
  [SearchByTypes.FrankieId]: isFrankie2Customer
    ? 'searchByEntityId'
    : 'searchByFrankieId',
  [SearchByTypes.AbnOrAcn]: 'searchByAbnOrAcn',
})

export const searchByPlaceholderI18nMap: (
  isFrankie2Customer: boolean,
) => Record<SearchByTypes, string> = isFrankie2Customer => ({
  [SearchByTypes.IndividualName]: 'searchByIndividualNamePlaceholder',
  [SearchByTypes.OrganisationName]: 'searchByOrganisationNamePlaceholder',
  [SearchByTypes.CustomerId]: isFrankie2Customer
    ? 'searchByCustomerIdPlaceholderF2'
    : 'searchByCustomerIdPlaceholder',
  [SearchByTypes.FrankieId]: isFrankie2Customer
    ? 'searchByEntityIdPlaceholder'
    : 'searchByFrankieIdPlaceholder',
  [SearchByTypes.AbnOrAcn]: 'searchByAbnOrAcnPlaceholder',
})

export const globalSearchControlledParams = [
  ApplicantsSearchParamsTypes.EntityIdFilter,
  ApplicantsSearchParamsTypes.EntityTypeFilter,
  ApplicantsSearchParamsTypes.NameFilter,
  ApplicantsSearchParamsTypes.MatchStatusFilter,
  ProfileSearchParamsTypes.EntityId,
]

export interface IGlobalSearchMemo {
  searchBy: SearchByTypes
  value: string
}

function isIGlobalSearchMemo(smth: unknown): smth is IGlobalSearchMemo {
  return (
    !!smth &&
    typeof smth === 'object' &&
    'searchBy' in smth &&
    'value' in smth &&
    typeof smth.value === 'string' &&
    typeof smth.searchBy === 'string' &&
    SearchByTypesFlat.includes(smth.searchBy)
  )
}

export function validateGlobalSearchHistoryStorage(
  storageItem: Nullable<Serializable>,
  isOnlySearchById: boolean,
): IGlobalSearchMemo[] {
  if (!Array.isArray(storageItem)) {
    return []
  }
  return storageItem
    .reduce<IGlobalSearchMemo[]>((result, value) => {
      if (
        isIGlobalSearchMemo(value) &&
        result.every(
          res =>
            `${res.searchBy}${res.value}` !== `${value.searchBy}${value.value}`,
        )
      ) {
        return [...result, value]
      }
      return result
    }, [])
    .filter(memo =>
      isOnlySearchById
        ? [
            SearchByTypes.FrankieId,
            SearchByTypes.CustomerId,
            SearchByTypes.AbnOrAcn,
            SearchByTypes.IndividualName,
          ].includes(memo.searchBy)
        : true,
    )
    .slice(0, GLOBAL_SEARCH_HISTORY_MAX_LENGTH)
}

export function updateGlobalSearchHistory(
  globalSearchHistory: IGlobalSearchMemo[],
  globalSearchMemo: IGlobalSearchMemo,
): IGlobalSearchMemo[] {
  if (
    hasElement(
      globalSearchHistory,
      globalSearchMemo,
      memo => `${memo.searchBy}${memo.value}`,
    )
  ) {
    return [
      globalSearchMemo,
      ...globalSearchHistory.filter(
        memo =>
          `${memo.searchBy}${memo.value}` !==
          `${globalSearchMemo.searchBy}${globalSearchMemo.value}`,
      ),
    ]
  }
  return [
    globalSearchMemo,
    ...globalSearchHistory.slice(0, GLOBAL_SEARCH_HISTORY_MAX_LENGTH - 1),
  ]
}

export function getGlobalSearch(searchParams: URLSearchParams): {
  searchBy: SearchByTypes | null
  value: string
} {
  let searchByParam = searchParams.get(GLOBAL_SEARCH_SEARCH_BY_PARAM)
  const nameFilter =
    searchParams.get(ApplicantsSearchParamsTypes.NameFilter) ||
    searchParams.get(ProfileSearchParamsTypes.EntityName)
  const entityTypeFilter = searchParams.get(
    ApplicantsSearchParamsTypes.EntityTypeFilter,
  )
  // override if local filtering is different
  if (nameFilter && entityTypeFilter === ApplicantEntityTypes.Individual) {
    searchByParam = SearchByTypes.IndividualName
  }
  if (nameFilter && entityTypeFilter === ApplicantEntityTypes.Organisation) {
    searchByParam = SearchByTypes.OrganisationName
  }
  return {
    searchBy: searchByParam as SearchByTypes | null,
    value:
      searchParams.get(ApplicantsSearchParamsTypes.NameFilter) ||
      searchParams.get(ProfileSearchParamsTypes.CustomerId) ||
      searchParams.get(ApplicantsSearchParamsTypes.EntityIdFilter) ||
      searchParams.get(ProfileSearchParamsTypes.EntityName) ||
      searchParams.get(ProfileSearchParamsTypes.EntityId) ||
      '',
  }
}

/**
 * Determines the search type based on the input text format
 *
 * @param text - The search text to analyze
 * @returns SearchByTypes - The determined search type:
 *   - SearchByTypes.FrankieId: If text matches UUID format (e.g., "123e4567-e89b-12d3-a456-426614174000")
 *   - SearchByTypes.AbnOrAcn: If text matches ABN/ACN format (e.g., "12 345 678 901" or "123 456 789")
 *   - Default SearchByTypes: If text doesn't match any of the above
 *
 * @example
 * getSearchByType("123e4567-e89b-12d3-a456-426614174000") // returns SearchByTypes.FrankieId
 * getSearchByType("12 345 678 901") // returns SearchByTypes.AbnOrAcn
 */
export const getSearchByType = (
  text: string,
  defaultSearchBy = SearchByTypes.IndividualName,
): SearchByTypes => {
  const frankieIdRegex =
    /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/
  const abnOrAcnRegex = /\b(?:\d{2} ?\d{3} ?\d{3} ?\d{3}|\d{3} ?\d{3} ?\d{3})\b/
  if (text.match(frankieIdRegex)) return SearchByTypes.FrankieId
  if (text.match(abnOrAcnRegex)) return SearchByTypes.AbnOrAcn
  return defaultSearchBy
}
