import React, { useEffect, useMemo } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import {
  FrankieButton,
  FrankieDivider,
  FrankieIcon,
  FrankieLoader,
} from 'frankify/src'

import { ApplicantId, DocumentContextTypes } from 'entities/applicant'
import {
  DOCUMENT_KEY,
  DocumentStatusTypes,
  DocumentUpload,
  documentEn,
  documentTypesOption,
  documentStatusOptions,
  UploadedDocumentData,
  DocumentTypes,
  DocumentView,
  useDocumentScansQuery,
} from 'entities/document'

import { base64ToBlob } from 'shared/file'
import { SelectFormField, TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { useOverlay } from 'shared/overlay'
import { SuggestionString } from 'shared/typescript'

import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../locale/applicant-supporting-documents.en'
import { applicantSupportDocQa } from '../../qa/applicant-support-documents.qa'
import { useApplicantSupportingDocumentCommentsQuery } from '../../state/applicant-supporting-document-comments-query/applicant-supporting-document-comments.query'
import { useSupportingDocumentDelete } from '../../state/applicant-supporting-document-delete-mutation/applicant-supporting-document-delete.mutation'
import {
  useDocumentStamp,
  useManageApplicantSupportingDocument,
} from '../../state/applicant-supporting-document-manage/applicant-supporting-document-manage'
import { useApplicantSupportingDocumentQuery } from '../../state/applicant-supporting-document-query/applicant-supporting-document.query'
import { documentDateFormatter } from '../applicant-support-document-table-helper/applicant-support-document-table-helper'

type DocumentForm = {
  type: DocumentTypes
  status?: DocumentStatusTypes
  comments: string
  document: UploadedDocumentData
}

export type Props = {
  applicantId: ApplicantId
  documentId: SuggestionString<'new'>
}

// eslint-disable-next-line complexity
export function ApplicantAddDocuments({ applicantId, documentId }: Props) {
  const navigate = useNavigate()
  const location = useLocation()

  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const tDoc = useI18n([DOCUMENT_KEY], {
    keys: documentEn,
  })

  const [createOverlay, closeOverlay] = useOverlay()

  const { mutate: deleteDocs, isSuccess: isSuccessfulDeletion } =
    useSupportingDocumentDelete({ applicantId })

  const view = documentId !== 'new'

  const {
    control,
    watch,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm<DocumentForm>({
    mode: 'onChange',
    defaultValues: {
      comments: '',
    },
  })

  const { data: scans, isError } = useDocumentScansQuery({
    documentId: view ? documentId : undefined,
  })

  const { data: applicantDocuments, isFetching } =
    useApplicantSupportingDocumentQuery({
      applicantId,
    })

  const selectedDocumentData = useMemo(
    () => applicantDocuments?.find(item => item.id === documentId),
    [applicantDocuments, documentId],
  )

  const {
    mutate,
    isSuccess,
    isLoading: addingDoc,
  } = useManageApplicantSupportingDocument({
    applicantId,
    manageType: view ? 'update' : 'new',
    documentId: selectedDocumentData?.id,
  })

  const { data: commentsData, isFetching: isFetchingComments } =
    useApplicantSupportingDocumentCommentsQuery({
      applicantId,
      documentId: selectedDocumentData?.id,
    })

  useEffect(() => {
    if (view && selectedDocumentData) {
      const { file } = selectedDocumentData.data

      const document = {
        fileUploadUuid: file?.fileUploadUuid,
        mimeType: file?.mimeType,
        scanCreated: file?.scanCreated,
        scanName: file?.scanName,
      } as UploadedDocumentData

      reset({
        type: selectedDocumentData.documentType,
        comments: commentsData?.length ? commentsData[0]?.comment : '',
        status: selectedDocumentData.status!,
        document,
      })
    }
  }, [
    selectedDocumentData,
    applicantDocuments,
    commentsData,
    documentId,
    reset,
    view,
  ])

  useEffect(() => {
    if (isError) notification.error(t('errorFetchingDoc'))
  }, [isError, t])

  useEffect(() => {
    if (isSuccess || isSuccessfulDeletion) navigate(location.pathname)
  }, [isSuccess, isSuccessfulDeletion, location.pathname, navigate])

  const documentOptions = useMemo(
    () =>
      documentTypesOption.map(({ tKey, value }) => ({
        label: tDoc(tKey),
        value,
      })),
    [tDoc],
  )

  const styleClass: Record<
    DocumentStatusTypes,
    { text: string; bg: string; border: string }
  > = useMemo(
    () => ({
      [DocumentStatusTypes.APPROVED]: {
        text: 'text-tertiary-green-500',
        bg: 'bg-tertiary-green-200',
        border: 'border-tertiary-green-400',
      },
      [DocumentStatusTypes.NEEDS_REVIEW]: {
        text: 'text-tertiary-amber-500',
        bg: 'bg-tertiary-amber-50',
        border: 'border-tertiary-amber-500',
      },
      [DocumentStatusTypes.DECLINED]: {
        text: 'text-tertiary-red-500',
        bg: 'bg-tertiary-red-200',
        border: 'border-tertiary-red-400',
      },
    }),
    [],
  )

  const docStatusOptions = useMemo(
    () =>
      Object.entries(documentStatusOptions).map(
        ([value, { iconName, tKey }]) => ({
          label: tDoc(tKey),
          value,
          iconName,
          styleClass: styleClass[value as DocumentStatusTypes],
        }),
      ),
    [tDoc, styleClass],
  )

  const formWatch = watch()

  const selectedDocumentType = useMemo(
    () => documentOptions.find(item => item.value === formWatch.type),
    [documentOptions, formWatch.type],
  )

  /**
   * Disable save button if form is not valid or no changes are made
   */
  const isSaveDisabled = useMemo(
    () =>
      !isValid ||
      (view &&
        formWatch.status === selectedDocumentData?.status &&
        formWatch.comments === commentsData?.[0]?.comment),
    [commentsData, formWatch, isValid, selectedDocumentData?.status, view],
  )

  const getDocumentStamp = useDocumentStamp()

  // eslint-disable-next-line complexity
  const onSubmit = (data: DocumentForm) => {
    const createdChangeStamp = getDocumentStamp()

    if (selectedDocumentType) {
      mutate({
        comment: {
          associatedResourceId: commentsData?.[0]?.associatedResourceId ?? '',
          comment: data.comments,
          context: DocumentContextTypes.supporting_docs,
          created: createdChangeStamp,
        },

        supportingDocument: {
          label: selectedDocumentType.label,
          type: data.type,
          status: data.status,

          id: selectedDocumentData?.id ?? null,

          file: selectedDocumentData?.data.file ?? {
            scanId: null,
            file: null,
            side: 'F',
            ...data.document,
          },

          modified: selectedDocumentData?.data.modified ?? createdChangeStamp,

          reviewed: createdChangeStamp,
          refer: selectedDocumentData?.data.refer ?? {
            uuid: '',
            type: '',
          },
          smartUiDocOrder: selectedDocumentData?.data.smartUiDocOrder ?? null,
        },

        isStatusUpdated: view && selectedDocumentData?.status !== data.status,
      })
    }
  }

  const formStatus = watch('status')

  const downloadUrl = useMemo(() => {
    const currentScan = scans?.[0]

    if (currentScan && currentScan.mimeType) {
      const fileURL = base64ToBlob(currentScan.file, currentScan.mimeType)
      return window.URL.createObjectURL(fileURL)
    }
    return false
  }, [scans])

  const handleRemove = () => {
    createOverlay(
      <div className="">
        <div className="flex items-center gap-2 p-3 text-tertiary-grey-800 font-bold">
          <FrankieIcon
            name="mdiTrashCanOutline"
            className="text-tertiary-grey-300"
          />

          <span> {t('deleteDocument')}</span>
        </div>
        <FrankieDivider />
        <div className="p-6">
          <div className="font-bold bg-tertiary-red-50 p-8 text-tertiary-red-700 mb-6 rounded-sm">
            {t('confirmRemove', { documentId })}
          </div>
          <div className="flex justify-between">
            <FrankieButton
              intent="danger"
              // className="!bg-tertiary-red-500" // Danger button is bit too dark will remove this after confirmation
              onClick={() => {
                deleteDocs(documentId)
                closeOverlay()
              }}
            >
              {t('delete')}
            </FrankieButton>
            <FrankieButton
              intent="secondary"
              onClick={closeOverlay}
              className="!text-tertiary-grey-500 border-0"
            >
              {t('cancel')}
            </FrankieButton>
          </div>
        </div>
      </div>,
      { className: 'p-0' },
    )
  }

  const isGettingData =
    documentId !== 'new' && (isFetching || isFetchingComments)

  const loadingLabel = useMemo(() => {
    if (addingDoc) {
      if (view) {
        return t('loading.updatingDoc')
      }
      return t('loading.uploadingDoc')
    }
    return t('gettingDocumentDetails')
  }, [addingDoc, t, view])

  /**
   * Error text if document id is not valid
   */
  if (documentId !== 'new' && !selectedDocumentData && !isFetching) {
    return <div>{t('notValidDocumentGoBack')}</div>
  }

  const actionIconClass =
    'border border-solid border-tertiary-grey-300 p-1 cursor-pointer rounded-sm cursor-pointer text-tertiary-grey-400'

  return (
    <FrankieLoader
      label={loadingLabel}
      loading={isGettingData || addingDoc}
      className="!h-auto"
    >
      {view && (
        <NavLink to={location.pathname}>
          <FrankieButton
            noStyles
            className="flex gap-1 text-lg font-bold items-center"
            startIcon={{
              name: 'mdiChevronLeft',
            }}
            testId={{ button: applicantSupportDocQa.button }}
          >
            {t('back')}
          </FrankieButton>
        </NavLink>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`flex p-6 gap-6 rounded-sm ${
          view ? 'flex-row-reverse' : 'bg-neutral-20'
        }`}
        data-qa={applicantSupportDocQa.form}
      >
        <div className="basis-1/2 flex flex-col gap-6">
          {view && (
            <div
              className={`relative border-2 border-solid p-3 rounded-sm ${
                formStatus
                  ? `${styleClass[formStatus].border} ${styleClass[formStatus].text}`
                  : ''
              }`}
            >
              <div className="pb-2">
                <div className="flex items-start gap-1 font-bold">
                  <FrankieIcon
                    name={
                      formStatus
                        ? documentStatusOptions[formStatus].iconName
                        : 'Check'
                    }
                    size="sm"
                    testId={{ icon: applicantSupportDocQa.checkIcon }}
                  />
                  <span>
                    {formStatus
                      ? tDoc(documentStatusOptions[formStatus].tKey)
                      : ''}
                  </span>
                </div>
              </div>
              <div className="flex gap-2 items-end absolute top-[10px] right-[10px] ">
                <FrankieButton
                  noStyles
                  className={actionIconClass}
                  onClick={handleRemove}
                  singleIcon={{
                    name: 'mdiTrashCanOutline',
                    size: 'xs',
                  }}
                  testId={{ button: applicantSupportDocQa.remove }}
                />
                {downloadUrl && (
                  <a
                    className={actionIconClass}
                    href={downloadUrl}
                    download={scans?.[0]?.scanName}
                    data-qa={applicantSupportDocQa.downloadUrl}
                  >
                    <FrankieIcon name="mdiTrayArrowDown" size="xs" />
                  </a>
                )}
              </div>
              <div className="font-bold text-tertiary-grey-800">
                {selectedDocumentType?.label}
              </div>
              <div className="font-bold text-tertiary-grey-800">
                {selectedDocumentData?.documentName}
              </div>
              <div className="text-xs font-lighter text-tertiary-grey-400">
                {t('uploadedByDetail', {
                  username: selectedDocumentData?.reviewBy,
                  date: documentDateFormatter({
                    value: selectedDocumentData?.lastUpdatedDate,
                  }),
                })}
              </div>
            </div>
          )}

          {!view && (
            <SelectFormField
              label={t('documentType')}
              placeholder={t('select')}
              control={control}
              name="type"
              options={documentOptions}
              rules={{ required: true }}
              testId={{ container: applicantSupportDocQa.noViewOptions }}
            />
          )}

          <Controller
            render={({ field: { value: fieldValue, onChange } }) => (
              <div>
                <div className="pb-1 text-tertiary-grey-800 text-sm font-medium">
                  {t('documentStatus')}
                </div>
                <div className="flex flex-col gap-3">
                  {docStatusOptions.map(({ label, styleClass, value }) => (
                    <label
                      key={value}
                      className={`flex items-center gap-2 p-2 rounded-sm border font-bold w-[150px] cursor-pointer ${
                        value === fieldValue
                          ? `${styleClass.bg} ${styleClass.border}`
                          : 'border-tertiary-grey-300'
                      }`}
                    >
                      <input
                        checked={value === fieldValue}
                        type="radio"
                        value={value}
                        onChange={onChange}
                      />
                      {label}
                    </label>
                  ))}
                </div>
              </div>
            )}
            control={control}
            name="status"
            rules={{ required: true }}
          />

          <TextAreaFormField
            label={t('comment')}
            placeholder={t('addCommentHere')}
            control={control}
            name="comments"
            rules={{ required: true }}
            data-qa={applicantSupportDocQa.comment}
          />

          <FrankieButton
            className="max-w-[124px]"
            type="submit"
            disabled={isSaveDisabled || addingDoc}
            data-qa={applicantSupportDocQa.submit}
          >
            {t('save')}
          </FrankieButton>
        </div>
        {!view ? (
          <Controller
            render={({ field: { value, onChange } }) => (
              <DocumentUpload
                className="basis-1/2 !h-[400px]"
                name={selectedDocumentType?.label}
                onChange={onChange}
                fileSize={20}
              />
            )}
            control={control}
            name="document"
            rules={{
              required: true,
              validate: {
                hasMedia: (value: UploadedDocumentData) =>
                  !!value.fileUploadUuid || view,
              },
            }}
          />
        ) : (
          <div className="basis-1/2">
            {scans?.[0] && (
              <DocumentView
                fileName={scans[0].scanName ?? ''}
                fileUrl={scans[0].file}
                mimeType={scans[0].mimeType}
                data-qa={applicantSupportDocQa.scan}
                hideFileName
                fullView
              />
            )}
          </div>
        )}
      </form>
    </FrankieLoader>
  )
}
