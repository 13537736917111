import { FrankieIconName } from 'frankify/src'

import { I18nKeys } from 'shared/i18n'

import { documentEn } from '../locale/document.en'

export enum SupportedFileTypes {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
  WEBP = 'image/webp',
  TIFF = 'image/tiff',
  BMP = 'image/bmp',
  PDF = 'application/pdf',
}

export interface IUploadedDocumentData {
  fileUploadUuid: string
  mimeType: string
  scanCreated: string
  scanName: string
}

export interface IDocumentUploadProps {
  className?: string
  name?: string
  uploadedOn?: string
  supportedFileTypes?: SupportedFileTypes[]
  fileSize?: number
  onChange: (data: IUploadedDocumentData) => void
  error?: boolean
  documentId?: string
  fetchNewScan?: boolean
}

export interface IDocumentUploadPreviewProps {
  scans: Scan[]
  fileUrl: string
  name?: string
  uploadedOn?: string
  fileName?: string
  isFileImage?: boolean
}

export interface IFileValidationConfig {
  maxFileSize: number
  supportedFileTypes: string[]
  maxFilenameLength: number
}

export const FILENAME_MAX_LENGTH = 80
export const DEFAULT_FILE_SIZE_MB = 25

export const defaultSupportedFileTypes: SupportedFileTypes[] = [
  SupportedFileTypes.JPEG,
  SupportedFileTypes.PNG,
  SupportedFileTypes.GIF,
  SupportedFileTypes.WEBP,
  SupportedFileTypes.TIFF,
  SupportedFileTypes.BMP,
  SupportedFileTypes.PDF,
]

export enum DocumentStatusTypes {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
}

export const documentStatusOptions: Record<
  DocumentStatusTypes,
  { tKey: I18nKeys<typeof documentEn>; iconName: FrankieIconName }
> = {
  [DocumentStatusTypes.APPROVED]: {
    tKey: 'documentStatusType.approved',
    iconName: 'mdiCheckCircle',
  },
  [DocumentStatusTypes.NEEDS_REVIEW]: {
    tKey: 'documentStatusType.needAttention',
    iconName: 'mdiInformation',
  },
  [DocumentStatusTypes.DECLINED]: {
    tKey: 'documentStatusType.declined',
    iconName: 'mdiCloseCircle',
  },
}

export type Scan = {
  scanId: string
  file: string
  mimeType?: string
  side: string
  scanCreated: string
  scanName?: string
}

export function scanSorter(scan1: Scan, scan2: Scan) {
  const getSide = (scan: Scan) => scan.side || 'F'
  const getPoints = (side: string) => {
    let points = 0
    switch (side) {
      case 'F':
        points = 0
        break
      case 'B':
        points = 1
        break
      default:
        points = 2
        break
    }
    return points
  }

  return getPoints(getSide(scan1)) - getPoints(getSide(scan2))
}

export function scanDateSorter(scan1: Scan, scan2: Scan) {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const getDate = (scan: Scan) => scan.scanCreated ?? 'ZZZ'
  return getDate(scan2).localeCompare(getDate(scan1))
}
