import React, { useRef, useState, useEffect } from 'react'

import {
  GridColumnHeaderParams,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro'
import cx from 'classnames'

import { FrankieTooltip } from 'frankify/src'

import { RiskLevel } from 'entities/entity'

import { capitalizeStringBySeparator } from 'shared/string'

import { RiskFactorRow } from '../../../model/applicant-workflow-events.model'
import { dataGridHelperQa } from '../../../qa/applicant-workflow-events.qa'
import { RiskLevelComponent } from '../../common/risk-level/risk-level'

export function RiskFactorDataGridCell({
  value,
  row,
  api: { getAllRowIds },
}: GridRenderCellParams<RiskFactorRow>) {
  const textRef = useRef<HTMLParagraphElement>(null)
  const [isTextTruncated, setIsTextTruncated] = useState(false)
  const text = capitalizeStringBySeparator(value as string, '_')

  useEffect(() => {
    const checkTruncation = () => {
      if (textRef.current) {
        setIsTextTruncated(
          textRef.current.scrollWidth > textRef.current.clientWidth,
        )
      }
    }

    checkTruncation()
    window.addEventListener('resize', checkTruncation)
    return () => window.removeEventListener('resize', checkTruncation)
  }, [])

  return (
    <FrankieTooltip
      hidden={!isTextTruncated}
      body={isTextTruncated ? text : undefined}
      position="top"
      zIndex={1001}
    >
      <p
        ref={textRef}
        data-qa={dataGridHelperQa.RiskFactorDataGridCellContainer}
        className={cx(
          'truncate overflow-hidden',
          '!px-4 text-tertiary-grey-700',
          { 'font-semibold': row.id === getAllRowIds().at(-1) },
        )}
      >
        {text}
      </p>
    </FrankieTooltip>
  )
}

export function RiskValueDataGridCell({
  value,
  row,
  api: { getAllRowIds },
}: GridRenderCellParams<RiskFactorRow>) {
  return (
    <span
      data-qa={dataGridHelperQa.RiskValueDataGridCell}
      className="!px-4 text-tertiary-grey-700 font-semibold"
    >
      {row.id === getAllRowIds().at(-1) ? (
        <RiskLevelComponent riskLevel={value as RiskLevel} />
      ) : (
        capitalizeStringBySeparator(value as string, '_')
      )}
    </span>
  )
}

export function RiskScoreDataGridCell({
  value,
}: GridRenderCellParams<RiskFactorRow>) {
  return (
    <span
      data-qa={dataGridHelperQa.RiskScoreDataGridCell}
      className="!px-4 text-tertiary-grey-700 font-semibold"
    >
      {value}
    </span>
  )
}

export function RiskDataGridHeader({
  colDef: { headerName },
}: GridColumnHeaderParams<RiskFactorRow>) {
  return (
    <span data-qa={dataGridHelperQa.RiskDataGridHeader} className="!px-4 !py-2">
      {headerName}
    </span>
  )
}

export const getWidth = (width: number) => ({
  minWidth: width,
  flex: width / 424,
})

export const headerClassName =
  '!p-0 text-xs font-medium uppercase bg-tertiary-grey-50 text-tertiary-grey-500 !outline-none'
export const cellClassName = '!outline-none !p-2 justify-center'
