import React, { useMemo, useState } from 'react'

import {
  DataGridPro,
  GridColDef,
  GridRowParams,
  GridSortModel,
  gridClasses,
} from '@mui/x-data-grid-pro'

import { FrankieLoader } from 'frankify/src'

import { ApplicantId, useApplicantDataQuery } from 'entities/applicant'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { WithProps } from 'shared/hoc'
import { useI18n } from 'shared/i18n'

import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../locale/applicant-supporting-documents.en'
import { PurchasedTableTypeData } from '../../model/organisations-purchased-documents.model'
import { useApplicantDocumentDownload } from '../../state/application-scans-query/application-scans-query'
import {
  DocumentTableHeader,
  documentCellWidth,
  documentTableMuiStyles,
} from '../applicant-support-document-table-helper/applicant-support-document-table-helper'
import {
  OrganisationNameTableCell,
  OrganisationPurchasedDocumentNoRowsOverlay,
} from '../organisation-documents-catalog-table-cell/organisation-documents-catalog-table-cell'

type Props = {
  applicantId: ApplicantId
}

export function OrganisationPurchasedDocuments({ applicantId }: Props) {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const {
    data: applicantData,
    isLoading,
    isFetching,
  } = useApplicantDataQuery({ applicantId })

  const { downloadReport } = useApplicantDocumentDownload({
    showNoScanError: true,
  })

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'purchased', sort: 'asc' },
  ])

  /**
   * to be done in future
   * filter by status
   */

  // const { control } = useForm<OrganisationPurchasedDocumentFormType>({
  //   mode: 'onTouched',
  //   defaultValues: {
  //     status: '',
  //   },
  // })

  /**
   *  TODO:
   * purchased date
   *
   */

  const rows: PurchasedTableTypeData[] = useMemo(
    () =>
      applicantData?.registryDocuments
        ? applicantData.registryDocuments.map(doc => ({
            id: doc.documentId,
            title: doc.scans[0].scanName ?? '',
            scanName: doc.scans[0].scanName ?? '',
            status: doc.registryDocumentStatus?.reportStatus,
            purchased: doc.registryDocumentStatus?.requestedDate,
            orderedBy: '-',
            issue: '-',
          }))
        : [],
    [applicantData?.registryDocuments],
  )

  const columns: GridColDef<PurchasedTableTypeData>[] = [
    {
      field: 'name',
      headerName: t('purchasedDocuments.tableColumn.document'),
      sortable: false,
      ...documentCellWidth(150),
      renderCell: OrganisationNameTableCell,
    },
    {
      field: 'purchased',
      headerName: t('purchasedDocuments.tableColumn.purchased'),
      minWidth: 150,
      width: 150,
      sortable: true,
      sortComparator: (a?: string, b?: string) => {
        if (!a && !b) return 0
        if (!a) return 1
        if (!b) return -1

        return b.localeCompare(a)
      },

      renderCell: ({ row }) => (
        <div>
          <div className="font-normal">
            {row.purchased
              ? formatDate(row.purchased, DateFormatTypes.ShortDate)
              : '-'}
          </div>
          <div className="font-normal text-tertiary-grey-500">
            {row.purchased && formatDate(row.purchased, DateFormatTypes.Time)}
          </div>
        </div>
      ),
    },
    // {
    //   field: 'orderedBy',
    //   headerName: t('purchasedDocuments.tableColumn.orderedBy'),
    // },
  ]

  const handleShowDocument = ({
    row,
  }: GridRowParams<PurchasedTableTypeData>) => {
    const doc = applicantData?.registryDocuments
      ? applicantData.registryDocuments.find(i => i.documentId === row.id)
          ?.registryDocumentStatus?.reportUrl
      : null
    if (doc) {
      downloadReport(row.id)
      // window.open(doc, '_blank', 'noopener,noreferrer')
    }
  }

  return (
    <div className="basis-full text-tertiary-grey-700 h-full overflow-x-hidden px-3">
      <div className="text-xl text-secondary-900 font-bold pb-2">
        {t('purchasedDocuments.heading')}
      </div>
      {/**
       * To be done in future
       * filter by status
       */}
      {/* <div className="max-w-[150px]">
        <SelectFormField options={[]} name="status" control={control} />
      </div> */}
      <FrankieLoader
        className="min-h-[50px]"
        loading={isLoading || isFetching}
        label={t('fetchingDoc')}
      >
        <DataGridPro
          getRowId={row => row.id}
          rows={rows}
          columns={columns.map(item => ({
            // Adding default properties for every column
            headerClassName:
              '!p-0 text-xs font-medium bg-tertiary-grey-50 text-tertiary-grey-500 !outline-none',
            cellClassName:
              '!outline-none !py-4 text-sm font-semibold text-secondary-900 break-words',
            renderHeader: WithProps(
              DocumentTableHeader<PurchasedTableTypeData>,
              { sortModel },
            ),
            sortable: false,
            hideSortIcons: true,
            resizable: false,
            disableColumnMenu: true,
            disableReorder: true,
            ...item,
          }))}
          slots={{
            noRowsOverlay: OrganisationPurchasedDocumentNoRowsOverlay,
          }}
          className="[&_.MuiDataGrid-virtualScrollerContent]:border-neutral-30 [&_.MuiDataGrid-columnHeadersInner]:bg-tertiary-grey-50 [&_.MuiDataGrid-columnHeaderTitle]:text-xs  [&_.MuiDataGrid-columnHeaderTitle]:font-medium [&_.MuiDataGrid-columnHeadersInner]:text-tertiary-grey-500  [&_.MuiDataGrid-columnHeader]:!outline-none"
          getCellClassName={() =>
            '!outline-none !border-neutral-30 !items-start !py-4  break-words'
          }
          getRowClassName={() => ' hover:!bg-transparent !min-h-[60px]'}
          sx={{
            ...documentTableMuiStyles,
            [`&.${gridClasses.root}`]: {
              border: 'none',
            },
            '& .MuiDataGrid-row.Mui-hovered, .MuiDataGrid-row:hover': {
              backgroundColor: 'transparent',
            },
          }}
          loading={isFetching}
          onRowClick={handleShowDocument}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          isRowSelectable={() => false}
          isCellEditable={() => false}
          getRowHeight={() => 'auto'}
          disableRowSelectionOnClick
          disableColumnSelector
          disableColumnFilter
          disableColumnMenu
          disableDensitySelector
          showCellVerticalBorder={false}
          showColumnVerticalBorder={false}
          hideFooterPagination
          hideFooter
          hideFooterSelectedRowCount
          autoHeight
          autoPageSize
        />
      </FrankieLoader>
    </div>
  )
}
