export const individualProfileInsightEn = {
  tab: {
    personalInfo: 'Personal info',
    phone: 'Phone',
    email: 'Email',
    documents: 'Identity documents',
    aml: 'AML screening',
    selfies: 'Selfies',
  },
  title: 'Section',
  label: {
    idType: 'ID Type',
    country: 'Country of Issue',
    individual: 'Individual',
    profileType: 'Profile Type',
  },
  documentsSection: {
    otherDocs: 'Other documents',
    otherDocsDesc:
      'These documents have been captured via OCR, but were not supported in KYC checks.',
  },
  banner: {
    noPermission:
      'You do not have the right permissions to verify this profile, please contact your administrator to do so.',
    title:
      'Some data on this profile has been changed but has not been verified',
    titleUnchecked: 'Data on this profile has not been verified',
    descriptionUnchecked: 'Please manually complete and verify this profile.',
    description:
      'To keep this profile up to date, verify the profile with a workflow.',
    button: 'Verify entity',
    archivedTitle: 'This profile has been archived',
    buttonEdit: 'Edit & verify',

    modal: {
      verifyDescription:
        'This will run the following workflow, please check that all data to be verified is accurate before proceeding.',
      selectWorkflow: 'Select Workflow',

      comment: 'Comment',
      commentPlaceholder: 'Type your comment here',
      commentLabel: 'Comment',
      commentError: 'Please add a comment',
      buttonVerify: 'Verify profile',
      loading: 'Verifying profile',
      success: 'Workflow ran successfully.',
      error: 'Failed to run workflow.',
      runWorkflow: 'Run workflow',
      cancel: 'Cancel',
    },
  },
  amlSection: {
    title: 'AML screening',
    cta: 'View all',
    pep: 'PEP',
    sanctions: 'Sanctions',
    watchlist: 'Watchlist',
    adverseMedia: 'Adverse Media',
    truePositive: 'True positive',
    unknown: 'Unknown',
  },
  modal: {
    compareImages: 'Compare images',
    selfie: 'Selfie',
    uploadedOn: 'Uploaded on {{date}} at {{time}}',
    directCapture: 'via direct capture',
    by: 'by {{name}}',
    recentUploadedOn: 'Most recent upload on {{date}} at {{time}}',
  },
  uploadedAt: 'Last updated on {{date}} at {{time}}',
}
