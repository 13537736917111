import React from 'react'

import classNames from 'classnames'

import { FrankieIcon } from 'frankify/src'

import { Node } from '../../state/individual-ocr-check/individual-ocr-check.state'

type Props = {
  ocrResult: Record<string, Node>
}

export function OcrDocumentRow({ ocrResult }: Props) {
  const groups = Object.values(ocrResult)

  return (
    <div className="grid grid-cols-3 gap-6 col-span-2">
      {groups.map(group => (
        <div key={group.id}>
          <div className="text-tertiary-grey-800 font-medium flex gap-1.5 mb-2">
            {group.name !== 'Scores' && (
              <FrankieIcon
                name={group.success ? 'mdiCheckCircle' : 'mdiCloseCircle'}
                size="xs"
                className={classNames(
                  group.success
                    ? 'text-tertiary-green-600'
                    : 'text-tertiary-red-700',
                  'mt-[3px]',
                )}
              />
            )}
            <span>{group.name}</span>
          </div>
          {Object.values(group.children || {}).map(report => {
            const hasScore = Object.hasOwn(report.children || {}, 'Score')

            const Icon = (
              <FrankieIcon
                name={report.success ? 'mdiCheckCircle' : 'mdiCloseCircle'}
                size="2xs"
                className={classNames(
                  report.success
                    ? 'text-tertiary-green-600'
                    : 'text-tertiary-red-700',
                  'mt-[3px]',
                )}
              />
            )

            return (
              <div
                key={report.id}
                className={classNames(
                  !hasScore && 'ml-4',
                  'mb-2 font-medium text-xs flex flex-col gap-1.5',
                )}
              >
                <p className="flex gap-1.5">
                  {!hasScore && !report.isStatusUnknown && Icon}
                  {report.isStatusUnknown && !hasScore && (
                    <span className="inline-block w-3" />
                  )}
                  <span
                    className={
                      hasScore
                        ? 'text-tertiary-grey-800'
                        : 'text-tertiary-grey-500'
                    }
                  >
                    {report.name}
                  </span>
                </p>
                {hasScore && (
                  <p className="flex gap-1.5">
                    {!report.isStatusUnknown && Icon}
                    <span className="text-tertiary-grey-500">
                      {report.children?.Score?.data.originalData}%
                    </span>
                  </p>
                )}
              </div>
            )
          })}
        </div>
      ))}
    </div>
  )
}
