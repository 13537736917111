import ReactGA from 'react-ga4'

export const initializeGoogleAnalytics = () => {
  const gaTrackingId = process.env.REACT_APP_GOOGLE_ANALYTICS

  if (gaTrackingId) {
    ReactGA.initialize(gaTrackingId)
  }
}

export const googleAnalyticsRegisterTracker = () => {
  initializeGoogleAnalytics()
}
