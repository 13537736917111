import { useQuery } from '@tanstack/react-query'

import { CountryAlpha3CodeTypes } from 'entities/country'
import { DocumentIdTypes } from 'entities/entity'

import { isNotEmptyString } from 'shared/typescript'

import { individualApiF2 } from '../api/individual-f2.api'

export const GetIndividualAddressQueryKey = 'get-individual-address-f2'

const EDITABLE_DOCUMENTS = [
  DocumentIdTypes.DRIVERS_LICENSE,
  DocumentIdTypes.PASSPORT,
  DocumentIdTypes.NATIONAL_HEALTH_ID,
]

export const useGetIndividualFormData = (entityId?: string) =>
  useQuery({
    queryKey: [GetIndividualAddressQueryKey, entityId],
    queryFn: async () => {
      if (!entityId) return undefined
      const { data } = await individualApiF2.getIndividualFormValue(entityId)

      // Filter out unsupported documents
      const SUPPORTED_COUNTRIES = [
        CountryAlpha3CodeTypes.Australia,
        CountryAlpha3CodeTypes.NEW_ZEALAND,
      ]
      data.documents = data.documents.filter(
        doc =>
          EDITABLE_DOCUMENTS.includes(doc.type as DocumentIdTypes) &&
          SUPPORTED_COUNTRIES.includes(doc.country as CountryAlpha3CodeTypes),
      )

      return data
    },
    enabled: isNotEmptyString(entityId),
  })
