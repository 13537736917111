/* eslint-disable complexity */
import React, { useState } from 'react'

import { FrankieButton } from 'frankify/src'

import { DOCUMENT_KEY, documentEn } from 'entities/document/locale/document.en'
import type { IDocumentUploadPreviewProps } from 'entities/document/model/document.model'
import { useFileHandler } from 'entities/document/model/use-file-handler.model'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { PdfPreview } from 'shared/pdf-preview/ui/pdf-preview'

export function DocumentUploadPreview(props: IDocumentUploadPreviewProps) {
  const t = useI18n([DOCUMENT_KEY], { keys: documentEn })
  const {
    scans = [],
    name,
    uploadedOn,
    fileUrl: initialFileUrl,
    fileName: initialFileName,
    isFileImage: initialIsFileImage,
  } = props

  const [activeIndex, setActiveIndex] = useState(0)

  // Use the hook only if we have scans, otherwise use the props directly
  const {
    fileUrl: scanFileUrl,
    fileName: scanFileName,
    isFileImage: scanIsFileImage,
  } = useFileHandler(scans, activeIndex)

  // Use scan values if available, otherwise fall back to props
  const fileUrl = scans.length > 0 ? scanFileUrl : initialFileUrl
  const fileName = scans.length > 0 ? scanFileName : initialFileName
  const isFileImage = scans.length > 0 ? scanIsFileImage : initialIsFileImage

  const handleFileChange = (next: boolean) => {
    setActiveIndex(prev => {
      const newIndex = next ? prev + 1 : prev - 1
      if (newIndex < 0) return 0
      if (newIndex >= scans.length) return scans.length - 1
      return newIndex
    })
  }

  const showNavigation = scans.length > 1

  return (
    <div className="flex flex-col gap-2 w-[550px] h-[420px]">
      <h1 className="text-tertiary-grey-800 text-lg font-bold">
        {name ?? fileName ?? t('document')}
      </h1>
      <figure className="flex flex-grow-1 items-center justify-center border border-tertiary-grey-300 bg-tertiary-grey-100 rounded-sm relative">
        {showNavigation && (
          <div className="border border-tertiary-grey-200 flex bg-mono-white rounded-sm absolute right-2 top-0 z-10">
            <FrankieButton
              intent="subtle"
              size="xs"
              className="border-r border-solid border-tertiary-grey-200 rounded-none"
              singleIcon={{ name: 'mdiChevronLeft' }}
              disabled={activeIndex === 0}
              onClick={() => handleFileChange(false)}
            />
            <FrankieButton
              intent="subtle"
              size="xs"
              disabled={activeIndex === scans.length - 1}
              singleIcon={{ name: 'mdiChevronRight' }}
              onClick={() => handleFileChange(true)}
            />
          </div>
        )}
        {isFileImage ? (
          <img
            src={fileUrl ?? ''}
            alt={name ?? fileName ?? t('document')}
            className="max-w-full max-h-full object-contain"
          />
        ) : (
          <PdfPreview
            url={fileUrl ?? ''}
            pageProps={{
              renderAnnotationLayer: false,
              renderTextLayer: false,
            }}
          />
        )}
      </figure>
      {uploadedOn && (
        <p className="text-sm text-tertiary-grey-600">
          {t('uploadedOn', {
            date: formatDate(uploadedOn, DateFormatTypes.fullDateWithTime),
          })}
        </p>
      )}
    </div>
  )
}
