import {
  ProcessResultManualStatusEnumAML,
  WorkflowStatusKeysTypes,
} from 'entities/entity'

import { TrackingEventsTypes } from 'shared/tracking'

export enum CommentsTypes {
  WORKFLOW = 'COMMENT_WORKFLOW',
  AML = 'COMMENT_AML',
  PROFILE = 'COMMENT_PROFILE',
  AUDIT = 'AUDIT',
  PROFILE_PERSONAL_INFO = 'COMMENT_PROFILE_PERSONAL_INFO',
  AMLRESULT = 'COMMENT_AMLRESULT',
  AMLHISTORY = 'COMMENT_AMLHISTORY',
  AMLSCREENING = 'COMMENT_AMLSCREENING',
  IDV_DOCUMENT = 'COMMENT_IDV_DOCUMENT',
  IDV_BIOMETRICS = 'COMMENT_IDV_BIOMETRICS',
}

export enum FunctionNameTypes {
  CREATEINDIVIDUAL = 'CreateIndividual',
  UPDATEINDIVIDUAL = 'UpdateIndividual',
  UPDATEPROFILE = 'UpdateProfile',
  OVERRIDEWORKFLOWEXECUTIONRESULT = 'OverrideWorkflowExecutionResult',
  UPDATEAML = 'UpdateAML',
  UPDATE_IDV = 'UpdateIDV',
}

export type SupplementaryData = {
  resultsUpdated?: {
    changeDescription: string
    newStatus: ProcessResultManualStatusEnumAML
    objectId: string
    objectType: string
  }[]

  fieldsUpdated?: {
    changeDescription: string
    objectId: string
    objectType: string
  }[]
}

export const successVariantsForAML = [
  ProcessResultManualStatusEnumAML.FALSE_POSITIVE,
  ProcessResultManualStatusEnumAML.UNKNOWN_ACCEPT,
  ProcessResultManualStatusEnumAML.TRUE_POSITIVE_ACCEPT,
]

export const failedVariantsForAML = [
  ProcessResultManualStatusEnumAML.UNKNOWN_REJECT,
  ProcessResultManualStatusEnumAML.TRUE_POSITIVE_REJECT,
]

export const warningVariantsForAML = [
  ProcessResultManualStatusEnumAML.UNKNOWN,
  ProcessResultManualStatusEnumAML.TRUE_POSITIVE,
]

export const successVariantsForWorkflow = [
  WorkflowStatusKeysTypes.MANUALLY_PASSED,
  WorkflowStatusKeysTypes.PASSED,
]

export const failedVariantsForWorkflow = [
  WorkflowStatusKeysTypes.MANUALLY_FAILED,
  WorkflowStatusKeysTypes.FAILED,
]

export const warningVariantsForWorkflow = [
  WorkflowStatusKeysTypes.NEEDS_ATTENTION,
]

export const alertVariantsForWorkflow = [WorkflowStatusKeysTypes.UNCHECKED]

type eventAction =
  | 'next'
  | 'prev'
  | 'expand'
  | 'collapse'
  | 'expandedCollapse'
  | 'hover'
  | 'viewBatch'

export const eventMapper: Record<
  CommentsTypes,
  Partial<Record<eventAction, TrackingEventsTypes>>
> = {
  [CommentsTypes.WORKFLOW]: {
    next: TrackingEventsTypes.CommentWorkflowEventExpandedNext,
    prev: TrackingEventsTypes.CommentWorkflowEventExpandedPrevious,
    expand: TrackingEventsTypes.CommentWorkflowEventExpand,
    collapse: TrackingEventsTypes.CommentWorkflowEventCollapse,
    expandedCollapse: TrackingEventsTypes.CommentWorkflowEventExpandedCollapse,
  },
  [CommentsTypes.PROFILE]: {
    expand: TrackingEventsTypes.CommentProfileExpand,
    collapse: TrackingEventsTypes.CommentProfileCollapse,
    expandedCollapse: TrackingEventsTypes.CommentProfileExpandedCollapse,
  },
  [CommentsTypes.PROFILE_PERSONAL_INFO]: {
    hover: TrackingEventsTypes.CommentProfilePersonalInfoHover,
    expand: TrackingEventsTypes.CommentProfilePersonalInfoExpand,
    collapse: TrackingEventsTypes.CommentProfilePersonalInfoCollapse,
    expandedCollapse: TrackingEventsTypes.CommentProfileExpandedCollapse,
  },
  [CommentsTypes.AML]: {
    hover: TrackingEventsTypes.CommentWorkflowAmlAlertHover,
    expand: TrackingEventsTypes.CommentWorkflowAmlAlertExpand,
    collapse: TrackingEventsTypes.CommentWorkflowAmlAlertCollapse,
    expandedCollapse: TrackingEventsTypes.CommentWorkflowEventExpandedCollapse,
  },
  [CommentsTypes.AMLHISTORY]: {
    hover: TrackingEventsTypes.CommentAmlHistoryAlertHover,
    expand: TrackingEventsTypes.CommentAmlHistoryAlertExpand,
    collapse: TrackingEventsTypes.CommentAmlHistoryAlertCollapse,
    expandedCollapse:
      TrackingEventsTypes.CommentAmlHistoryAlertExpandedCollapse,
    viewBatch: TrackingEventsTypes.CommentAmlHistoryAlertExpandedViewBatch,
  },
  [CommentsTypes.AMLSCREENING]: {
    hover: TrackingEventsTypes.CommentAmlHover,
    expand: TrackingEventsTypes.CommentAmlExpand,
    collapse: TrackingEventsTypes.CommentAmlCollapse,
    expandedCollapse: TrackingEventsTypes.CommentAmlExpandedCollapse,
  },

  [CommentsTypes.AMLRESULT]: {
    hover: TrackingEventsTypes.CommentAmlAlertHover,
    expand: TrackingEventsTypes.CommentAmlAlertExpand,
    collapse: TrackingEventsTypes.CommentAmlAlertCollapse,
    expandedCollapse: TrackingEventsTypes.CommentAmlAlertExpandedCollapse,
  },
  [CommentsTypes.AUDIT]: {},
  [CommentsTypes.IDV_DOCUMENT]: {
    hover: TrackingEventsTypes.CommentIdvDocumentHover,
    expand: TrackingEventsTypes.CommentIdvDocumentExpand,
    collapse: TrackingEventsTypes.CommentIdvDocumentCollapse,
    expandedCollapse: TrackingEventsTypes.CommentIdvDocumentExpandedCollapse,
  },
  [CommentsTypes.IDV_BIOMETRICS]: {
    hover: TrackingEventsTypes.CommentIdvBiometricsHover,
    expand: TrackingEventsTypes.CommentIdvBiometricsExpand,
    collapse: TrackingEventsTypes.CommentIdvBiometricsCollapse,
    expandedCollapse: TrackingEventsTypes.CommentIdvBiometricsExpandedCollapse,
  },
}

export function decodeHtmlEntities(str: string) {
  const entities = {
    '&lt;': '<',
    '&gt;': '>',
    '&amp;': '&',
    '&quot;': '"',
    '&apos;': "'",
    '&cent;': '¢',
    '&pound;': '£',
    '&yen;': '¥',
    '&euro;': '€',
    '&copy;': '©',
    '&reg;': '®',
    '&trade;': '™',
    '&nbsp;': '\u00A0',
    '&mdash;': '—',
    '&ndash;': '–',
    '&lsquo;': '‘',
    '&rsquo;': '’',
    '&ldquo;': '“',
    '&rdquo;': '”',
    '&hellip;': '…',
    '&permil;': '‰',
    '&eacute;': 'é',
    '&agrave;': 'à',
    '&ouml;': 'ö',
    '&uuml;': 'ü',
    '&ntilde;': 'ñ',
    '&#39;': "'",
    '&#x2F;': '/',
    '&#x27;': "'",
  }

  return str.replace(
    /&[a-zA-Z0-9#]+;/g,
    (match: string): string =>
      (entities as Record<string, string>)[match] || match,
  )
}
