import React, { Fragment } from 'react'

import cx from 'classnames'

import { FrankieIcon } from 'frankify/src'

import {
  IdvDocument,
  IdvCheckReport,
} from 'features/individual-idv-check/model/idv-documents.model'

type ReportSectionProps = {
  report: IdvDocument['report']
}

const getIconProps = (report: IdvCheckReport) => {
  if (report.isStatusUnknown) {
    return {
      name: 'mdiMinusCircle' as const,
      className: cx('self-center text-tertiary-grey-400 mb-0.5'),
    }
  }

  if (report.success) {
    return {
      name: 'mdiCheckCircle' as const,
      className: cx('self-center text-tertiary-green-600 mb-0.5'),
    }
  }

  return {
    name: 'mdiCloseCircle' as const,
    className: cx('self-center text-tertiary-red-700 mb-0.5'),
  }
}

export function ReportSection({ report }: ReportSectionProps) {
  return (
    <div className="flex flex-wrap">
      {report.map(reportSection => {
        const isScoresCategory = reportSection.key === 'Scores'

        return (
          <div key={reportSection.key} className="basis-1/5 pr-4 min-w-[199px]">
            <div className="text-tertiary-grey-800 font-medium flex items-center gap-1.5 mb-2">
              {!reportSection.isStatusUnknown && (
                <FrankieIcon
                  name={
                    reportSection.success ? 'mdiCheckCircle' : 'mdiCloseCircle'
                  }
                  size="xs"
                  className={cx(
                    reportSection.success
                      ? 'text-tertiary-green-600'
                      : 'text-tertiary-red-700',
                  )}
                />
              )}
              <span>{reportSection.title}</span>
            </div>
            {reportSection.children?.map(report => (
              <Fragment key={report.key}>
                <div
                  className={cx(
                    'font-medium text-xs flex items-baseline gap-1.5 mb-1.5 leading-[18px]',
                    {
                      'ml-4 text-tertiary-grey-500': !isScoresCategory,
                    },
                  )}
                >
                  {!report.value && (
                    <FrankieIcon size="2xs" {...getIconProps(report)} />
                  )}
                  <span>{report.title}</span>
                </div>
                {report.value && (
                  <p className="flex items-baseline gap-1.5 justify-start mb-1.5 text-tertiary-grey-500 text-xs leading-[18px]">
                    {report.value}
                  </p>
                )}
              </Fragment>
            ))}
          </div>
        )
      })}
    </div>
  )
}
