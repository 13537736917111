import React from 'react'

import classNames from 'classnames'

import { FrankieButton, FrankieIcon } from 'frankify/src'

import { PdfPreview } from 'shared/pdf-preview/ui/pdf-preview'

export type GalleryItem = {
  id: string
  url: string
  mimeType?: string
  type: 'PHOTO' | 'VIDEO' | 'PDF'
  side: 'FRONT' | 'BACK'
  createdAt?: string
}

type Props = {
  gallery: GalleryItem[]
  selected: number
  onChange: (index: number) => void
  className?: string
  imageClassName?: string
}

function ImageGalleryComp({
  gallery,
  onChange,
  selected,
  className,
  imageClassName,
}: Props) {
  const renderMedia = (attachment: GalleryItem) => {
    switch (attachment.type) {
      case 'PHOTO':
        return (
          <img
            src={attachment.url}
            alt="gallery"
            className={classNames(
              'w-full aspect-square object-cover',
              imageClassName,
            )}
          />
        )
      case 'VIDEO':
        return (
          <div className="relative">
            <div className="absolute flex justify-center items-center w-full h-full">
              <FrankieIcon
                size="lg"
                className="text-mono-white"
                name="mdiPlayCircleOutline"
              />
            </div>

            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              src={attachment.url}
              className={classNames(
                'w-full aspect-square object-cover',
                imageClassName,
              )}
            />
          </div>
        )
      case 'PDF':
        return (
          <div className="w-full aspect-square overflow-hidden">
            <PdfPreview
              url={attachment.url}
              documentProps={{ loading: null }}
              pageProps={{
                pageNumber: 1,
                width: 70,
                renderAnnotationLayer: false,
                renderTextLayer: false,
              }}
            />
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div
      className={classNames(
        'flex gap-1 pb-2 w-auto scrollbar overflow-x-scroll',
        className,
      )}
    >
      {gallery.map((attachment, index) => {
        const isSel = index === selected

        return (
          <FrankieButton
            key={attachment.id}
            noStyles
            className={classNames(
              'min-w-[70px]  max-w-[70px] rounded-sm overflow-hidden relative aspect-[5/4]',
            )}
            onClick={() => onChange(index)}
          >
            <div
              className={classNames(
                ' w-full h-full rounded-sm border-solid absolute z-[1]',
                isSel
                  ? 'border-2 border-primary-600'
                  : 'border border-tertiary-grey-200',
              )}
            />
            {renderMedia(attachment)}
          </FrankieButton>
        )
      })}
    </div>
  )
}

const ImageGallery = React.memo(ImageGalleryComp)

export { ImageGallery }
