import { DocumentAttachment, DocumentInformation } from './entity.model'

export const transformAttachmentType = (attachments: DocumentAttachment[]) =>
  attachments.map(attachment => {
    if (attachment.mimeType === 'application/pdf') {
      return { ...attachment, type: 'PDF' }
    }
    return attachment
  })

export const transformDocumentType = (documents: DocumentInformation[]) =>
  documents.map(doc => {
    if (doc.attachments) {
      return { ...doc, attachments: transformAttachmentType(doc.attachments) }
    }
    return doc
  })
