import { countryList, CountryAlpha3CodeTypes } from 'entities/country'

import { Address, Individual, ServiceProfile } from './entity.model'

export function extractLongForm(address?: Address): string {
  if (!address) return ''

  const {
    buildingName,
    unitNumber,
    streetNumber,
    streetName,
    streetType,
    subdivision,
    postalCode,
    country,
    locality,
    unstructuredLongForm,
    neighborhood,
  } = address

  if (unstructuredLongForm) return unstructuredLongForm

  function getCountryDetails(code3: string) {
    const country = countryList.find(c => c.alpha3code === code3)
    const countryObject = {
      label: country?.name || code3,
    }
    return countryObject
  }

  const countryName = (country && getCountryDetails(country).label) || null

  if (
    ![
      // if missing important information, return unstructuredLongForm OR null
      streetName,
      postalCode,
      countryName,
    ].every(Boolean)
  )
    return address.unstructuredLongForm || ''

  const streetNumberLine = [unitNumber, streetNumber]
    .filter(Boolean)
    .join(' / ')
  const unitLine = [buildingName, streetNumberLine].filter(Boolean).join(', ')
  const streetLine = [unitLine, streetName, streetType]
    .filter(Boolean)
    .join(' ')

  const suburbLinne = [neighborhood].filter(Boolean).join(' ')
  const subdivisionLine = [locality, subdivision, postalCode]
    .filter(Boolean)
    .join(' ')
  return [
    streetLine,
    country === CountryAlpha3CodeTypes.Australia ? suburbLinne : undefined,
    subdivisionLine,
    countryName,
  ]
    .filter(Boolean)
    .join(', ')
}

export type AssignEntityResponse = {
  meta: {
    passed: number
    failed: number
    total: number
  }
  data: {
    serviceProfile: ServiceProfile
    individual: Individual | undefined
  }[]
}

export type ExecuteWorkflowPayload = {
  workflowName?: string
  comment?: string
}
