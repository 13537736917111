/* eslint-disable no-nested-ternary */
import React, { memo, useMemo } from 'react'

import { IconButton } from '@mui/material'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'

import { FrankieIcon, FrankieTooltip } from 'frankify/src'

import { COMMENT_KEY } from 'entities/comments/comments.key'
import { commentsEn } from 'entities/comments/locale/comments.en'
import { commentToolTipQa } from 'entities/comments/qa/comments.qa'
import { useApplicantRoute } from 'entities/routing'

import { useI18n } from 'shared/i18n'
import { trackingManager } from 'shared/tracking'

import { CommentsTypes, eventMapper } from '../../model/comments.model'
import {
  useCommentBoxVisibility,
  useCommentData,
  useUpdateCommentBoxVisibility,
} from '../../states/comments-states/comments.state'
import { CommentBox } from '../comment-box/comment-box'

type CommentToolTipProps = {
  className?: string
  logoClassName?: string
  commentType: CommentsTypes
  entityId: string
  processId?: string
}

export const bulkCommentEnablingMapper: Partial<
  Record<CommentsTypes, CommentsTypes[]>
> = {
  [CommentsTypes.WORKFLOW]: [
    CommentsTypes.AML,
    CommentsTypes.WORKFLOW,
    CommentsTypes.IDV_DOCUMENT,
    CommentsTypes.IDV_BIOMETRICS,
  ],
  [CommentsTypes.PROFILE]: [
    CommentsTypes.PROFILE_PERSONAL_INFO,
    CommentsTypes.PROFILE,
  ],
  [CommentsTypes.AUDIT]: [CommentsTypes.AUDIT],
}

export const CommentToolTip = memo(
  // eslint-disable-next-line complexity
  ({
    className = '',
    logoClassName = '',
    commentType,
    entityId,
    processId,
  }: CommentToolTipProps) => {
    const { generateRoute } = useApplicantRoute()
    const { data: visibility } = useCommentBoxVisibility({ entityId })

    const global = Object.keys(bulkCommentEnablingMapper).includes(commentType)

    const profileRoute = generateRoute({
      routeKey: 'individualProfileInsight',
      overrideParams: { entityId },
    })
    const location = useLocation()
    const currentLocation = location.pathname
    const isProfileTab = currentLocation === profileRoute

    const updateCommentBoxVisibility = useUpdateCommentBoxVisibility({
      entityId,
      isProfileTab,
    })
    const t = useI18n([COMMENT_KEY], { keys: commentsEn })

    const { data } = useCommentData({
      entityId,
      commentType:
        (CommentsTypes.WORKFLOW === commentType
          ? bulkCommentEnablingMapper[commentType]
          : commentType) ?? [],
      processId,
    })

    const { data: amlData } = useCommentData({
      entityId,
      commentType: CommentsTypes.AML,
      processId,
    })
    const { data: profileData } = useCommentData({
      entityId,
      commentType: CommentsTypes.PROFILE,
      processId,
    })

    const isGlobalBtnDisable = !data.length && !amlData.length && global
    const isPartialEnabled = bulkCommentEnablingMapper[commentType]?.some(
      (type: CommentsTypes) => visibility && !visibility[type],
    )

    const toolTipText = useMemo(
      () => (data.length ? t('globalIconTitle') : t('noCommentText')),
      [isProfileTab, profileData.length, isGlobalBtnDisable, t],
    )

    if ((!global && !data.length) || commentType === CommentsTypes.AUDIT)
      return null

    return (
      <div className={`${className}`} data-qa={commentToolTipQa.container}>
        <FrankieTooltip
          className={`${
            global
              ? ''
              : '!max-w-[252px] !bg-tertiary-grey-200 after:!bg-tertiary-grey-200 !p-4'
          }`}
          body={
            global ? (
              <div className="tertiary-grey-400 text-xs leading-4">
                {toolTipText}
              </div>
            ) : (
              <CommentBox
                entityId={entityId}
                processId={processId}
                commentType={commentType}
              />
            )
          }
          position={global ? 'bottom' : 'left'}
        >
          <div
            className="relative h-[16px] w-[16px] rounded-sm p-2 "
            data-qa={commentToolTipQa.logo}
          >
            <IconButton
              className="absolute inset-0  rounded-sm z-[2]"
              disabled={global && !data.length}
              onClick={e => {
                e.stopPropagation()
                if (!visibility?.[commentType]) {
                  trackingManager.track(
                    eventMapper[commentType].expand as string,
                  )
                } else {
                  trackingManager.track(
                    eventMapper[commentType].collapse as string,
                  )
                }
                // TODO: Only display tooltip if the comment is collapsed
                updateCommentBoxVisibility({
                  payload: global
                    ? bulkCommentEnablingMapper[commentType] || []
                    : commentType,
                  overrideState: global ? isPartialEnabled : undefined,
                })
              }}
            />

            <FrankieIcon
              name="mdiCommentTextOutline"
              className={classNames(
                {
                  'text-tertiary-grey-400':
                    isProfileTab && global
                      ? !profileData.length
                      : isGlobalBtnDisable,
                  'text-tertiary-grey-700':
                    isProfileTab && global
                      ? profileData.length
                      : !global || data.length || amlData.length,
                },
                'z-[1] absolute flex items-center justify-center inset-0',
                logoClassName,
              )}
              size="xs"
            />
          </div>
        </FrankieTooltip>
      </div>
    )
  },
)
CommentToolTip.displayName = 'CommentToolTip'
