import React, { useEffect } from 'react'

import { useSearchParams } from 'react-router-dom'

import { FrankieButton, FrankieLoader } from 'frankify/src'

import { useHasFeatureFlag } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { ORGANISATION_SETTINGS_KEY } from '../../organisation-settings.key'
import { integrationSectionsQa } from '../../qa/organisation-settings.qa'
import { useSlackDisintegrationMutation } from '../../state/integrations/slack-disintegration.mutation'
import { useSlackIntegrationMutation } from '../../state/integrations/slack-integration.mutation'
import { useIntegrationQuery } from '../../state/integrations/slack-integration.query'

function IntegrationSections() {
  const t = useI18n([ORGANISATION_SETTINGS_KEY])
  const { isSlackEnabled } = useHasFeatureFlag({
    isSlackEnabled: 'slackNotifications',
  })

  const [searchParams, setSearchParams] = useSearchParams()
  const code = searchParams.get('code')

  const { data } = useIntegrationQuery()

  const { isLoading, mutateAsync } = useSlackIntegrationMutation()
  const [createOverlay, closeOverlay] = useOverlay()
  const { mutate } = useSlackDisintegrationMutation()

  const handleMutate = async (code: string) => {
    const { protocol, host, pathname } = window.location
    const urlPieces = [protocol, '//', host, pathname]
    const redirectUri = urlPieces.join('')

    await mutateAsync({
      configValue: code,
      redirectUri,
    })
    trackingManager.track(TrackingEventsTypes.SlackIntegrationConnected)

    searchParams.delete('code')
    setSearchParams(searchParams)
  }

  useEffect(() => {
    if (code) {
      void handleMutate(code)
    }
  }, [code])

  const handleDisintegration = () => {
    createOverlay(
      <div className="flex flex-col gap-3 w-[400px]">
        <div className="text-xl text-tertiary-grey-800 font-bold mr-8">
          {t('integration.disconnect.title')}
        </div>
        <div className="text-mono-black">
          {t('integration.disconnect.description')}
        </div>
        <div className="flex gap-5 mt-3 justify-end items-center">
          <FrankieButton
            noStyles
            className="py-2 px-3 font-semibold text-tertiary-grey-600"
            onClick={() => {
              closeOverlay()
            }}
          >
            {t('integration.disconnect.cancel')}
          </FrankieButton>
          <FrankieButton
            onClick={() => {
              mutate()
              closeOverlay()
              trackingManager.track(
                TrackingEventsTypes.SlackIntegrationDisconnect,
              )
            }}
          >
            {t('integration.disconnect.submit')}
          </FrankieButton>
        </div>
      </div>,
      {
        className: '!p-5',
        closeButtonClassName: '!top-5 !right-5',
      },
    )
  }

  const handleSlackIntegration = () => {
    if (!data) return
    const slackUrl = encodeURI(
      `https://slack.com/oauth/v2/authorize?scope=chat:write,chat:write.public,im:write,users:read,users:read.email&user_scope=&redirect_uri=${window.location.origin}/admin/settings&client_id=${data.slackAppClientId}`,
    )
    window.location.href = slackUrl
    trackingManager.track(TrackingEventsTypes.SlackIntegrationConnect)
  }

  if (!isSlackEnabled) return null

  return (
    <div
      className="flex flex-initial flex-col gap-3"
      data-qa={integrationSectionsQa.container}
    >
      <FrankieLoader
        loading={isLoading}
        fullscreen
        size="md"
        label={t('integration.loading')}
      />
      <div
        data-qa={integrationSectionsQa.title}
        className="text-tertiary-grey-800 text-md font-semibold"
      >
        {t('integration.title')}
      </div>
      <div
        data-qa={integrationSectionsQa.logo}
        className="border border-solid border-tertiary-grey-200 p-6 gap-4 flex items-center"
      >
        <svg
          height="40px"
          width="40px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 122.8 122.8"
        >
          <path
            d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
            fill="#e01e5a"
          />
          <path
            d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
            fill="#36c5f0"
          />
          <path
            d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
            fill="#2eb67d"
          />
          <path
            d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
            fill="#ecb22e"
          />
        </svg>
        <div>
          <div
            data-qa={integrationSectionsQa.slack}
            className="text-lg font-semibold text-tertiary-grey-700"
          >
            {t('integration.slack')}
          </div>
          <div
            className="leading-tight text-tertiary-grey-500"
            data-qa={integrationSectionsQa.desc}
          >
            {data?.config?.configValue || t('integration.description')}
          </div>
        </div>
        <div className="ml-auto" data-qa={integrationSectionsQa.btnContainer}>
          {data &&
            (!data.config ? (
              <FrankieButton
                testId={{ button: integrationSectionsQa.connectBtn }}
                onClick={handleSlackIntegration}
              >
                {t('integration.cta.connect')}
              </FrankieButton>
            ) : (
              <FrankieButton
                onClick={handleDisintegration}
                testId={{ button: integrationSectionsQa.disconnectBtn }}
              >
                {t('integration.cta.disconnect')}
              </FrankieButton>
            ))}
        </div>
      </div>
    </div>
  )
}

export { IntegrationSections }
