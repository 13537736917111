import { GridApiPro, GridSortCellParams } from '@mui/x-data-grid-pro'

import { FrankieBadgeTheme } from 'frankify/src'

import { individualAmlScreeningEn } from 'features/individual-aml-screening/locale/individual-aml-screening.en'

import {
  ProcessResultManualStatusEnum,
  SupplementaryDataAML,
  ProcessResultManualStatusEnumAML,
  AmlIssuesTypes,
  WorkflowStepSummaryAMLSearchParameters,
} from 'entities/entity'
import type { ProcessResultResponse } from 'entities/entity/model/entity.model'

import { I18nFunction, I18nKeys } from 'shared/i18n'
import { TrackingEventsTypes } from 'shared/tracking'

type MatchStatus = {
  id: string
  status: string
}

export type AmlRow = {
  id: string
  name: string | undefined
  matchStrength: number | undefined
  countries: string[] | undefined
  resolvedBy: string | undefined
  resolvedAt: string | undefined
  workflow: string
  issues: AmlIssuesTypes[]
  matchStatus: ProcessResultManualStatusEnum | undefined
  entityId: string | undefined
}

export function getMatchStatusBadge(
  t: I18nFunction<typeof individualAmlScreeningEn>,
  status?: ProcessResultManualStatusEnum,
): {
  theme: FrankieBadgeTheme
  text: string
} {
  if (status)
    switch (status) {
      case ProcessResultManualStatusEnumAML.FALSE_POSITIVE:
        return {
          theme: 'green',
          text: t('matchStatus.falsePositive'),
        }
      case ProcessResultManualStatusEnumAML.UNKNOWN_ACCEPT:
        return {
          theme: 'green',
          text: t('matchStatus.unknown'),
        }
      case ProcessResultManualStatusEnumAML.TRUE_POSITIVE_ACCEPT:
        return {
          theme: 'green',
          text: t('matchStatus.truePositive'),
        }
      case ProcessResultManualStatusEnumAML.UNKNOWN_REJECT:
        return {
          theme: 'red',
          text: t('matchStatus.unknown'),
        }
      case ProcessResultManualStatusEnumAML.TRUE_POSITIVE_REJECT:
        return {
          theme: 'red',
          text: t('matchStatus.truePositive'),
        }
      case ProcessResultManualStatusEnumAML.TRUE_POSITIVE:
        return {
          theme: 'yellow',
          text: t('matchStatus.truePositive'),
        }
      case ProcessResultManualStatusEnumAML.UNKNOWN:
      default:
        return {
          theme: 'yellow',
          text: t('matchStatus.unknown'),
        }
    }
  return {
    theme: 'yellow',
    text: t('matchStatus.potentialMatch'),
  }
}

export function getAmlIssues(
  supplementaryData: SupplementaryDataAML,
  t: I18nFunction<typeof individualAmlScreeningEn>,
) {
  const issues = []

  if ((supplementaryData.pepData?.length ?? 0) > 0) {
    issues.push(AmlIssuesTypes.PEP)
  }

  if ((supplementaryData.mediaData?.length ?? 0) > 0) {
    issues.push(AmlIssuesTypes.ADVERSE_MEDIA)
  }

  if ((supplementaryData.sanctionData?.length ?? 0) > 0) {
    issues.push(AmlIssuesTypes.SANCTION)
  }

  if ((supplementaryData.watchlistData?.length ?? 0) > 0) {
    issues.push(AmlIssuesTypes.WATCH_LIST)
  }

  return issues
}

export const mergeMatchStatuses = (
  oldStatus: MatchStatus[],
  newResponses: ProcessResultResponse[],
): MatchStatus[] => {
  const statusMap = new Map<string, MatchStatus>()

  // Set initial statuses
  oldStatus.forEach(status => {
    statusMap.set(status.id, status)
  })

  // Merge all new responses
  newResponses.forEach(response => {
    response.processResults.forEach(result => {
      statusMap.set(result.processResultId, {
        id: result.processResultId,
        status:
          result.manualStatus ||
          statusMap.get(result.processResultId)?.status ||
          '',
      })
    })
  })

  return Array.from(statusMap.values())
}

export const amlIssuesToI18n: Record<
  AmlIssuesTypes,
  I18nKeys<typeof individualAmlScreeningEn>
> = {
  [AmlIssuesTypes.PEP]: 'amlIssues.pep',
  [AmlIssuesTypes.ADVERSE_MEDIA]: 'amlIssues.adverseMedia',
  [AmlIssuesTypes.SANCTION]: 'amlIssues.sanction',
  [AmlIssuesTypes.WATCH_LIST]: 'amlIssues.watchlist',
}

export type AmlFilters = {
  amlMatch: (keyof WorkflowStepSummaryAMLSearchParameters)[]
  matchStatus: string[]
  issues: AmlIssuesTypes[]
  workflow: string[]
}

// TP reject > Unknown reject > Potential match > TP accept > Unknown accept > False positive
const sortPriority = [
  ProcessResultManualStatusEnumAML.TRUE_POSITIVE_REJECT,
  ProcessResultManualStatusEnumAML.UNKNOWN_REJECT,
  undefined,
  null,
  ProcessResultManualStatusEnumAML.TRUE_POSITIVE_ACCEPT,
  ProcessResultManualStatusEnumAML.UNKNOWN_ACCEPT,
  ProcessResultManualStatusEnumAML.FALSE_POSITIVE,
]

export const sortMatchStatus = (
  v1: ProcessResultManualStatusEnumAML,
  v2: ProcessResultManualStatusEnumAML,
  v1Params: GridSortCellParams<ProcessResultManualStatusEnumAML>,
  v2Params: GridSortCellParams<ProcessResultManualStatusEnumAML>,
  // eslint-disable-next-line max-params
) => {
  const v1Index = sortPriority.indexOf(v1)
  const v2Index = sortPriority.indexOf(v2)

  const matchStrength1 = (v1Params.api as GridApiPro).getCellValue<number>(
    v1Params.id,
    'matchStrength',
  )

  const matchStrength2 = (v2Params.api as GridApiPro).getCellValue<number>(
    v2Params.id,
    'matchStrength',
  )

  if (v1Index < v2Index) return 1
  if (v1Index === v2Index) {
    if (Number(matchStrength1) && Number(matchStrength2)) {
      if (matchStrength1 < matchStrength2) return -1
      if (matchStrength1 > matchStrength2) return 1
      return 0
    }
    return 0
  }

  return -1
}

export const amlKeys = [
  'amlMatch',
  'matchStatus',
  'issues',
  'workflow',
] as const

export const amlFilterTrackingEventsMapping: Record<
  (typeof amlKeys)[number],
  TrackingEventsTypes
> = {
  amlMatch: TrackingEventsTypes.AmlEventsFilterAmlMatchApply,
  matchStatus: TrackingEventsTypes.AmlEventsFilterMatchStatusApply,
  issues: TrackingEventsTypes.AmlEventsFilterIssuesApply,
  workflow: TrackingEventsTypes.AmlEventsFilterWorkflowApply,
}
