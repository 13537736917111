import React, { useEffect, useMemo, useRef, useState } from 'react'

import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import { navigateToUrl } from 'single-spa'

import {
  FrankieBadge,
  FrankiePopover,
  FrankieTextField,
  FrankieTooltip,
  upBreakpoint,
} from 'frankify/src'

import { ApplicantsSearchParamsTypes } from 'features/applicants-filter'
import { ProfileSearchParamsTypes } from 'features/profiles-filter'

import { ApplicantEntityTypes } from 'entities/applicant'
import { useGetPreviousPath } from 'entities/routing'
import { useHasFeatureFlag } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { getLocalStorageState, StorageKeyTypes } from 'shared/local-storage'
import { useGlobalSearchStore } from 'shared/state-management/client'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { GLOBAL_SEARCH_KEY } from '../../locale/global-search.en'
import {
  getGlobalSearch,
  getSearchByType,
  GLOBAL_SEARCH_SEARCH_BY_PARAM,
  GLOBAL_SEARCH_VALUE_MAX_LENGTH,
  globalSearchControlledParams,
  IGlobalSearchMemo,
  searchByI18nMap,
  searchByPlaceholderI18nMap,
  SearchByTypes,
  updateGlobalSearchHistory,
  validateGlobalSearchHistoryStorage,
} from '../../model/global-search.model'
import { globalSearchQa } from '../../qa/global-search.qa'
import { GlobalSearchControlPanel } from '../global-search-control-panel/global-search-control-panel'

type Props = {
  redirectPath: string
  open: boolean
  setOpen: (open: boolean) => void
  shouldResetFilter?: boolean
  isSearchProfiles?: boolean
}

// eslint-disable-next-line complexity
export function GlobalSearch({
  isSearchProfiles = false,
  redirectPath,
  setOpen,
  open,
  shouldResetFilter,
}: Props) {
  const t = useI18n([GLOBAL_SEARCH_KEY])
  const [searchParams, setSearchParams] = useSearchParams()
  const inputRef = useRef<HTMLInputElement>(null)

  const previousPath = useGetPreviousPath()

  const { isFrankie2 } = useHasFeatureFlag({ isFrankie2: 'frankie2customer' })

  const [value, setValue] = useState<string>('')

  const [globalSearchHistoryStorage, setGlobalSearchHistoryStorage] = useMemo(
    () => getLocalStorageState(StorageKeyTypes.GlobalSearch),
    [],
  )

  const [globalSearchHistory, setGlobalSearchHistory] = useState<
    IGlobalSearchMemo[]
  >(
    validateGlobalSearchHistoryStorage(
      globalSearchHistoryStorage,
      isSearchProfiles,
    ),
  )

  const defaultSearchBy =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    globalSearchHistory[0]?.searchBy || SearchByTypes.IndividualName

  const [searchBy, setSearchBy] = useState<SearchByTypes>(defaultSearchBy)

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (!open) {
      const { searchBy, value } = getGlobalSearch(searchParams)
      if (searchBy) {
        setSearchBy(searchBy)
      }
      setValue(value)
    }
  }, [searchParams, open])

  const hasValue = !!value
  const isShowBadge: boolean = open || !!value

  const isSearchParamsEmpty = (): boolean =>
    globalSearchControlledParams.every(param => !searchParams.has(param))

  const focus = () =>
    setTimeout(() => {
      inputRef.current?.focus()
    }, 1)

  const blur = () =>
    setTimeout(() => {
      inputRef.current?.blur()
    }, 1)

  const handleOpen = () => {
    setOpen(true)
    focus()
  }

  const close = () => setOpen(false)

  const setSearchByType = (value: string) => {
    const searchByType: SearchByTypes = getSearchByType(value, searchBy)
    setSearchBy(searchByType)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    setSearchByType(e.target.value)
  }

  const handleChangeSearchBy = (newSearchBy: SearchByTypes) => {
    setSearchBy(newSearchBy)
    focus()
  }

  const triggerNavigation = () => {
    navigateToUrl(`${redirectPath}?${searchParams.toString()}`)
  }

  const triggerNavigationToPreviousPath = () => {
    navigateToUrl(`${previousPath.split('?')[0]}?${searchParams.toString()}`)
  }

  const saveSearchHistory = (memo: IGlobalSearchMemo) => {
    const newGlobalSearchHistory = updateGlobalSearchHistory(
      globalSearchHistory,
      memo,
    )
    setGlobalSearchHistory(newGlobalSearchHistory)
    setGlobalSearchHistoryStorage(newGlobalSearchHistory)
  }

  const setAllSearchParams = ({
    searchBy,
    nameFilter,
    entityTypeFilter,
    entityIdFilter,
    customerIdFilter,
  }: {
    searchBy: string
    nameFilter: string
    entityTypeFilter: ApplicantEntityTypes | ''
    entityIdFilter: string
    customerIdFilter: string
  }) => {
    setSearchParams(prev => {
      if (searchBy) prev.set(GLOBAL_SEARCH_SEARCH_BY_PARAM, searchBy)
      else prev.delete(GLOBAL_SEARCH_SEARCH_BY_PARAM)
      if (nameFilter)
        prev.set(
          isSearchProfiles
            ? ProfileSearchParamsTypes.EntityName
            : ApplicantsSearchParamsTypes.NameFilter,
          nameFilter,
        )
      else
        prev.delete(
          isSearchProfiles
            ? ProfileSearchParamsTypes.EntityName
            : ApplicantsSearchParamsTypes.NameFilter,
        )
      // added new customerIdFilter
      if (customerIdFilter)
        prev.set(ProfileSearchParamsTypes.CustomerId, customerIdFilter)
      else prev.delete(ProfileSearchParamsTypes.CustomerId)
      if (entityTypeFilter)
        prev.set(ApplicantsSearchParamsTypes.EntityTypeFilter, entityTypeFilter)
      else prev.delete(ApplicantsSearchParamsTypes.EntityTypeFilter)
      if (entityIdFilter)
        prev.set(
          isSearchProfiles
            ? ProfileSearchParamsTypes.EntityId
            : ApplicantsSearchParamsTypes.EntityIdFilter,
          entityIdFilter,
        )
      else
        prev.delete(
          isSearchProfiles
            ? ProfileSearchParamsTypes.EntityId
            : ApplicantsSearchParamsTypes.EntityIdFilter,
        )
      return prev
    })
  }

  const clearSearchParams = () =>
    setAllSearchParams({
      searchBy: '',
      nameFilter: '',
      entityIdFilter: '',
      entityTypeFilter: '',
      customerIdFilter: '',
    })

  const saveSearchToUrl = (
    value: string,
    searchBy: SearchByTypes = SearchByTypes.IndividualName,
  ) => {
    if (shouldResetFilter) {
      // clear all search params
      setSearchParams(prev => {
        for (const key of prev.keys()) {
          prev.delete(key)
        }
        return prev
      })
    }
    if (!value) {
      clearSearchParams()
      return
    }
    if (searchBy === SearchByTypes.IndividualName) {
      setAllSearchParams({
        searchBy,
        nameFilter: value,
        entityTypeFilter: ApplicantEntityTypes.Individual,
        entityIdFilter: '',
        customerIdFilter: '',
      })
      return
    }
    if (searchBy === SearchByTypes.OrganisationName) {
      setAllSearchParams({
        searchBy,
        nameFilter: value,
        entityTypeFilter: ApplicantEntityTypes.Organisation,
        entityIdFilter: '',
        customerIdFilter: '',
      })
      return
    }
    if (searchBy === SearchByTypes.CustomerId && isSearchProfiles) {
      setAllSearchParams({
        searchBy,
        nameFilter: '',
        entityIdFilter: '',
        customerIdFilter: value,
        entityTypeFilter: '',
      })
      return
    }
    setAllSearchParams({
      searchBy,
      nameFilter: '',
      entityTypeFilter: '',
      customerIdFilter: '',
      entityIdFilter: value,
    })
  }

  const setSearchRedirect = useGlobalSearchStore(
    state => state.setSearchRedirect,
  )

  const handleInputKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      saveSearchToUrl(value, searchBy)
      if (value) {
        setSearchRedirect(true)
        saveSearchHistory({ searchBy, value })
        triggerNavigation()
        trackingManager.track(TrackingEventsTypes.GlobalSearchSearch)
      }
      // when value is empty but there are old search params,
      // it means that user hits key to clear the search
      else if (!isSearchParamsEmpty()) {
        triggerNavigation()
      }
      close()
      blur()
    }
  }

  const handleInputClear = () => {
    setValue('')
    if (open) {
      focus()
    } else {
      saveSearchToUrl('', searchBy)
      if (isFrankie2) triggerNavigationToPreviousPath()
      else triggerNavigation()
    }
  }

  const handleSelectGlobalSearchMemo = (memo: IGlobalSearchMemo) => {
    setSearchRedirect(true)
    setValue(memo.value)
    setSearchBy(memo.searchBy)
    saveSearchToUrl(memo.value, memo.searchBy)
    saveSearchHistory(memo)
    triggerNavigation()
    close()
    blur()
  }

  const isIndividualName = searchBy === SearchByTypes.IndividualName
  const isOrganisationName = searchBy === SearchByTypes.OrganisationName
  const isNameSearch = isIndividualName || isOrganisationName
  const tooltipIsHidden = !open || !hasValue || isFrankie2 || !isNameSearch
  const body = isOrganisationName
    ? t('tooltipOrganisationNameTitle')
    : t('tooltipIndividualNameTitle')

  return (
    <div className="basis-full min-w-0">
      <FrankiePopover
        popoverRest={{ placement: 'bottom-start' }}
        open={open}
        onOpenChange={upBreakpoint('tablet') ? setOpen : undefined}
        trigger={
          <div className="relative">
            <FrankieTooltip
              position="right"
              body={body}
              hidden={tooltipIsHidden}
              className="relative left-2"
            >
              <div
                className={classNames({
                  'w-full mb-3 max-w-[532px] tablet:mb-0 laptop:max-w-[640px]':
                    open,
                  'w-full tablet:w-[200px] laptop:w-[344px]':
                    !open && !hasValue,
                  'w-full max-w-[344px] laptop:w-[344px]': !open && hasValue,
                })}
              >
                <FrankieTextField
                  size="sm"
                  type="search"
                  value={value}
                  onChange={handleChange}
                  onKeyDown={handleInputKeyDown}
                  maxLength={GLOBAL_SEARCH_VALUE_MAX_LENGTH}
                  variant="light"
                  inputClassName={classNames({
                    // tailwind doesn't support dynamic custom values
                    '!pl-[157px]':
                      isShowBadge && searchBy === SearchByTypes.IndividualName,
                    '!pl-[174px]':
                      isShowBadge &&
                      searchBy === SearchByTypes.OrganisationName,
                    '!pl-[139px]':
                      isShowBadge && searchBy === SearchByTypes.CustomerId,
                    '!pl-[127px]':
                      isShowBadge && searchBy === SearchByTypes.FrankieId,
                    '!pl-[131px]':
                      isShowBadge && searchBy === SearchByTypes.AbnOrAcn,
                  })}
                  isSearchIcon
                  badge={
                    isShowBadge && (
                      <FrankieBadge
                        theme="blue"
                        onClick={handleOpen}
                        text={t(searchByI18nMap(isSearchProfiles)[searchBy])}
                        className="absolute top-2 left-[42px]"
                        testId={{ badge: globalSearchQa.searchBadge }}
                      />
                    )
                  }
                  placeholder={t(
                    open
                      ? searchByPlaceholderI18nMap(isSearchProfiles)[searchBy]
                      : 'defaultPlaceholder',
                  )}
                  closeButton={
                    value ? { onClick: handleInputClear } : undefined
                  }
                  ref={inputRef}
                  onClick={handleOpen}
                  testId={{
                    input: globalSearchQa.searchInput,
                    closeCta: globalSearchQa.cta.searchClear,
                  }}
                />
              </div>
            </FrankieTooltip>
          </div>
        }
      >
        <div className=" w-full tablet:block tablet:w-[532px] laptop:w-[640px] rounded-sm bg-mono-white py-3 shadow-lg">
          <GlobalSearchControlPanel
            isSearchProfiles={isSearchProfiles}
            history={globalSearchHistory}
            searchBy={searchBy}
            onChangeSearchBy={handleChangeSearchBy}
            onSelectGlobalSearchMemo={handleSelectGlobalSearchMemo}
          />
        </div>
      </FrankiePopover>
    </div>
  )
}
