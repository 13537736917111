import {
  CountryAlpha3CodeTypes,
  countryPhoneCodeList,
  getCountryNameByAlpha3Code,
} from 'entities/country'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { I18nKeys } from 'shared/i18n'

import { extractLongForm } from './entity-data.model'
import {
  AvailableDocumentType,
  DocumentLabel,
  Label,
  mapLabels,
} from './entity-label-data-model'
import {
  AddressStatusEnum,
  AddressTypeEnum,
  DocumentType,
  DocumentInformation,
  Individual,
} from './entity.model'
import { entityEn } from '../locale/entity.en'

export interface IDocsToBeDisplayedType extends DocumentInformation {
  label: ILabelAndSources[]
  extraData: ILabelAndSources[]
}

export interface ILabelAndSources {
  label: string
  value?: string | undefined
  sortPos?: number
  sources?: string[]
}

type GetPersonalInfoLabelDataProps = {
  individual?: Individual
  t: (key: I18nKeys<typeof entityEn>) => string
  singleAddress?: boolean
}

const getFullName = (individual: Individual) => {
  const { displayName, givenName, middleName, familyName } =
    individual.name ?? {}
  return (
    displayName ??
    `${givenName ?? ''} ${middleName ?? ''} ${familyName ?? ''}`.trim()
  )
}

export const getProfileInfoWithLabelDataF2R2 = ({
  t,
  individual,
  singleAddress = false,
}: GetPersonalInfoLabelDataProps) => {
  const fullName = getFullName(individual ?? {})

  const email = individual?.emailAddresses?.[0]?.email

  const phoneNumber = individual?.phoneNumbers?.[0]

  const countryCode = countryPhoneCodeList.find(
    i => i.code === phoneNumber?.country,
  )?.value

  const phone = `${countryCode ? `+${countryCode}` : ''}${
    phoneNumber?.number ?? ''
  }`

  const additionalAddress = singleAddress
    ? []
    : [
        {
          label: t('personalInfo.previousAddress'),
          value: extractLongForm(
            individual?.addresses?.find(
              address =>
                address.type === AddressTypeEnum.RESIDENTIAL &&
                address.status === AddressStatusEnum.PREVIOUS,
            ),
          ),
        },
        {
          label: t('personalInfo.mailingAddress'),
          value: extractLongForm(
            individual?.addresses?.find(
              address => address.type === AddressTypeEnum.POSTAL,
            ),
          ),
        },
        { label: t('personalInfo.phoneNumber'), value: phone },
        { label: t('personalInfo.email'), value: email },
      ]

  const profileInfo: ILabelAndSources[] = [
    {
      label: t(singleAddress ? 'personalInfo.fullName' : 'personalInfo.name'),
      value: fullName,
    },
    {
      label: t('personalInfo.dob'),
      value:
        individual?.dateOfBirth?.normalized &&
        formatDate(
          individual.dateOfBirth.normalized,
          DateFormatTypes.ShortDate,
        ),
    },
    {
      label: t('personalInfo.currentAddress'),
      value: extractLongForm(
        individual?.addresses?.find(
          address =>
            address.type === AddressTypeEnum.RESIDENTIAL &&
            address.status === AddressStatusEnum.CURRENT,
        ),
      ),
    },

    ...additionalAddress,
  ]

  return profileInfo
}

const DEFAULT_LABEL_TEMPLATE: DocumentLabel = {
  [DocumentType.DRIVERS_LICENSE]: [
    {
      label: 'labels.driverLicense.licenseNumber',
      value: 'document.primaryIdentifier',
    },
    {
      label: 'labels.driverLicense.cardNumber',
      value: 'document.secondaryIdentifier',
    },
    {
      label: 'labels.driverLicense.country',
      value: document => getCountryNameByAlpha3Code(document.country),
    },
    {
      label: 'labels.driverLicense.state',
      value: 'document.subdivision',
    },
  ],
  [DocumentType.PASSPORT]: [
    {
      label: 'labels.passport.passport',
      value: 'document.primaryIdentifier',
    },
    {
      label: 'labels.passport.country',
      value: document => getCountryNameByAlpha3Code(document.country),
    },
  ],
  [DocumentType.NATIONAL_HEALTH_ID]: [
    {
      label: 'labels.medicareCard.color',
      value: (document, t) =>
        t(`medicareCardColor.${document.subtype as 'G' | 'B' | 'Y'}`),
    },
    {
      label: 'labels.medicareCard.cardNumber',
      value: 'document.primaryIdentifier',
    },
    {
      label: 'labels.medicareCard.position',
      value: 'document.supplementaryData.reference',
    },
    {
      label: 'labels.medicareCard.expiry',
      value: document =>
        document.expiryDate?.normalized
          ? formatDate(
              document.expiryDate.normalized,
              document.subtype === 'G'
                ? DateFormatTypes.ShortDateNoDay
                : DateFormatTypes.ShortDate,
            )
          : '-',
    },
    {
      label: 'labels.medicareCard.displayName',
      value: 'document.supplementaryData.nameOnCardLine1',
    },
    {
      label: 'labels.medicareCard.country',
      value: document => getCountryNameByAlpha3Code(document.country),
    },
  ],
}
const COUNTRY_LABEL_MAP: Record<
  CountryAlpha3CodeTypes,
  DocumentLabel | undefined
> = {
  [CountryAlpha3CodeTypes.Australia]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.NEW_ZEALAND]: {
    [DocumentType.DRIVERS_LICENSE]: [
      {
        label: 'labels.driverLicense.licenseNumber',
        value: 'document.primaryIdentifier',
      },
      {
        label: 'labels.driverLicense.licenseVersion',
        value: 'document.secondaryIdentifier',
      },
      {
        label: 'labels.driverLicense.country',
        value: document => getCountryNameByAlpha3Code(document.country),
      },
    ],
    [DocumentType.PASSPORT]: [
      {
        label: 'labels.passport.passport',
        value: 'document.primaryIdentifier',
      },
      {
        label: 'labels.passport.expiry',
        value: document =>
          document.expiryDate?.normalized
            ? formatDate(
                document.expiryDate.normalized,
                DateFormatTypes.ShortDate,
              )
            : '-',
      },
      {
        label: 'labels.passport.country',
        value: document => getCountryNameByAlpha3Code(document.country),
      },
    ],
    [DocumentType.NATIONAL_HEALTH_ID]: [], // No data for New Zealand
  },
  [CountryAlpha3CodeTypes.UNITED_KINGDOM]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.INDIA]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.PHILIPPINES]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.CHINA]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.HONG_KONG]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.INDONESIA]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.MALAYSIA]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.SPAIN]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.SWEDEN]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.ITALY]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.BRAZIL]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.MEXICO]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.RUSSIA]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.TURKEY]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.SINGAPORE]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.SOUTH_AFRICA]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.ARGENTINA]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.JORDAN]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.KUWAIT]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.OMAN]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.SAUDI_ARABIA]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.EGYPT]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.ROMANIA]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.VIETNAM]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.CAMBODIA]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.THAILAND]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.UNITED_STATES_AMERICA]: DEFAULT_LABEL_TEMPLATE,
  [CountryAlpha3CodeTypes.DEFAULT]: DEFAULT_LABEL_TEMPLATE,
}

function getLabelsMap(
  country: CountryAlpha3CodeTypes,
  documentType: AvailableDocumentType,
) {
  return (mapFn: (labels: Label[]) => Label[]): Label[] => {
    const countryMap = COUNTRY_LABEL_MAP[country]
    if (!countryMap) {
      return mapFn(
        COUNTRY_LABEL_MAP[CountryAlpha3CodeTypes.DEFAULT]?.[documentType] ?? [],
      )
    }
    const labelMap = countryMap[documentType]

    return mapFn(labelMap)
  }
}

type GetDocumentWithLabelDataProps = GetPersonalInfoLabelDataProps & {
  documents?: DocumentInformation[]
}

export const getDocumentWithLabelDataF2R2 = ({
  documents,
  individual,
  t,
}: GetDocumentWithLabelDataProps) => {
  const profileInfo: ILabelAndSources[] = getProfileInfoWithLabelDataF2R2({
    individual,
    t,
    singleAddress: true,
  })

  const docsToBeDisplayed: IDocsToBeDisplayedType[] | undefined = documents
    ?.sort((a, b) => a.type.charCodeAt(0) - b.type.charCodeAt(0))
    ?.map(document => {
      const labels = getLabelsMap(
        document.country as CountryAlpha3CodeTypes,
        document.type as AvailableDocumentType,
      )(mapLabels(document, t, individual))

      let extraData: ILabelAndSources[] = []
      switch (document.type) {
        case DocumentType.DRIVERS_LICENSE:
        case DocumentType.PASSPORT: {
          extraData = [...profileInfo, ...(labels as ILabelAndSources[])]
          break
        }
        case DocumentType.NATIONAL_HEALTH_ID:
          extraData = [
            ...profileInfo,
            {
              label: t('labels.medicareCard.country'),
              value: getCountryNameByAlpha3Code(document.country),
            },
            {
              label: t('labels.medicareCard.displayName'),
              value: document.supplementaryData?.nameOnCardLine1 || '-',
            },
            {
              label: t('labels.medicareCard.cardNumber'),
              value: document.primaryIdentifier,
            },
            {
              label: t('labels.medicareCard.position'),
              value: document.supplementaryData?.reference || '-',
            },
            {
              label: t('labels.medicareCard.expiry'),
              value:
                document.expiryDate?.normalized &&
                formatDate(
                  document.expiryDate.normalized,
                  document.subtype === 'G'
                    ? DateFormatTypes.ShortDateNoDay
                    : DateFormatTypes.ShortDate,
                ),
            },
            {
              label: t('labels.medicareCard.color'),
              value: t(
                `medicareCardColor.${document.subtype as 'G' | 'B' | 'Y'}`,
              ),
            },
          ]
          break
        default:
          break
      }
      return {
        ...document,
        label: labels as ILabelAndSources[],
        extraData,
      }
    })
  return docsToBeDisplayed
}
