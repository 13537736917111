export const individualOcrCheckEn = {
  title: 'Biometrics check',
  noIssues: 'No issues found.',
  manualFail: 'Manually failed',
  manualPass: 'Manually passed',
  updateCheckStatus: 'Updating check status',
  resolve: 'Resolve',
  showFullReport: 'Show full report',
  idScore: 'ID Score',
  ocrScore: 'OCR Score',
  documentIssues: 'Document Issues',
  score: '{{score}}%',
  faceRecognition: 'Face recognition score',
  livenessDetection: 'Liveness detection',
  manualPassStatus: 'Manually passed',
  manualFailStatus: 'Manually failed',
  noImageUploaded: 'No image uploaded',
  modal: {
    compareImages: 'Compare images',
    selfie: 'Selfie',
    uploadedOn: 'Uploaded on {{date}} at {{time}} {{via}}',
    directCapture: 'via direct capture',
    by: 'by {{name}}',
  },
  checks: {
    documentComparison: 'Document comparison',
    livenessDetection: 'Liveness detection',
    document: {
      passport: 'Passport',
      driverLicense: "Driver's Licence",
    },
    livenessCheck: {
      livenessCheck: 'Liveness Check',
      digitalSpoofCheck: 'Digital Spoof Check',
      faceEvasionCheck: 'Face Evasion Check',
      closedEyesCheck: 'Closed Eyes Check',
      hatCheck: 'Hat Check',
    },
  },
  form: {
    title: 'Resolve biometric check status',
    subTitle: 'This will manually override the status for this check.',
    selectLabel: 'Override outcome to',
    commentLabel: 'Comment',
    cancel: 'Cancel',
    changeStatus: 'Change status',
    successMessage: 'Check status has been successfully updated.',
    errorMessage: 'Check status could not be updated at this time.',
  },
}
