import React, { useRef, useState, useEffect } from 'react'

import { FrankieTooltip } from 'frankify/src'

interface ITruncatedTextProps {
  text: string
  className?: string
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right'
  showTooltip?: boolean
  zIndex?: number
}

export function TruncatedText({
  text,
  className = '',
  tooltipPosition = 'bottom',
  showTooltip = true,
  zIndex,
}: ITruncatedTextProps) {
  const textRef = useRef<HTMLParagraphElement>(null)
  const [isTextTruncated, setIsTextTruncated] = useState(false)

  useEffect(() => {
    const checkTruncation = () => {
      if (textRef.current) {
        setIsTextTruncated(
          textRef.current.scrollWidth > textRef.current.clientWidth,
        )
      }
    }

    checkTruncation()
    window.addEventListener('resize', checkTruncation)
    return () => window.removeEventListener('resize', checkTruncation)
  }, [text])

  return (
    <FrankieTooltip
      hidden={!isTextTruncated || !showTooltip}
      body={isTextTruncated && showTooltip ? text : undefined}
      position={tooltipPosition}
      zIndex={zIndex}
    >
      <p ref={textRef} className={`${className} truncate`}>
        {text}
      </p>
    </FrankieTooltip>
  )
}
