import { useMemo } from 'react'

import { useSearchParams } from 'react-router-dom'

import type { ServiceProfile } from 'entities/entity'

import { WORKFLOW_EXECUTION_ID_QUERY_PARAM } from './workflow.model'

/**
 * A custom hook that tries to use `useSearchParams` from react-router,
 * but if it's not in a Router context, it returns a read-only fallback.
 *
 * Fallback behavior:
 * - Reads the current search string from `window.location.search` (if available).
 * - Provides a no-op setter function, so changing search params does nothing in fallback mode.
 */
const useRouterSafeSearchParams = () => {
  try {
    return useSearchParams()
  } catch (error) {
    console.warn(
      'useSearchParams must be used within Router context. Falling back to default value.',
    )

    const currentSearch =
      typeof window !== 'undefined' ? window.location.search : ''

    const noop = () => {
      console.warn('The setter is a no-op in fallback mode.')
    }

    return [new URLSearchParams(currentSearch), noop] as const
  }
}

/**
 * Custom hook that filters workflow data based on the execution ID from URL search parameters
 *
 * @param data - Optional ServiceProfile object containing workflow summaries
 * @returns A filtered version of the input data with filtered workflow summaries
 *
 * @remarks
 * - If no execution ID is provided in URL params, returns only the first workflow summary
 * - If execution ID is provided, returns all workflow summaries matching that ID
 * - If no data or workflow summaries exist, returns the data with an empty workflow summaries array
 */
export const useFilterWorkflow = (data?: ServiceProfile) => {
  const [searchParams] = useRouterSafeSearchParams()
  const filteredWorkflow = useMemo(() => {
    const getFilteredWorkflowSummaries = (executionId: string | null) => {
      if (!data?.workflowSummaries) return []

      return executionId
        ? data.workflowSummaries.filter(
            summary => summary.workflowExecutionId === executionId,
          )
        : data.workflowSummaries
    }

    return {
      ...data,
      workflowSummaries: getFilteredWorkflowSummaries(
        searchParams.get(WORKFLOW_EXECUTION_ID_QUERY_PARAM),
      ),
    }
  }, [data, searchParams])

  return filteredWorkflow
}
