import React, { useEffect, useRef } from 'react'

import { Control, UseFormRegister, UseFormWatch } from 'react-hook-form'

import { FrankieTextField } from 'frankify/src'

import { countryList, stateList } from 'entities/country'

import { SelectFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'

import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../../locale/applicant-supporting-documents.en'
import {
  ITrustDeedForm,
  TrustFormTypes,
} from '../../../model/applicant-supporting-trust-deed.model'
import { trustAnalyserEditFormQa } from '../../../qa/applicant-support-documents.qa'

type TrustAnalyserAddressFormDetailProps = {
  watch: UseFormWatch<ITrustDeedForm>
  control: Control<ITrustDeedForm, unknown>
  register: UseFormRegister<ITrustDeedForm>
  formKey: TrustFormTypes
  index: number
}

export function TrustAnalyserAddressFormDetail({
  register,
  formKey,
  index,
  watch,
  control,
}: TrustAnalyserAddressFormDetailProps) {
  const [addressKey, setAddressKey] = React.useState<
    'locality' | 'neighborhood'
  >('locality')

  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const country =
    formKey !== TrustFormTypes.GeneralBeneficiary &&
    watch(`${formKey}.${index}.detail.address.country`)

  const countryOptions = React.useMemo(
    () =>
      countryList.map(country => ({
        label: country.name,
        value: country.alpha3code,
      })),
    [],
  )

  const getStates = React.useMemo(() => {
    // need this to be empty array if country not picked
    // it will be an error from BE if there is no country and user pick a state
    if (!country) {
      return []
    }

    if (Object.hasOwn(stateList, country)) {
      return stateList[country]
    }

    return []
  }, [country])

  const hasChecked = useRef<boolean>(false)

  useEffect(() => {
    if (formKey !== TrustFormTypes.GeneralBeneficiary && !hasChecked.current) {
      const locality = watch(`${formKey}.${index}.detail.address.locality`)
      const entityId = watch(`${formKey}.${index}.entityId`)

      // if locality and entityId is present, means that we are updating the current address
      const updateAddress = locality && entityId
      // if there is no entity id means that we are adding a new address
      const newAddress = !entityId

      // locality will always be the first to check for the payload, then we will go with neighborhood
      setAddressKey(updateAddress || newAddress ? 'locality' : 'neighborhood')
      hasChecked.current = true
    }
  }, [formKey, index, watch])

  if (formKey === TrustFormTypes.GeneralBeneficiary) return null

  return (
    <div className="grid grid-cols-12 gap-4">
      <SelectFormField
        {...register(`${formKey}.${index}.detail.address.country`)}
        control={control}
        label={t('trustFormLabel.address.country')}
        options={countryOptions}
        testId={{
          input: trustAnalyserEditFormQa.country(formKey, index + 1),
        }}
        autocomplete
        className="col-span-6"
        data-hj-suppress
      />
      <FrankieTextField
        {...register(`${formKey}.${index}.detail.address.buildingName`)}
        label={t('trustFormLabel.address.propertyName')}
        testId={{
          input: trustAnalyserEditFormQa.propertyName(formKey, index + 1),
        }}
        className="col-span-6"
        data-hj-suppress
      />
      <FrankieTextField
        {...register(`${formKey}.${index}.detail.address.unitNumber`)}
        label={t('trustFormLabel.address.unitNumber')}
        testId={{
          input: trustAnalyserEditFormQa.unitNumber(formKey, index + 1),
        }}
        className="col-span-3"
        data-hj-suppress
      />
      <FrankieTextField
        {...register(`${formKey}.${index}.detail.address.streetNumber`)}
        label={t('trustFormLabel.address.streetNumber')}
        testId={{
          input: trustAnalyserEditFormQa.streetNumber(formKey, index + 1),
        }}
        className="col-span-3"
        data-hj-suppress
      />
      <FrankieTextField
        {...register(`${formKey}.${index}.detail.address.streetName`)}
        label={t('trustFormLabel.address.streetName')}
        testId={{
          input: trustAnalyserEditFormQa.streetName(formKey, index + 1),
        }}
        className="col-span-6"
        data-hj-suppress
      />
      <FrankieTextField
        {...register(`${formKey}.${index}.detail.address.${addressKey}`)}
        label={t('trustFormLabel.address.suburb')}
        testId={{
          input: trustAnalyserEditFormQa.suburb(formKey, index + 1),
        }}
        className="col-span-5"
        data-hj-suppress
      />
      <SelectFormField
        {...register(`${formKey}.${index}.detail.address.subdivision`)}
        control={control}
        label={t('trustFormLabel.address.state')}
        options={getStates}
        testId={{
          input: trustAnalyserEditFormQa.state(formKey, index + 1),
        }}
        autocomplete
        className="col-span-3"
        data-hj-suppress
      />
      <FrankieTextField
        {...register(`${formKey}.${index}.detail.address.postalCode`)}
        label={t('trustFormLabel.address.postalCode')}
        testId={{
          input: trustAnalyserEditFormQa.postalCode(formKey, index + 1),
        }}
        className="col-span-4"
        data-hj-suppress
      />
    </div>
  )
}
