import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

export interface IGlobalSearchState {
  searchRedirect: boolean
  setSearchRedirect: (newState: boolean) => void
}

export const useGlobalSearchStore = create<IGlobalSearchState>()(
  persist(
    set => ({
      searchRedirect: false,
      setSearchRedirect: (payload: boolean) => set({ searchRedirect: payload }),
    }),
    {
      name: 'global-search-storage',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
)
