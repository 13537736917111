import { ApplicantAddressResponse } from './applicant-response.model'

export enum AdditionalAddressCategoryTypes {
  RESIDENTIAL = 'RESIDENTIAL', // Some how this is getting used if RESIDENTIAL1 is not found as current address
}

export enum AddressCategoryTypes {
  current = 'RESIDENTIAL1',
  previous = 'RESIDENTIAL2',
  mailing = 'POSTAL',
}

export enum AddressCategoryReverseTypes {
  RESIDENTIAL1 = 'current',
  RESIDENTIAL2 = 'previous',
  POSTAL = 'mailing',
}

export const getLongAddressName = (
  addresses: ApplicantAddressResponse[],
  addressType: AddressCategoryTypes,
) =>
  addresses.find(address => address.addressType === addressType)?.longForm || ''

export const parseStreetName = (
  streetName: string | null,
  streetType?: string | null,
): { streetName: string; streetType?: string } => {
  if (!streetName) {
    return { streetName: '' }
  }

  if (streetType) {
    return { streetName, streetType }
  }

  const parts = streetName.split(' ')
  const derivedStreetType = parts.length > 1 ? parts.pop() : undefined

  return {
    streetName: parts.join(' ') || streetName,
    ...(derivedStreetType ? { streetType: derivedStreetType } : {}),
  }
}

export function formatAddress(
  address?: ApplicantAddressResponse | null,
): string {
  const {
    buildingName,
    unitNumber,
    streetNumber,
    streetName = '',
    streetType,
    suburb, // v1
    town, // v1
    neighborhood,
    locality,
    state, // v1
    subdivision,
    postalCode,
  } = address || {}
  const { streetName: sn, streetType: st } = parseStreetName(
    streetName,
    streetType,
  )

  const streetDetails = [streetNumber, sn, st].filter(Boolean).join(' ').trim()

  const parts = [
    buildingName, // property name
    unitNumber,
    streetDetails,
    locality || neighborhood || suburb || town, // suburb
    subdivision || state, // state
    postalCode,
  ].filter(Boolean)

  return parts.join(', ')
}
