import React from 'react'

import { getStepperStatus } from 'features/applicant-workflow-events/ui/entity-verification-helper/entity-verification-helper'

import { useEntityDataQuery, useGetWorkflowEventsData } from 'entities/entity'
import {
  ProcessResultManualStatusEnumKYC,
  ProcessResultObject,
  SupplementaryDataBase,
} from 'entities/entity/model/entity.model'
import { useFilterWorkflow } from 'entities/workflow'

import { IdvDocument, makeIdvDocument } from '../../model/idv-documents.model'
import { passVariants } from '../../model/individual-idv-check.model'
import { idvCheckFullReport } from '../../model/mock-idv-data.model'

type Args = {
  entityId: string
  idx: number
}

export type DocumentInfo = IdvDocument
export type DocumentCheckReport = (typeof idvCheckFullReport)[number]

export const useIndividualIdvCheckState = ({ entityId, idx }: Args) => {
  const { data } = useGetWorkflowEventsData({ entityId })
  const workflowEvent = useFilterWorkflow(data)
  const { data: entity } = useEntityDataQuery(entityId, 'base64')

  const document = React.useMemo(
    () => makeIdvDocument(entity, workflowEvent),
    [entity, workflowEvent],
  )

  const workflow = workflowEvent.workflowSummaries.at(0)

  const hadIdvChecks = workflow?.steps?.order?.includes('IDV')

  const step = workflow?.workflowResultData?.workflowStepResults?.filter(
    i => i.stepName === 'IDV',
  )[idx]
  type ProcessResultMap = Record<
    SupplementaryDataBase['type'],
    ProcessResultObject | undefined
  >
  const processResultMap = (
    step?.processResults || []
  ).reduce<ProcessResultMap>((acc, curr) => {
    if (curr.supplementaryData?.type) {
      acc[curr.supplementaryData.type] = curr
    }
    return acc
  }, {} as ProcessResultMap)

  const { IDV_DOCUMENT, IDV_OCR, IDV_OCR_COMPARISON } = processResultMap
  const processResultIds = [
    IDV_DOCUMENT?.processResultId,
    IDV_OCR?.processResultId,
    IDV_OCR_COMPARISON?.processResultId,
  ].filter(Boolean) as string[]

  const validProcessResults = [
    IDV_DOCUMENT,
    IDV_OCR,
    IDV_OCR_COMPARISON,
  ].filter(Boolean) as ProcessResultObject[]
  const isManualPass = validProcessResults.every(
    processResult =>
      processResult.manualStatus === ProcessResultManualStatusEnumKYC.CLEAR,
  )
  const isManualFail = validProcessResults.every(
    processResult =>
      processResult.manualStatus === ProcessResultManualStatusEnumKYC.REJECTED,
  )

  const statuses =
    step?.processResults?.map(processResult => processResult.result) || []
  const manualStatuses =
    step?.processResults?.map(processResult => processResult.manualStatus) || []
  const status = getStepperStatus(statuses, manualStatuses)

  const requireResolve = isManualPass || !passVariants.includes(status)

  return {
    hadIdvChecks,
    status,
    document,
    isManualPass,
    isManualFail,
    requireResolve,
    processResultIds,
  }
}
