import React, { ReactNode } from 'react'

import { NavLink } from 'react-router-dom'

import { FrankieIcon, FrankieIconName, FrankieTooltip } from 'frankify/src'

import { useSetOriginatedPath } from 'entities/routing'

type Props = {
  children: ReactNode
  icon: FrankieIconName
  activeIcon?: FrankieIconName
  to: string
  testId: string
  open: boolean
  rel?: string
  className?: string
  target?: string
  tooltip?: string | ReactNode
  precondition?: boolean
  isAuxiliaryActive?: boolean
}

export function PortalNavLink({
  icon,
  children,
  to,
  testId,
  open,
  rel,
  target,
  tooltip,
  className,
  precondition = true,
  isAuxiliaryActive = false,
  activeIcon,
}: Props) {
  const { setOriginatedPath } = useSetOriginatedPath()

  return (
    <FrankieTooltip hidden={open} position="right" title={tooltip ?? children}>
      <NavLink
        rel={rel}
        target={target}
        className={({ isActive }) =>
          `flex h-[36px] gap-3 p-2 items-center whitespace-nowrap text-sm font-medium rounded-sm ${
            precondition && (isActive || isAuxiliaryActive)
              ? 'text-primary-800'
              : 'text-tertiary-grey-600 hover:text-tertiary-grey-800 hover:bg-tertiary-grey-100 ' +
                'active:text-tertiary-grey-800 focus-visible:text-tertiary-grey-800 active:bg-primary-50 focus-visible:bg-primary-50 ' +
                'active:outline focus-visible:outline outline-tertiary-grey-100 focus-visible:outline-3 active:outline-3'
          } ${className ?? ''}`
        }
        to={to}
        data-qa={testId}
        onClick={() => setOriginatedPath(to)}
      >
        {({ isActive }) => (
          <>
            <FrankieIcon
              name={
                precondition && (isActive || isAuxiliaryActive) && activeIcon
                  ? activeIcon
                  : icon
              }
              size="sm"
            />
            {open && children}
          </>
        )}
      </NavLink>
    </FrankieTooltip>
  )
}
