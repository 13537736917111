import React, { Fragment, useEffect } from 'react'

import cx from 'classnames'

import { FrankieIcon, FrankieTooltip } from 'frankify/src'

import { FrankieUtilityImg } from 'shared/frankie-image'
import { useI18n } from 'shared/i18n'
import { GalleryItem } from 'shared/image-gallery'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { INDIVIDUAL_IDV_CHECK_KEY } from '../../individual-idv-check.key'
import { individualIdvCheckEn } from '../../locale/individual-idv-check.en'
import { DocumentInfo } from '../../state/individual-idv-check/individual-idv-check.state'

function OcrValuesTable({
  comparisonValues,
}: {
  comparisonValues: DocumentInfo['comparisonValues']
}) {
  const t = useI18n([INDIVIDUAL_IDV_CHECK_KEY], { keys: individualIdvCheckEn })
  return (
    <div className="grid grid-cols-1 gap-x-4 max-h-[462px] min-w-[400px] grow overflow-y-auto pb-20">
      <h2 className="text-tertiary-grey-800 font-semibold text-md leading-5 p-4 bg-tertiary-grey-50 rounded-t-sm">
        {t('modal.ocrOutput')}
      </h2>
      {comparisonValues.map((data, idx) => {
        if (!data.ocrValue) {
          return null
        }

        return (
          <div
            key={data.label}
            className={cx(
              'px-4 py-1.5',
              data.match ? 'bg-tertiary-grey-50' : 'bg-tertiary-red-100',
            )}
          >
            <p
              className={`${
                !data.match ? 'text-danger' : 'text-tertiary-grey-800'
              } font-medium text-sm leading-5`}
            >
              {data.label}
            </p>
            <p
              className={cx(
                'max-w-[155px]',
                data.match ? 'text-tertiary-grey-700' : 'text-danger',
              )}
            >
              {data.ocrValue || '-'}
            </p>
          </div>
        )
      })}
    </div>
  )
}

function ComparisonTable({
  comparisonValues,
}: {
  comparisonValues: DocumentInfo['comparisonValues']
}) {
  const t = useI18n([INDIVIDUAL_IDV_CHECK_KEY], { keys: individualIdvCheckEn })
  return (
    <div className="grid grid-cols-2 gap-x-4 max-h-[462px] min-w-[400px] grow overflow-y-auto pb-20">
      <div className="flex items-center gap-1 text-tertiary-grey-800 font-semibold text-md leading-5 p-4 bg-tertiary-grey-50 rounded-t-sm">
        <span>{t('modal.ocrOutput')}</span>
        <FrankieTooltip
          position="top"
          zIndex={1000}
          className="max-w-none w-[300px] bg-tertiary-grey-100"
          textClassName="text-xs text-tertiary-grey-400"
          title={t('modal.ocrOutputTooltip')}
        >
          <div>
            <FrankieIcon
              name="mdiInformation"
              size="2xs"
              className="text-tertiary-grey-500"
            />
          </div>
        </FrankieTooltip>
      </div>
      <p className="text-tertiary-grey-800 font-semibold text-md leading-5 p-4 bg-tertiary-grey-50 rounded-t-sm">
        {t('modal.entityData')}
      </p>
      {comparisonValues.map((data, idx) => {
        if (!data.ocrValue && !data.entityValue) {
          return null
        }

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={data.label}>
            <div
              className={cx(
                'px-4 py-1.5',
                data.match ? 'bg-tertiary-grey-50' : 'bg-tertiary-red-100',
              )}
            >
              <p
                className={`${
                  !data.match ? 'text-danger' : 'text-tertiary-grey-800'
                } font-medium text-sm leading-5`}
              >
                {data.label}
              </p>
              <p
                className={cx(
                  'max-w-[155px]',
                  data.match ? 'text-tertiary-grey-700' : 'text-danger',
                )}
              >
                {data.ocrValue || '-'}
              </p>
            </div>
            <div
              className={cx(
                'px-4 py-1.5',
                data.match ? 'bg-tertiary-grey-50' : 'bg-tertiary-red-100',
              )}
            >
              <p
                className={`${
                  !data.match ? 'text-danger' : 'text-tertiary-grey-800'
                } font-medium text-sm leading-5`}
              >
                {data.label}
              </p>
              <p
                className={cx(
                  'max-w-[155px]',
                  data.match ? 'text-tertiary-grey-700' : 'text-danger',
                )}
              >
                {data.entityValue || '-'}
              </p>
            </div>
          </Fragment>
        )
      })}
    </div>
  )
}

type Props = {
  documentInfo: DocumentInfo
  defaultIdx?: number
}

export function IndividualIdvModal({ documentInfo, defaultIdx }: Props) {
  const t = useI18n([INDIVIDUAL_IDV_CHECK_KEY], { keys: individualIdvCheckEn })
  const { title, comparisonValues, flags } = documentInfo

  useEffect(() => {
    trackingManager.track(TrackingEventsTypes.WorkflowEventsDocumentCompareShow)
  }, [])

  return (
    <div className="max-h-[calc(100vh-48px)] overflow-x-hidden overflow-y-hidden">
      <div className="font-bold text-xl leading-[30px] text-tertiary-grey-800 mb-4">
        {flags.ocrComparison
          ? t('modal.titleComparison', {
              documentType: t(
                `documentType.${
                  title as keyof typeof individualIdvCheckEn.documentType
                }`,
              ),
            })
          : t(
              `documentType.${
                title as keyof typeof individualIdvCheckEn.documentType
              }`,
            )}
      </div>

      <div className="flex gap-6 flex-wrap">
        <div className="flex flex-col gap-3">
          <FrankieUtilityImg
            defaultIdx={defaultIdx}
            gallery={
              documentInfo.attachments?.map(
                a =>
                  ({
                    url: a.url,
                    mimeType: a.mimeType,
                    id: a.id,
                    side: a.side,
                    type: a.type,
                    createdAt: a.createdAt,
                  } as GalleryItem),
              ) ?? []
            }
            trackingEvents={{
              gallery: [
                TrackingEventsTypes.WorkflowEventsDocumentCompareFront,
                TrackingEventsTypes.WorkflowEventsDocumentCompareBack,
              ],
              rotate: TrackingEventsTypes.WorkflowEventsDocumentCompareRotate,
              zoom: TrackingEventsTypes.WorkflowEventsDocumentCompareZoom,
            }}
          />

          <div className="text-sm leading-5 text-tertiary-grey-500">
            <p>{t('modal.uploadedOn', documentInfo.uploadedOn)}</p>
            <p>{t('modal.updatedOn', documentInfo.updatedOn)}</p>
          </div>
        </div>

        {flags.ocrComparison ? (
          <ComparisonTable comparisonValues={comparisonValues} />
        ) : (
          <OcrValuesTable comparisonValues={comparisonValues} />
        )}
      </div>
    </div>
  )
}
