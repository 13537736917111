import React, { useEffect } from 'react'

import { Trans } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { FrankieButton, FrankieIcon } from 'frankify/src'

import { externalLinks } from 'shared/external-links'
import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import {
  GLOBAL_SEARCH_KEY,
  globalSearchEn,
} from '../../locale/global-search.en'
import {
  getGlobalSearch,
  searchByI18nMap,
  SearchByTypes,
} from '../../model/global-search.model'
import { globalSearchQa } from '../../qa/global-search.qa'

function RenderNoResultsNameMessageV1({
  searchBy,
}: {
  searchBy: SearchByTypes
}) {
  const t = useI18n([GLOBAL_SEARCH_KEY], { keys: globalSearchEn })

  if (searchBy === SearchByTypes.IndividualName) {
    return (
      <Trans
        i18nKey="global-search:noResultIndividualName"
        tOptions={{
          fullName: t('fullName'),
          lastName: t('lastName'),
        }}
        components={[<strong />]}
      />
    )
  }
  return (
    <Trans
      i18nKey="global-search:noResultOrganisationName"
      tOptions={{
        legalName: t('legalName'),
        abnAcn: t('abnAcn'),
      }}
      components={{
        legalName: <strong />,
        abnAcn: <strong />,
      }}
    />
  )
}

type Props = {
  /** currently isSearchProfile is only used for Frankie2 */
  isSearchProfile?: boolean
}
export function GlobalSearchNoResults({ isSearchProfile }: Props) {
  const t = useI18n([GLOBAL_SEARCH_KEY], { keys: globalSearchEn })
  const [searchParams] = useSearchParams()
  const { searchBy, value } = getGlobalSearch(searchParams)

  const handleGiveFeedback = () => {
    trackingManager.track(TrackingEventsTypes.GlobalSearchFeedback)
  }

  useEffect(() => {
    trackingManager.track(TrackingEventsTypes.GlobalSearchNoResults)
  }, [])

  const isIndividualName = searchBy === SearchByTypes.IndividualName
  const isOrganisationName = searchBy === SearchByTypes.OrganisationName
  const showNoResultsNameV1 =
    (isIndividualName || isOrganisationName) && !isSearchProfile

  return (
    <div className="flex pt-10 align-center justify-center flex-col w-full h-full bg-mono-white">
      <div>
        <FrankieIcon
          size="75px"
          name="mdiMagnify"
          className="text-primary-300 flex justify-center"
        />
        <div className="flex flex-col items-center mt-3">
          <span className="text-lg text-tertiary-grey-800 font-bold inline-block leading-6">
            {t('noResultsTitle')}
          </span>
          <span className="text-sm text-tertiary-grey-800 font-normal max-w-[600px] text-center mt-3 leading-6">
            {t('noResultsSubtitle', {
              searchBy: searchBy
                ? // @ts-ignore
                  t(searchByI18nMap(isSearchProfile)[searchBy]).toLowerCase()
                : '',
              value,
            })}
          </span>
          <span className="text-sm text-tertiary-grey-800 font-normal max-w-[600px] text-center leading-6">
            {showNoResultsNameV1 ? (
              <RenderNoResultsNameMessageV1 searchBy={searchBy} />
            ) : (
              t('noResultsInfo')
            )}
          </span>
          <span className="text-sm text-tertiary-grey-800 font-normal mt-3 text-center">
            <Trans i18nKey="noResultsDocs" docs={t('noResultsDocsLearnMore')}>
              <a
                rel="noreferrer"
                target="_blank"
                href={externalLinks.globalSearchDocs}
                className="text-primary-800"
                data-qa={globalSearchQa.cta.docs}
              >
                {/* @ts-ignore */}
                {{ docs: t('noResultsDocsLearnMore') }}
              </a>
              &nbsp;about how our search works.
            </Trans>
          </span>
          <FrankieButton
            noStyles
            className="inline mt-3 text-primary-800"
            testId={{ button: globalSearchQa.cta.feedback }}
            onClick={handleGiveFeedback}
          >
            {t('badResultsFeedback')}
          </FrankieButton>
        </div>
      </div>
    </div>
  )
}
