import React from 'react'

import { useInView } from 'react-intersection-observer'

import { FrankieBadge, FrankieButton, FrankieLoader } from 'frankify/src'

import { MutateIndividualResultsType } from 'features/individual-idv-check/model/individual-results.model'
import {
  IndividualResultsTypes,
  useUpdateIndividualResultsMutation,
} from 'features/individual-idv-check/mutation/update-results.mutation'

import { CommentHOC, CommentsTypes, CommentToolTip } from 'entities/comments'
import { ProcessResultManualStatusEnumKYC } from 'entities/entity'
import { PermissionTypes } from 'entities/role'
import { useHasPermission } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, useTrackingShowEvents } from 'shared/tracking'

import {
  ManualStatusChangeForm,
  UpdateStatusType,
} from './individual-idv-form/individual-idv-form'
import { INDIVIDUAL_IDV_CHECK_KEY } from '../../individual-idv-check.key'
import { individualIdvCheckEn } from '../../locale/individual-idv-check.en'
import {
  borderStyleConfig,
  IconConfig,
} from '../../model/individual-idv-check.model'
import { useIndividualIdvCheckState } from '../../state/individual-idv-check/individual-idv-check.state'
import { IDVDocumentRow } from '../individual-idv-check-document-row/individual-idv-check-document-row'

type Props = {
  entityId: string
  idx: number
}

export function IndividualIdvCheck({ entityId, idx }: Props) {
  const {
    hadIdvChecks,
    status,
    document,
    isManualFail,
    isManualPass,
    requireResolve,
    processResultIds,
  } = useIndividualIdvCheckState({
    entityId,
    idx,
  })

  const { canOverrideStatus } = useHasPermission({
    canOverrideStatus: PermissionTypes.ApplicantNew,
  })

  const [createOverlay, closeOverlay] = useOverlay()

  const t = useI18n([INDIVIDUAL_IDV_CHECK_KEY], { keys: individualIdvCheckEn })

  const { mutateAsync, isLoading } = useUpdateIndividualResultsMutation({
    entityId,
    type: IndividualResultsTypes.IDV,
  })

  const handleStatusChange = async (data: UpdateStatusType) => {
    const payload: MutateIndividualResultsType = {
      processResults: processResultIds,
      manualStatus: data.manualStatus,
      comment: {
        text: data.comment,
      },
    }
    closeOverlay()
    await mutateAsync(payload)
  }

  function handleClick() {
    createOverlay(
      <ManualStatusChangeForm
        options={[
          {
            label: t('manualPassStatus'),
            value: ProcessResultManualStatusEnumKYC.CLEAR,
          },
          {
            label: t('manualFailStatus'),
            value: ProcessResultManualStatusEnumKYC.REJECTED,
          },
        ]}
        closeOverlay={closeOverlay}
        updateWorkflowStatus={handleStatusChange}
      />,
      {
        closeButtonClassName: '!top-6 !right-6',
        className: '!p-6',
      },
    )
  }
  const { ref, inView } = useInView()

  useTrackingShowEvents({
    data: hadIdvChecks && inView,
    eventsType: TrackingEventsTypes.WorkflowEventsDocumentShow,
  })

  if (!hadIdvChecks || !document || !document.flags.overallIdv) {
    return null
  }

  return (
    <>
      {isLoading && (
        <FrankieLoader
          size="md"
          fullscreen
          className="!z-[100]"
          loaderClassName="gap-3"
          label={t('updateCheckStatus')}
          loading={isLoading}
        />
      )}
      <CommentHOC
        entityId={entityId}
        commentType={CommentsTypes.IDV_DOCUMENT}
        className="!h-min"
        processId={processResultIds[0]}
      >
        <div
          id="IDV_DOCUMENT"
          ref={ref}
          className={`border rounded-1.5 flex flex-col gap-4 pt-5 px-6 pb-6 ${borderStyleConfig(
            status,
          )}`}
        >
          <div className="text-xl text-tertiary-grey-800 gap-3 font-bold flex items-center">
            <IconConfig type={status} />
            {t('title')}
            {isManualFail && (
              <FrankieBadge theme="red" text={t('manualFail')} />
            )}
            {isManualPass && (
              <FrankieBadge theme="green" text={t('manualPass')} />
            )}
            {canOverrideStatus && requireResolve && (
              <FrankieButton
                noStyles
                className="ml-auto text-sm text-primary-800 font-semibold"
                onClick={() => handleClick()}
              >
                {t('resolve')}
              </FrankieButton>
            )}
            <CommentToolTip
              className="!z-50 mt-0.5"
              commentType={CommentsTypes.IDV_DOCUMENT}
              entityId={entityId}
              processId={processResultIds[0]}
            />
          </div>
          <IDVDocumentRow documentInfo={document} />
        </div>
      </CommentHOC>
    </>
  )
}
