import React, { useRef } from 'react'

import cx from 'classnames'

import { FrankieButton, FrankieLoader } from 'frankify/src'

import { useFileHandler } from 'entities/document/model/use-file-handler.model'
import { useFileValidation } from 'entities/document/model/use-file-validation.model'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { PdfPreview } from 'shared/pdf-preview/ui/pdf-preview'

import { DocumentUploadPreview } from './document-upload-preview'
import { DOCUMENT_KEY, documentEn } from '../../locale/document.en'
import {
  defaultSupportedFileTypes,
  IDocumentUploadProps,
} from '../../model/document.model'
import { useGetDocumentScan } from '../../mutation/document-scans'
import { useUploadDocument } from '../../mutation/upload-document.mutation'

const FILENAME_MAX_LENGTH = 80
const DEFAULT_FILE_SIZE_MB = 25

export type UploadedDocumentData = {
  fileUploadUuid: string
  mimeType: string
  scanCreated: string
  scanName: string
}

/**
 * Document Upload
 * @description Supports only pdf and images upload
 */
export function DocumentUpload({
  className = '',
  uploadedOn,
  supportedFileTypes = defaultSupportedFileTypes,
  fileSize = DEFAULT_FILE_SIZE_MB,
  onChange,
  name,
  error = false,
  documentId,
  fetchNewScan = false,
}: IDocumentUploadProps) {
  const t = useI18n([DOCUMENT_KEY], { keys: documentEn })
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [createOverlay] = useOverlay()
  const validateFile = useFileValidation({
    maxFileSize: fileSize,
    maxFilenameLength: FILENAME_MAX_LENGTH,
    supportedFileTypes: defaultSupportedFileTypes,
  })

  const { data: scans } = useGetDocumentScan({ documentId, fetchNewScan })
  const { fileUrl, fileName, isFileImage, handleFileChange } = useFileHandler(
    scans ?? [],
    0,
    onChange,
  )

  const { mutate, isLoading } = useUploadDocument((data, variable) => {
    handleFileChange(variable.file, data.id)
  })

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) return
    if (validateFile(file)) {
      mutate({ file })
    }
  }

  const handlePreviewClick = () => {
    if (!fileUrl) return
    createOverlay(
      <DocumentUploadPreview
        name={name}
        fileUrl={fileUrl}
        fileName={fileName}
        isFileImage={isFileImage}
        scans={scans || []}
        uploadedOn={uploadedOn}
      />,
    )
  }

  return (
    <section
      className={cx(
        className,
        'relative w-full h-full min-w-[200px] min-h-[150px] border-2 rounded-sm',
        {
          'border-solid border-neutral-40': fileUrl,
          'border-dashed border-tertiary-grey-200': !fileUrl,
        },
        {
          'border-tertiary-red-700 border-solid': error,
          'hover:border-primary-500 hover:border-solid': !error,
        },
      )}
    >
      <FrankieLoader
        loading={isLoading}
        label={fileUrl ? t('replacing') : t('uploading')}
      >
        <input
          type="file"
          multiple={false}
          ref={fileInputRef}
          accept={supportedFileTypes.join(',')}
          className="absolute inset-0 opacity-0 z-[2] cursor-pointer"
          onChange={handleFileUpload}
          onClick={e => {
            e.currentTarget.value = '' // to reset the file input so it can same file again
          }}
        />

        {fileUrl ? (
          <div className="group/uploaded flex items-center justify-center absolute inset-0 z-[3] bg-neutral-40 p-2">
            {isFileImage ? (
              <img
                src={fileUrl}
                className="max-h-[100%] object-contain"
                data-hj-suppress
                alt="uploaded-file"
              />
            ) : (
              <div
                data-hj-suppress
                title="uploaded-file"
                className="overflow-hidden border border-tertiary-grey-300 rounded-sm"
              >
                <PdfPreview
                  url={fileUrl}
                  pageProps={{
                    renderAnnotationLayer: false,
                    renderTextLayer: false,
                    pageNumber: 1,
                    height: 171,
                  }}
                />
              </div>
            )}

            <div
              className="absolute inset-0 p-2 flex flex-col justify-center gap-4 items-center bg-neutral-90 bg-opacity-90
            opacity-0 group-hover/uploaded:opacity-100
            transition-opacity duration-300 ease-in-out transform"
            >
              <FrankieButton
                intent="primary"
                className="!w-3/5"
                size="sm"
                endIcon={{
                  name: 'mdiArrowExpand',
                  size: 'xs',
                  className: 'ps-2',
                }}
                onClick={handlePreviewClick}
              >
                {t('view')}
              </FrankieButton>
              <FrankieButton
                intent="secondary"
                onClick={() => fileInputRef.current?.click()}
                size="sm"
                className="!bg-primary-50 !w-3/5"
                endIcon={{ name: 'mdiClose', size: 'xs', className: 'ps-2' }}
              >
                {t('replace')}
              </FrankieButton>
            </div>
          </div>
        ) : (
          <div className="absolute inset-0 z-[1] p-4 flex items-center justify-center">
            <p className="text-center text-tertiary-grey-800 text-sm font-medium">
              {t('placeholder')}
            </p>
          </div>
        )}
      </FrankieLoader>
    </section>
  )
}
