import React from 'react'

import { useSearchParams } from 'react-router-dom'

import { Banner, FrankieButton } from 'frankify/src'

import { useDownloadAuditReportQuery } from 'features/individual-audit-report-f2/state/applicant-download-audit-report'

import { PermissionTypes } from 'entities/role'
import { useHasPermission } from 'entities/session'

import { useI18n } from 'shared/i18n'

import { INDIVIDUAL_AUDIT_REPORT_F2_KEY } from '../../individual-audit-report-f2.key'
import { individualAuditReportF2En } from '../../locale/individual-audit-report-f2.en'
import { headerF2Qa } from '../../qa/individual-audit-report-f2.qa'

type Props = {
  entityId: string
}

export function IndividualReportHeader({ entityId }: Props) {
  const t = useI18n([INDIVIDUAL_AUDIT_REPORT_F2_KEY], {
    keys: individualAuditReportF2En,
  })

  const [searchParams] = useSearchParams()

  const mock = searchParams.get('mock') === 'true'

  const { downloadReport, isLoading } = useDownloadAuditReportQuery({
    entityId,
    mock,
  })

  const { auditReportDownloadPermission } = useHasPermission({
    auditReportDownloadPermission: PermissionTypes.AuditReportDownload,
  })

  // const filterOptions: FrankieSelectOptions<string>[] = [
  //   {
  //     label: t('label.connector'),
  //     value: AuditReportSourceOptionsTypes.DATA_CONNECTOR,
  //   },
  //   { label: t('label.entity'), value: AuditReportSourceOptionsTypes.ENTITY },
  //   {
  //     label: t('label.frankieOne'),
  //     value: AuditReportSourceOptionsTypes.FRANKIEONE,
  //   },
  //   {
  //     label: t('label.portalUser'),
  //     value: AuditReportSourceOptionsTypes.PORTALUSER,
  //   },
  // ]

  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [filterState, setFilterState] = useState<string>('')
  // const handleChange = (e: { target: { value: string | number | null } }) => {
  //   if (e.target.value) setFilterState(`${e.target.value}`)
  // }
  return (
    <div
      className="flex flex-col justify-center gap-5"
      data-qa={headerF2Qa.header}
    >
      <Banner theme="info" className="!pb-1.5" heading={t('banner.title')} />

      <div>
        <h1
          className="font-bold text-2xl mb-1.5 text-tertiary-grey-800 leading-7"
          data-qa={headerF2Qa.title}
        >
          {t('header.title')}
        </h1>
        <p
          data-qa={headerF2Qa.subTitle}
          className="text-tertiary-grey-500 leading-5"
        >
          {t('header.subTitle')}
        </p>
      </div>
      <div className="flex justify-between">
        <div className="w-32">
          {/* <FrankieSelectField
            placeholder={t('header.dropDownPlaceHolder')}
            options={filterOptions}
            optionsClassName="!w-44 !z-[100]"
            onChange={handleChange}
            value={filterState}
            testId={{ input: headerF2Qa.selectField }}
          /> */}
        </div>

        {auditReportDownloadPermission && (
          <FrankieButton
            disabled={isLoading}
            onClick={() => downloadReport()}
            intent="secondary"
            testId={{ button: headerF2Qa.cta }}
          >
            {t('header.cta')}
          </FrankieButton>
        )}
      </div>
    </div>
  )
}
