import xss from 'xss'

import { useI18n } from 'shared/i18n'

import { VALIDATION_LOCALE, validationEn } from '../locale/validation.en'

/**
 * Email pattern (RFC 5322):
 * - Allows alphanumeric characters, dots, and special chars in local part
 * - Requires @ symbol
 * - Domain part allows alphanumeric chars and hyphens
 * - Domain segments max 63 chars
 */
export const EMAIL_PATTERN =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i

export const DECIMAL_2_PLACES_PATTERN = /^\d+(\.\d{1,2})?$/
export const UK_POSTAL_CODE_PATTERN =
  /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/

export const ALPHABETIC_PATTERN = /^[A-Za-z ]+$/i
export const NUMERIC_PATTERN = /^[0-9]+$/i
export const NON_NUMERIC_PATTERN = /^[^0-9]+$/i
export const ALPHA_NUMERIC_PATTERN = /^[A-Za-z0-9 ]+$/i
export const NZ_BIRTH_CERT_PATTERN = /^\d{1,4}\/?\d+$/

export const useValidationRules = () => {
  const t = useI18n('common', { keys: { [VALIDATION_LOCALE]: validationEn } })
  const alphabeticRule = {
    pattern: {
      value: ALPHABETIC_PATTERN,
      message: t('validation.validation.alphabetic'),
    },
  }

  const alphaNumericRule = {
    pattern: {
      value: ALPHA_NUMERIC_PATTERN,
      message: t('validation.validation.alphaNumeric'),
    },
  }

  const numericRule = {
    pattern: {
      value: NUMERIC_PATTERN,
      message: t('validation.validation.numeric'),
    },
  }

  const nonNumericRule = {
    pattern: {
      value: NON_NUMERIC_PATTERN,
      message: t('validation.validation.nonNumeric'),
    },
  }

  const xssRule = {
    validate: (value?: unknown) => {
      if (value && typeof value === 'string' && value !== xss(value)) {
        return t('validation.validation.xss')
      }
      return true
    },
  }

  return {
    alphabeticRule,
    nonNumericRule,
    alphaNumericRule,
    numericRule,
    xssRule,
  }
}
