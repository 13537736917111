import React, { useEffect, useMemo, useState } from 'react'

import { useForm } from 'react-hook-form'

import { FrankieButton, FrankieIcon, FrankieLoader } from 'frankify/src'

import { useUpdateAmlStatus } from 'entities/entity/mutation/resolve-aml/resolve-aml'
import { useEntityAmlData } from 'entities/entity/state/entity-aml/entity-aml.state'

import { SelectFormField, TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'
import { EmptyString } from 'shared/typescript'
import { useValidationRules } from 'shared/validation'

import { ENTITY_KEY } from '../../entity.key'
import { entityEn } from '../../locale/entity.en'
import {
  getAmlStatusConfig,
  getApprovalStatusOptions,
} from '../../model/aml.model'
import {
  ProcessResultManualStatusEnumAML,
  ProcessResultResponse,
} from '../../model/entity.model'
import { entityAssignQa } from '../../qa/entity.qa'

type AmlStatusChangeFormProps = {
  statusValue?: string
  processResultId: string[]
  restProcessResultIds?: string[]
  entityId: string
  closeOverlay: () => void
  onAmlResolveSuccess?: (data: ProcessResultResponse[], cb?: () => void) => void
}

export type UpdateStatusType = {
  amlStatus: ProcessResultManualStatusEnumAML
  comment: string
  approvalStatus: EmptyString<ProcessResultManualStatusEnumAML>
}

function AlertComponent({ onKeep }: { onKeep: () => void }) {
  const t = useI18n([ENTITY_KEY], { keys: entityEn })

  return (
    <>
      <div className="flex flex-col gap-4 justify-center items-center pt-5 px-5 pb-4 ">
        <FrankieIcon
          name="mdiAlertCircleOutline"
          size="lg"
          className="text-tertiary-red-700"
        />

        <div className="text-tertiary-grey-700 text-md leading-6 text-center">
          <div>{t('alertAml.title')}</div>
          <div>{t('alertAml.subTitle')}</div>
        </div>
      </div>
      <div className="flex flex-grow-0 justify-end mt-6">
        <FrankieButton
          intent="secondary"
          className="mr-[20px]"
          size="sm"
          type="button"
          onClick={onKeep}
        >
          {t('alertAml.noKeep')}
        </FrankieButton>
        <FrankieButton size="sm" type="submit">
          {t('alertAml.yesUpdate')}
        </FrankieButton>
      </div>
    </>
  )
}

export function AmlStatusChangeForm({
  statusValue,
  processResultId,
  restProcessResultIds,
  entityId,
  closeOverlay,
  onAmlResolveSuccess,
}: AmlStatusChangeFormProps) {
  const t = useI18n([ENTITY_KEY], { keys: entityEn })
  const { data } = useEntityAmlData({ entityId })
  const { xssRule } = useValidationRules()

  const [isWorkflowExecLoading, setWorkflowExecLoading] = useState(false)
  const [showAlert, setShowAlert] = useState(false)

  // **Store the first mutation result**
  const [firstMutationResult, setFirstMutationResult] =
    useState<ProcessResultResponse | null>(null)

  const manualStatus = useMemo(
    () =>
      processResultId.length === 1
        ? (data.processResults.find(
            i => i.processResultId === processResultId[0],
          )?.manualStatus as ProcessResultManualStatusEnumAML)
        : undefined,
    [data, processResultId],
  )

  const { options } = getAmlStatusConfig(t, manualStatus)

  const {
    control,
    formState: { isValid },
    watch,
    handleSubmit,
    reset,
  } = useForm<UpdateStatusType>({
    defaultValues: {
      amlStatus: statusValue as ProcessResultManualStatusEnumAML,
      approvalStatus: '',
      comment: '',
    },
    mode: 'onTouched',
  })

  const amlStatus = watch('amlStatus')
  const approvalStatus = watch('approvalStatus')

  const isBtnOnly = useMemo(
    () =>
      (restProcessResultIds || []).length > 0 &&
      amlStatus === ProcessResultManualStatusEnumAML.TRUE_POSITIVE &&
      approvalStatus === ProcessResultManualStatusEnumAML.TRUE_POSITIVE_REJECT,
    [restProcessResultIds, amlStatus, approvalStatus],
  )

  const { mutateAsync } = useUpdateAmlStatus({ entityId })

  useEffect(() => {
    trackingManager.trackWithProps(TrackingEventsTypes.AmlModalViewIndividual, {
      entityId: data.processResults.at(0)?.entityId,
      manualStatus: data.processResults.at(0)?.manualStatus,
      processResultId: data.processResults.at(0)?.processResultId,
      systemStatus: data.processResults.at(0)?.systemStatus,
    })
  }, [data])

  const handleSuccess = (combinedData: ProcessResultResponse[]) => {
    if (typeof onAmlResolveSuccess !== 'function') {
      setWorkflowExecLoading(false)
      return
    }

    onAmlResolveSuccess(combinedData, () => {
      closeOverlay()
      reset()
      setWorkflowExecLoading(false)
    })
  }

  const handleAlertKeep = () => {
    if (firstMutationResult) {
      setWorkflowExecLoading(true)
      handleSuccess([firstMutationResult])
    }
  }

  const onSubmitHandler = async (formData: UpdateStatusType) => {
    setWorkflowExecLoading(true)

    try {
      if (!showAlert) {
        // First submission
        const firstResult = await mutateAsync({
          processResults: processResultId,
          manualStatus: formData.approvalStatus || formData.amlStatus,
          comment: { text: formData.comment },
        })

        setFirstMutationResult(firstResult)

        if (isBtnOnly) {
          // We will need a second submission later
          setShowAlert(true)
          setWorkflowExecLoading(false)
        } else {
          // No second submission needed
          // Call onAmlResolveSuccess with just the firstResult
          trackingManager.trackWithProps(
            TrackingEventsTypes.AmlConfirmResolveIndividual,
            {
              entityId,
              amlStatus: formData.amlStatus,
              approvalStatus: formData.approvalStatus,
            },
          )

          // If we don't need a second call, just pass the firstResult
          handleSuccess([firstResult])
        }
      } else {
        // Second submission (only if isBtnOnly was true)
        const secondResult = await mutateAsync({
          processResults: restProcessResultIds || [],
          manualStatus: ProcessResultManualStatusEnumAML.FALSE_POSITIVE,
          comment: { text: formData.comment },
        })

        if (firstMutationResult) {
          trackingManager.trackWithProps(
            TrackingEventsTypes.AmlConfirmResolveIndividual,
            {
              entityId,
              amlStatus: formData.amlStatus,
              approvalStatus: formData.approvalStatus,
            },
          )

          // Pass the combined result to handleSuccess
          handleSuccess([firstMutationResult, secondResult])
        } else {
          console.error('First mutation result not found. Check logic.')
          setWorkflowExecLoading(false)
        }
      }
    } catch (error) {
      console.error('Error during mutation:', error)
      setWorkflowExecLoading(false)
    }
  }

  const approvalStatusOptions = useMemo(
    () => getApprovalStatusOptions({ amlStatus, t }),
    [amlStatus, t],
  )

  return (
    <div className="w-[440px]">
      <FrankieLoader
        label={t('alertAml.loading')}
        fullscreen
        className="z-40"
        size="md"
        loading={isWorkflowExecLoading}
      />
      <div className="flex flex-col gap-4">
        <h1 className="text-tertiary-grey-800 font-bold text-xl leading-7">
          {t('amlStatusForm.title')}
        </h1>

        <form onSubmit={handleSubmit(onSubmitHandler)}>
          {showAlert && <AlertComponent onKeep={handleAlertKeep} />}
          <div className={showAlert ? 'invisible !h-0' : ''}>
            <h2 className="leading-5 mb-4 text-md">
              {t('amlStatusForm.subTitle')}
            </h2>
            <div className="flex flex-col gap-4 justify-between">
              <div className="flex flex-col gap-4">
                <SelectFormField
                  name="amlStatus"
                  control={control}
                  options={options}
                  label={t('amlStatusForm.selectLabel')}
                  rules={{ required: true }}
                  testId={{ input: entityAssignQa.amlStatusSelect }}
                />
                {approvalStatusOptions.length > 0 && (
                  <SelectFormField
                    name="approvalStatus"
                    control={control}
                    options={approvalStatusOptions}
                    label={t('amlStatusForm.approvalStatusSelectLabel')}
                    placeholder={t('amlStatusForm.approvalStatusPlaceholder')}
                    rules={{ required: true }}
                    testId={{ input: entityAssignQa.approvalStatusSelect }}
                    shouldUnregister
                  />
                )}

                <TextAreaFormField
                  name="comment"
                  shouldUnregister
                  control={control}
                  inputClassName="!min-h-[56px]"
                  showError
                  trim
                  counter={500}
                  maxLength={500}
                  label={t('amlStatusForm.commentLabel')}
                  rules={{
                    required: t('amlStatusForm.commentError'),
                    ...xssRule,
                  }}
                  testId={{ input: entityAssignQa.amlCommentTextArea }}
                />
              </div>
              <div className="flex flex-grow-0 justify-end">
                <FrankieButton
                  className="mr-8"
                  noStyles
                  onClick={() => closeOverlay()}
                >
                  {t('amlStatusForm.cancel')}
                </FrankieButton>
                <FrankieButton
                  size="sm"
                  disabled={!isValid}
                  type="submit"
                  testId={{ button: entityAssignQa.resolveStatus }}
                >
                  {t('amlStatusForm.changeStatus')}
                </FrankieButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
