import React from 'react'

import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { FrankieButton, FrankieIcon } from 'frankify/src'

import { SelectFormField, TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { useValidationRules } from 'shared/validation'

import { INDIVIDUAL_IDV_CHECK_KEY } from '../../../individual-idv-check.key'
import { individualIdvCheckEn } from '../../../locale/individual-idv-check.en'

type ManualStatusChangeFormProps = {
  options: { label: string; value: string }[]

  closeOverlay: () => void
  updateWorkflowStatus: (data: UpdateStatusType) => Promise<void>
}

export type UpdateStatusType = {
  manualStatus: string
  comment: string
}

export function ManualStatusChangeForm({
  options,
  closeOverlay,
  updateWorkflowStatus,
}: ManualStatusChangeFormProps) {
  const t = useI18n([INDIVIDUAL_IDV_CHECK_KEY], {
    keys: individualIdvCheckEn,
  })

  const { xssRule } = useValidationRules()

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<UpdateStatusType>({
    defaultValues: {
      manualStatus: '',
      comment: '',
    },
    mode: 'onTouched',
  })

  const [isLoading, setIsLoading] = React.useState(false)
  const onSubmitHandler = async (data: UpdateStatusType) => {
    setIsLoading(true)
    try {
      await updateWorkflowStatus(data)
      toast.success(t('form.successMessage'))
    } catch (error) {
      toast.error(t('form.errorMessage'))
    }
    setIsLoading(false)
    closeOverlay()
  }

  return (
    <div className="w-[440px]">
      <div className="flex flex-col">
        <div className="flex flex-col gap-4">
          <div className="flex justify-between flex-grow-0">
            <div className="text-tertiary-grey-800 font-bold text-xl leading-7">
              {t('form.title')}
            </div>
          </div>

          <div className="leading-5">{t('form.subTitle')}</div>

          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            className="flex flex-col gap-4 justify-between"
          >
            <div className="flex flex-col gap-4">
              <SelectFormField
                name="manualStatus"
                control={control}
                options={options}
                label={t('form.selectLabel')}
                rules={{ required: true }}
              />

              <TextAreaFormField
                name="comment"
                shouldUnregister
                control={control}
                showError
                label={t('form.commentLabel')}
                rules={{ required: true, ...xssRule }}
              />
            </div>
            <div className="flex flex-grow-0 justify-end">
              <FrankieButton
                className="mr-8 font-semibold"
                noStyles
                onClick={() => closeOverlay()}
              >
                {t('form.cancel')}
              </FrankieButton>
              <FrankieButton
                size="sm"
                disabled={!isValid || isLoading}
                type="submit"
                className="gap-1"
              >
                {isLoading && (
                  <FrankieIcon
                    size="sm"
                    name="mdiLoading"
                    className="animate-spin"
                  />
                )}
                {t('form.changeStatus')}
              </FrankieButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
