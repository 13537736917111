import React from 'react'

import { PdfPreview } from 'shared/pdf-preview/ui/pdf-preview'

type MediaProps = {
  mimeType: string
  url: string
}

export function Media(props: MediaProps) {
  switch (props.mimeType) {
    case 'application/pdf':
      return (
        <div className="shadow-md rounded-md max-h-[254px] overflow-hidden">
          <PdfPreview
            url={props.url}
            documentProps={{ loading: null }}
            pageProps={{
              pageNumber: 1,
              width: 256,
              renderAnnotationLayer: false,
              renderTextLayer: false,
            }}
          />
        </div>
      )
    default:
      return (
        <img
          src={props.url}
          alt=""
          className="object-cover m-auto max-h-[254px] h-full w-full"
        />
      )
  }
}
