import React, { useMemo } from 'react'

import { useInView } from 'react-intersection-observer'

import { FrankieDivider, FrankieIcon } from 'frankify/src'

import { IndividualProfileDocumentQa } from 'features/individual-profile-insight/qa/individual-profile-insight.qa'

import {
  DOCUMENT_F2_KEY,
  DocumentViewF2R2,
  documentF2En,
  documentIdTypesOption,
  useFilteredAttachments,
} from 'entities/document-f2'
import { DocumentType, useEntityLabelDataState } from 'entities/entity'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, useTrackingShowEvents } from 'shared/tracking'

import { INDIVIDUAL_PROFILE_INSIGHT_KEY } from '../../individual-profile-insight.key'
import { individualProfileInsightEn } from '../../locale/individual-profile-insight.en'

type Props = {
  entityId: string
  isInvalidated?: boolean
}

export function IndividualProfileDocumentR2({
  entityId,
  isInvalidated,
}: Props) {
  const { ref, inView } = useInView()
  const tDocument = useI18n(DOCUMENT_F2_KEY, { keys: documentF2En })
  const { documentWithLabelData = [] } = useEntityLabelDataState({ entityId })
  const { filterMostRecentAttachments } = useFilteredAttachments(
    documentWithLabelData,
  )

  const t = useI18n(INDIVIDUAL_PROFILE_INSIGHT_KEY, {
    keys: individualProfileInsightEn,
  })

  useTrackingShowEvents({
    data: inView,
    eventsType: TrackingEventsTypes.ProfileDocumentShow,
  })

  const { supportedDocuments, unsupportedDocuments } = useMemo(() => {
    const filtered = filterMostRecentAttachments(documentWithLabelData).filter(
      document =>
        [
          DocumentType.PASSPORT,
          DocumentType.DRIVERS_LICENSE,
          DocumentType.NATIONAL_HEALTH_ID,
          DocumentType.NATIONAL_ID,
          DocumentType.BIRTH_CERT,
          DocumentType.TAX_ID,
          DocumentType.OTHER,
        ].includes(document.type),
    )

    const { supported, unsupported } = filtered.reduce<{
      supported: typeof filtered
      unsupported: typeof filtered
    }>(
      (acc, doc) => {
        const isSupported = [
          DocumentType.PASSPORT,
          DocumentType.DRIVERS_LICENSE,
          DocumentType.NATIONAL_HEALTH_ID,
        ].includes(doc.type)

        if (isSupported) {
          acc.supported.push(doc)
        } else if (doc.attachments.length > 0) {
          acc.unsupported.push(doc)
        }
        return acc
      },
      { supported: [], unsupported: [] },
    )

    return {
      supportedDocuments: supported,
      unsupportedDocuments: unsupported,
    }
  }, [documentWithLabelData, filterMostRecentAttachments])

  return (
    <div
      className="flex flex-col gap-y-3"
      data-qa={IndividualProfileDocumentQa.container}
      ref={ref}
    >
      <h1 className="font-bold text-tertiary-grey-800 text-xl flex items-center gap-1">
        {isInvalidated && (
          <FrankieIcon
            size="md"
            name="mdiAlertCircle"
            className="text-tertiary-yellow-300"
            testId={{ icon: IndividualProfileDocumentQa.tabDocumentIcon }}
          />
        )}
        {t('tab.documents')}
      </h1>

      <div className="flex flex-col gap-y-2">
        {supportedDocuments.map((document, idx) => {
          const hasAttachment = document.attachments.length > 0

          const { tKey = '' } =
            documentIdTypesOption.find(
              ({ value }) => value === document.type,
            ) ?? {}

          const updatedAt =
            document.attachments[0]?.createdAt || document.createdAt

          return (
            <React.Fragment key={document.documentId}>
              {tKey ? (
                <h2 className="font-semibold text-md leading-6 text-tertiary-grey-800">
                  {tDocument(tKey)}
                </h2>
              ) : null}

              <div className="w-full flex grow-0 shrink-0 gap-6">
                {hasAttachment ? (
                  <div
                    className="w-[220px] desktop:w-[250px]"
                    data-qa={IndividualProfileDocumentQa.documentViewContainer}
                  >
                    <DocumentViewF2R2
                      document={document}
                      trackingEvents={{
                        gallery: [
                          TrackingEventsTypes.ProfileDocumentFront,
                          TrackingEventsTypes.ProfileDocumentBack,
                        ],
                        rotate: TrackingEventsTypes.ProfileDocumentExpandRotate,
                        zoom: TrackingEventsTypes.ProfileDocumentExpandZoom,
                        show: TrackingEventsTypes.ProfileDocumentExpandShow,
                      }}
                      className="aspect-video"
                      extraData={document.extraData}
                    />
                  </div>
                ) : null}

                <div
                  className="grid grid-cols-3 gap-x-6 gap-y-3 auto-rows-max"
                  data-qa={IndividualProfileDocumentQa.srcLabelContainer}
                >
                  {[...document.label].map(({ label, value }) =>
                    !value ? null : (
                      <div key={label} className="basis-1/3 min-w-[193px]">
                        <p className="font-medium text-tertiary-grey-800">
                          {label}
                        </p>
                        <p
                          data-hj-suppress
                          className="font-normal text-tertiary-grey-700"
                        >
                          {value}
                        </p>
                      </div>
                    ),
                  )}
                  <p className="w-full col-span-full text-tertiary-grey-500 text-sm leading-5 mt-4">
                    {updatedAt
                      ? t('uploadedAt', {
                          date: formatDate(
                            updatedAt,
                            DateFormatTypes.DateNumbersSlash,
                          ),
                          time: formatDate(
                            updatedAt,
                            DateFormatTypes.Time24HoursWithSeconds,
                          ),
                        })
                      : null}
                  </p>
                </div>
              </div>
              {supportedDocuments.length > 1 &&
              idx < supportedDocuments.length - 1 ? (
                <div className="py-4 mt-1">
                  <FrankieDivider className="w-full bg-tertiary-grey-200" />
                </div>
              ) : null}
            </React.Fragment>
          )
        })}
        {supportedDocuments.length > 0 && unsupportedDocuments.length > 0 && (
          <FrankieDivider className="w-full bg-tertiary-grey-200 my-4" />
        )}
        {unsupportedDocuments.length > 0 && (
          <div>
            <h2 className="font-semibold text-md leading-6 text-tertiary-grey-800">
              {t('documentsSection.otherDocs')}
            </h2>
            <p className="text-xs text-tertiary-grey-500">
              {t('documentsSection.otherDocsDesc')}
            </p>
            <div className="overflow-x-auto">
              <div className="flex gap-4 pb-4 mt-4">
                {unsupportedDocuments.map(document => (
                  <div key={document.documentId} className="w-[258px]">
                    <DocumentViewF2R2
                      document={document}
                      trackingEvents={{
                        gallery: [
                          TrackingEventsTypes.ProfileDocumentFront,
                          TrackingEventsTypes.ProfileDocumentBack,
                        ],
                        rotate: TrackingEventsTypes.ProfileDocumentExpandRotate,
                        zoom: TrackingEventsTypes.ProfileDocumentExpandZoom,
                        show: TrackingEventsTypes.ProfileDocumentExpandShow,
                      }}
                      className="w-[250px] aspect-video"
                      extraData={document.extraData}
                      showControls={false}
                      documentList={unsupportedDocuments}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
