import React, { useRef, useState, useEffect } from 'react'

import { FrankieBadge } from '../badge'
import { FrankieTooltip } from '../tooltip'

type ValueType = string | number

export enum VerticalTabBadgeVariantTypes {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
}

export type VerticalTabBadge = {
  text: string
  variant: VerticalTabBadgeVariantTypes
}

export type IFrankieVerticalTabsProps<TValue extends ValueType> = {
  activeTab?: TValue
  onTabChange: (value: TValue, e: React.MouseEvent) => void
  tabItems: {
    label: string
    value: TValue
    link?: string
    testId?: string
    badge?: VerticalTabBadge
    subtext?: string
    className?: string
    labelClassName?: string
  }[]
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right'
  showTooltip?: boolean
}

const getBadgeTheme = (variant: VerticalTabBadgeVariantTypes) => {
  switch (variant) {
    case VerticalTabBadgeVariantTypes.Success:
      return 'green'
    case VerticalTabBadgeVariantTypes.Warning:
      return 'yellow'
    case VerticalTabBadgeVariantTypes.Error:
      return 'red'
    case VerticalTabBadgeVariantTypes.Info:
      return 'blue'
    default:
      return 'grey'
  }
}

export function FrankieVerticalTabs<TValue extends ValueType>({
  tabItems,
  activeTab,
  onTabChange,
  tooltipPosition = 'right',
  showTooltip = true,
}: IFrankieVerticalTabsProps<TValue>) {
  const [truncatedItems, setTruncatedItems] = useState<
    Record<string | number, boolean>
  >({})
  const textRefs = useRef<Record<string | number, HTMLDivElement | null>>({})

  useEffect(() => {
    const checkTruncation = () => {
      const newTruncatedItems: Record<string | number, boolean> = {}
      Object.entries(textRefs.current).forEach(([value, ref]) => {
        if (ref) {
          newTruncatedItems[value] = ref.scrollWidth > ref.clientWidth
        }
      })
      setTruncatedItems(newTruncatedItems)
    }

    checkTruncation()
    window.addEventListener('resize', checkTruncation)
    return () => window.removeEventListener('resize', checkTruncation)
  }, [tabItems])

  return (
    <div className="w-[200px] mr-2 border-s-[2px] border-tertiary-grey-200">
      {tabItems.map(
        (
          {
            value,
            label,
            link,
            testId,
            badge,
            subtext,
            className = '',
            labelClassName = '',
          },
          index,
        ) => (
          <a
            type="button"
            key={value}
            href={link}
            data-qa={testId}
            className={`flex items-stretch justify-start my-2 w-full ${
              index === 0 ? 'mt-0' : ''
            } ${className}`}
            onClick={e => onTabChange(value, e)}
          >
            <div
              className={`ml-[-2px] mr-2 w-[2px] ${
                value === activeTab ? 'rounded-md bg-primary-800' : ''
              }`}
            />
            <div className="flex-1 min-w-0">
              <div className="mx-2">
                <FrankieTooltip
                  hidden={!truncatedItems[value] || !showTooltip}
                  body={
                    truncatedItems[value] && showTooltip ? label : undefined
                  }
                  position={tooltipPosition}
                >
                  <p
                    ref={ref => {
                      textRefs.current[value] = ref
                    }}
                    className={`block text-start leading-relaxed font-semibold truncate ${labelClassName} ${
                      value === activeTab
                        ? 'text-primary-800'
                        : 'text-tertiary-grey-600 hover:text-tertiary-grey-700'
                    }`}
                  >
                    {label}
                  </p>
                </FrankieTooltip>
                {badge && (
                  <div className="mt-1">
                    <FrankieBadge
                      theme={getBadgeTheme(badge.variant)}
                      text={badge.text}
                      size="sm"
                    />
                  </div>
                )}
                {subtext && (
                  <p className="text-xs text-tertiary-grey-500 mt-1">
                    {subtext}
                  </p>
                )}
              </div>
            </div>
          </a>
        ),
      )}
    </div>
  )
}
