import React, { Suspense, useCallback, useEffect, useState } from 'react'

import { LicenseInfo } from '@mui/x-license-pro'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { pdfjs } from 'react-pdf'
import { RouterProvider } from 'react-router-dom'

import { FrankieLoader, enableTailwindClass } from 'frankify/src'

import { i18n, I18nProvider } from 'app/i18n'
// These styles are used in the portal-vue application,
// there are some conflicts with tailwind styles.
import { listenReact } from 'app/portal-vue-compatibility'
import 'app/portal-vue-compatibility/breaking-styles.css'
import { CreatedRouter, paths } from 'app/routing'
import { initQueryClient } from 'app/state-management'

import { logout, replaceUrl } from 'features/logout'

import { initAppInfo } from 'shared/app-info/model/app-info.model'
import { appcuesRegisterTracker } from 'shared/appcues'
import { googleAnalyticsRegisterTracker } from 'shared/google-analytics'
import { hotjarRegisterTracker } from 'shared/hotjar'
import { NotificationContainer } from 'shared/notification'
import { OverlayProvider } from 'shared/overlay'
import { posthogRegisterTracker } from 'shared/posthog'
import { Nullable } from 'shared/typescript'

// setup the mui-grid-pro key
LicenseInfo.setLicenseKey(
  'b22061056887c60767c6d236f6a7b360Tz04MDg1NSxFPTE3MzQ2NzA5OTEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
)

pdfjs.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

function clearAppState(resetQueryClient: () => void) {
  logout()
  resetQueryClient()
  replaceUrl(paths.login)
}

// TODO: @ggrigorev - remove this when vertical navigation is released
listenReact()

export function App(): JSX.Element | null {
  const [queryClient, setQueryClient] = useState<Nullable<QueryClient>>(null)

  /**
   * When any request fails with invalid token error,
   * clears application state completely and re-inits query client
   */
  const setQueryClientPersistently = useCallback(() => {
    setQueryClient(
      initQueryClient({
        onInvalidTokenError: () => {
          clearAppState(setQueryClientPersistently)
        },
      }),
    )
  }, [])

  // called once
  useEffect(() => {
    appcuesRegisterTracker()
    hotjarRegisterTracker()
    posthogRegisterTracker()
    googleAnalyticsRegisterTracker()
    setQueryClientPersistently()
    initAppInfo()
  }, [setQueryClientPersistently])

  if (queryClient) {
    return (
      <div className={enableTailwindClass}>
        <I18nProvider i18n={i18n}>
          <QueryClientProvider client={queryClient}>
            <OverlayProvider>
              <Suspense
                fallback={<FrankieLoader loading className="h-screen" />}
              >
                <RouterProvider router={CreatedRouter} />
              </Suspense>
              <NotificationContainer />
            </OverlayProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </I18nProvider>
      </div>
    )
  }

  return null
}
