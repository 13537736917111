import type { ServiceId } from 'entities/service'

export type WorkflowId = string

export const WORKFLOW_EXECUTION_ID_QUERY_PARAM = 'execution'

export interface IWorkflow {
  workflowId: WorkflowId
  serviceId: ServiceId
  serviceName: string
  workflowName: string
  lifecyclePhase: WorkflowLifecyclePhaseTypes
}

export enum WorkflowLifecyclePhaseTypes {
  ONBOARDING = 'ONBOARDING',
  OTHER = 'OTHER',
}
