import { useMemo } from 'react'

import { VerticalTabBadge } from 'frankify/src'

import { type ServiceProfile } from 'entities/entity'

import { formatDate, DateFormatTypes } from 'shared/date-time'
import { type I18nFunction } from 'shared/i18n'

import { workflowEventsEn } from '../locale/workflow-events.en'
import { getWorkflowEventResultConfig } from '../model/applicant-workflow-events-mapping.model'

type NavItem = {
  value: string
  label: string
  testId: string
  subtext: string
  badge: VerticalTabBadge
}

export const useNavItems = <
  IType extends I18nFunction<typeof workflowEventsEn>,
>(
  t: IType,
  workflowSummaries?: ServiceProfile['workflowSummaries'],
): NavItem[] =>
  useMemo(() => {
    if (!workflowSummaries?.length) return []

    // Filter out duplicates keeping only the latest entry for each workflowName
    const uniqueWorkflows = workflowSummaries.reduce<
      ServiceProfile['workflowSummaries']
    >((acc, current) => {
      const existingIndex = acc?.findIndex(
        item => item.workflowName === current.workflowName,
      )

      if (existingIndex === -1) {
        acc?.push(current)
      } else if (acc) {
        const existing = acc.at(Number(existingIndex))

        if (
          new Date(String(current.startedAt)) >
          new Date(String(existing?.startedAt))
        ) {
          acc[Number(existingIndex)] = current
        }
      }

      return acc
    }, [])

    // Convert to NavItems and sort by timestamp
    return (uniqueWorkflows ?? [])
      .sort((a, b) => {
        const aTime = new Date(a.startedAt ?? 0).getTime()
        const bTime = new Date(b.startedAt ?? 0).getTime()
        return bTime - aTime
      })
      .map(summary => ({
        value: String(summary.workflowName),
        label: String(summary.workflowName),
        className: 'my-4',
        labelClassName: 'pt-2',
        testId: `workflow-tab-${String(summary.workflowName)}`,
        subtext: formatDate(
          String(summary.startedAt),
          DateFormatTypes.ShortDate,
        ),
        badge: {
          text: getWorkflowEventResultConfig(t, summary).text,
          variant: getWorkflowEventResultConfig(t, summary)
            .variant as NavItem['badge']['variant'],
        },
      }))
  }, [workflowSummaries, t])
