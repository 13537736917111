import React, { useState } from 'react'

import { useNonSelfImageDocuments } from 'features/individual-ocr-check/state/individual-ocr-check/individual-ocr-check.state'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { FrankieUtilityImg } from 'shared/frankie-image'
import { useI18n } from 'shared/i18n'
import { ImageGallery, GalleryItem } from 'shared/image-gallery'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { BiometricMedia } from './biometric-media'
import { INDIVIDUAL_OCR_CHECK_KEY } from '../../individual-ocr-check.key'
import { individualOcrCheckEn } from '../../locale/individual-ocr-check.en'

const docImageEvents = {
  zoom: TrackingEventsTypes.WorkflowEventsBiometricsCompareDocZoom,
  rotate: TrackingEventsTypes.WorkflowEventsBiometricsCompareDocRotate,
  gallery: [
    TrackingEventsTypes.WorkflowEventsBiometricsCompareDocFront,
    TrackingEventsTypes.WorkflowEventsBiometricsCompareDocBack,
  ],
}

type Props = {
  gallery: GalleryItem[]
  defaultIndex: number
  entityId: string
}
export function IndividualOcrOverlay({
  defaultIndex,
  gallery,
  entityId,
}: Props) {
  const [activeIndex, setActiveIndex] = useState(defaultIndex)
  const [activeDocument, setActiveDocument] = useState(0)

  const t = useI18n([INDIVIDUAL_OCR_CHECK_KEY], { keys: individualOcrCheckEn })

  const { documents, documentThumbs, attachments } =
    useNonSelfImageDocuments(entityId)

  const getHeaderSlot = (attachmentIdx: number) => {
    const document = documents.at(activeDocument)
    const attachment = document?.attachments?.at(attachmentIdx)
    if (!attachment) return null
    return (
      <div className="flex justify-between">
        <div className="text-tertiary-grey-500">
          {t('modal.uploadedOn', {
            date: formatDate(
              attachment.createdAt || 0,
              DateFormatTypes.DateNumbersSlash,
            ),
            time: formatDate(
              attachment.createdAt || 0,
              DateFormatTypes.Time24HoursWithSeconds,
            ),
            via: '',
          })}
        </div>
      </div>
    )
  }
  const activeItem = gallery.at(activeIndex)

  return (
    <div>
      <div className="text-xl mb-4 font-bold text-tertiary-grey-800">
        {t('modal.compareImages')}
      </div>
      <div className="flex gap-6">
        <div className="flex w-[400px] flex-col shrink gap-3">
          {!!activeItem && (
            <>
              <p className="text-tertiary-grey-500">
                {t('modal.uploadedOn', {
                  date: formatDate(
                    activeItem.createdAt || 0,
                    DateFormatTypes.DateNumbersSlash,
                  ),
                  time: formatDate(
                    activeItem.createdAt || 0,
                    DateFormatTypes.Time24HoursWithSeconds,
                  ),
                  via: '',
                })}
              </p>
              <BiometricMedia activeItem={activeItem} />
            </>
          )}
          <ImageGallery
            gallery={gallery}
            selected={activeIndex}
            onChange={idx => {
              setActiveIndex(idx)
              trackingManager.track(
                TrackingEventsTypes.WorkflowEventsBiometricsCompareThumbnailClick,
              )
            }}
          />
        </div>
        {!!attachments[activeDocument] && (
          <div className="flex w-[400px] flex-col shrink gap-3">
            <FrankieUtilityImg
              key={`${activeDocument}-overlay-doc`}
              gallery={attachments[activeDocument]}
              headerSlot={getHeaderSlot}
              trackingEvents={docImageEvents}
            />
            <ImageGallery
              gallery={documentThumbs}
              selected={activeDocument}
              onChange={idx => {
                setActiveDocument(idx)
                trackingManager.track(
                  TrackingEventsTypes.WorkflowEventsBiometricsCompareDocSwitch,
                )
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
