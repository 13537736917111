import React, { useEffect, useMemo, useState } from 'react'

import { InputAdornment, InputLabel, TextField } from '@mui/material'
import cx from 'classnames'

import { FrankieButton, FrankieLoader } from 'frankify/src'

import { useApplicantDocumentDownload } from 'features/applicant-supporting-documents'

import {
  ApplicantResponse,
  Document,
  useApplicantDataQuery,
  useApplicantUpdateDetailsMutation,
} from 'entities/applicant'
import {
  DOCUMENT_KEY,
  DocumentIdTypes,
  DocumentView,
  documentEn,
} from 'entities/document'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { Nullable } from 'shared/typescript'

import { APPLICANT_PERSONAL_INFO_KEYS } from '../../applicant-persona-info.keys'
import { applicantPersonalInfoEn } from '../../locale/applicant-personal-info.en'
import {
  DetailViewTypes,
  checkManualKYCPass,
} from '../../model/applicant-detail-view.model'
import {
  AdditionalDocumentsTypes,
  Content,
  DocumentInfoTypes,
  getDocIcon,
  getDocumentDetails,
  getDocumentStatus,
  getVevoData,
  getVisaIssueMessages,
  sortDocByVerifiedStatus,
} from '../../model/document-detail-view.model'
import { documentInfoQa } from '../../qa/applicant-personal-info.qa'
import { ApplicantDetailView } from '../applicant-detail-view/applicant-detail-view'

type Props = {
  applicantData: ApplicantResponse
  isLegacyProfile: boolean
  testId?: { container?: string }
}

type FieldViewTypes = {
  content: Content[] | []
  documentId: string
  testId?: {
    button?: string
    overlay?: string
  }
}

type OcrUpdateProps = {
  documentId: string
  entityId: string
  ocrExpiryDate: Nullable<string>
}

function FieldView({ content, documentId, testId }: FieldViewTypes) {
  const t = useI18n(APPLICANT_PERSONAL_INFO_KEYS, {
    keys: applicantPersonalInfoEn,
  })

  const {
    data: scanData,
    isLoading,
    downloadReport,
  } = useApplicantDocumentDownload({
    disableDownload: true,
    showNoScanError: false,
  })

  const [createOverlay] = useOverlay()

  const isViewButtonShown = useMemo(
    () => scanData?.data.some(scan => scan.file && scan.mimeType),
    [scanData?.data],
  )

  const handleView = () => {
    // `data[0]` can be still undefined eventhough the type does not show it
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const { file, scanName, mimeType, scanCreated } = scanData?.data?.[0] ?? {}

    createOverlay(
      <div className="min-w-[600px] flex flex-col h-max min-h-[400px]">
        <DocumentView
          additionalData={scanData?.data.slice(1).map(data => ({
            fileUrl: data.file,
            mimeType: data.mimeType,
            fileName: data.scanName ?? '',
            uploadedOn: data.scanCreated,
          }))}
          testId={testId ? { container: testId.overlay } : undefined}
          fileUrl={file ?? ''}
          fileName={scanName ?? ''}
          mimeType={mimeType ?? ''}
          uploadedOn={scanCreated ?? ''}
        />
      </div>,
    )
  }

  useEffect(() => {
    if (documentId && !scanData?.data) {
      downloadReport(documentId)
    }
  }, [createOverlay, scanData, documentId, downloadReport])

  return (
    <div className="flex justify-start items-start text-tertiary-grey-700">
      <div
        className={`flex flex-wrap ${
          scanData?.data.length ? 'basis-[85%]' : 'basis-[20%]'
        }`}
      >
        {content
          .filter((data: Content) => data.value)
          .map(({ label, value }: Content) => (
            <div
              className="flex-col justify-around items-center mb-2 mx-2 max-w-[140px] basis-[33%]"
              key={value}
            >
              <div className="text-[13px]">{label}</div>
              <div className="text-sm font-bold">{value}</div>
            </div>
          ))}
      </div>
      <div className={cx('basis-[15%]', { 'h-[30px]': !isViewButtonShown })}>
        {isLoading ? (
          <FrankieLoader loading size="xs" />
        ) : (
          isViewButtonShown && (
            <FrankieButton
              testId={{ button: testId?.button }}
              size="xs"
              className="w-full text-tertiary-grey-400 border border-solid border-neutral-100 font-semibold rounded-sm p-1 hover:text-primary-700 hover:border-primary-700 flex-grow-0 ml-auto"
              intent="secondary"
              onClick={handleView}
            >
              {t('documentInfo.viewId')}
            </FrankieButton>
          )
        )}
      </div>
    </div>
  )
}

function OcrUpdate({ ocrExpiryDate, documentId, entityId }: OcrUpdateProps) {
  const t = useI18n(APPLICANT_PERSONAL_INFO_KEYS, {
    keys: applicantPersonalInfoEn,
  })

  const [focused, setFocused] = useState(false)
  const { data } = useApplicantDataQuery({
    applicantId: entityId,
    enabled: true,
  })
  const [ocrInputValue, setOcrInputValue] = useState<string | null>(
    ocrExpiryDate || null,
  )

  const { mutate, isIdle } = useApplicantUpdateDetailsMutation()
  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!data) return
    const payload = {
      applicant: data.applicantDetails,
      documents: data.documents,
    }

    payload.documents = payload.documents.map((doc: Document) => {
      if (doc.documentId === documentId) {
        doc.extraData = {
          ...doc.extraData,
          ocr_scanned_date_of_expiry: ocrInputValue,
        }
      }
      return doc
    })

    mutate({
      entityId,
      params: {
        noInvalidate: false,
        noNameDobValidation: false,
        saveOnly: false,
      },
      payload,
    })
    setFocused(false)
  }
  const onClear = () => {
    setOcrInputValue(ocrExpiryDate || null)
    setFocused(false)
  }
  return (
    <div className="ml-[25%] flex  flex-col justify-center items-center mb-2 mx-2 bg-tertiary-grey-100 text-tertiary-grey-500 rounded-sm p-4  w-3/4">
      <div className="text-xs text-center">{t('ocrExpiryHeader')}</div>
      <fieldset>
        <InputLabel className="mt-5 mb-1.5 text-xs">
          {t('ocrExpiryDateLabel')}
        </InputLabel>
        <TextField
          size="small"
          className="!bg-mono-white min-w-[180px] max-w-[180px]"
          value={ocrInputValue || ''}
          onBlur={e => {
            if (e.relatedTarget?.tagName !== 'BUTTON') {
              if (isIdle) {
                setOcrInputValue(ocrExpiryDate)
              }
              setFocused(false)
            }
          }}
          onFocus={() => setFocused(true)}
          onChange={e => setOcrInputValue(e.target.value)}
          InputProps={{
            endAdornment: focused && (
              <InputAdornment position="end">
                <FrankieButton
                  noStyles
                  onClick={onClear}
                  singleIcon={{
                    name: 'mdiCloseCircle',
                    className: 'text-sm text-tertiary-grey-500',
                  }}
                />
                {ocrExpiryDate !== ocrInputValue && (
                  <FrankieButton
                    noStyles
                    onClick={onSubmit}
                    singleIcon={{
                      name: 'mdiCheckCircle',
                      className: 'text-sm text-primary-400',
                    }}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
      </fieldset>
    </div>
  )
}

export function DocumentInfo({
  applicantData,
  isLegacyProfile,
  testId,
}: Props) {
  const t = useI18n(APPLICANT_PERSONAL_INFO_KEYS, {
    keys: applicantPersonalInfoEn,
  })

  const tDocs = useI18n([DOCUMENT_KEY], { keys: documentEn })

  const { checkResults, kycMethod } = applicantData.checkSummary
  const isManualKYCPass = checkManualKYCPass(kycMethod, checkResults)
  const isEntityPassed = applicantData.checkSummary.status.key === 'PASS'
  const isManualKYC = applicantData.checkSummary.kycMethod === 'manual'

  function convertIDTypeToHumanReadable(idType: string) {
    if (!idType) return ''
    const arrStr = idType.toLowerCase().split('_')
    const upperCaseFirstLetter = (str: string) => {
      if (str === 'id') return 'ID'
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
    return arrStr.map(upperCaseFirstLetter).join(' ')
  }

  const documentInfo: DocumentInfoTypes[] = useMemo(() => {
    const { documents } = applicantData
    if (!documents.length) return []

    return documents
      .filter(document => document.idType !== AdditionalDocumentsTypes.REPORT)
      .map((document: Document) => {
        const vevoData = getVevoData(document, t)
        const isVisaExpired = !!vevoData?.some(i => i.value === 'Expired')
        const isVisaClassRejected = !!document.extraData?.['visa.blocked']
        const isVisaNoMatch = document.extraData?.['visa.no_match'] === 'true'

        const getOverrideDataWhenVisaIsNoMatch = () => ({
          isVerified: false,
          overrideVerifiedText: null,
          visaIssues: [],
          checkSource: null,
          checkCount: 0,
          vevoData: null,
        })

        const overrideData = isVisaNoMatch
          ? getOverrideDataWhenVisaIsNoMatch()
          : {}

        const visaIssues = getVisaIssueMessages(
          {
            isVisaExpired,
            isVisaClassRejected,
          },
          t,
        )
        const isDocumentHasIssues = !![...visaIssues].length
        const isVerified = getDocumentStatus(
          document,
          isDocumentHasIssues,
          kycMethod,
        )

        const checkSource =
          applicantData.checkSummary.checkCounts.document?.[
            document.documentId
          ] ?? null

        const checkCount = checkSource?.length ?? 0

        const getOcrExpiryDate = (document: Document) => {
          const { extraData } = document
          if (extraData?.ocr_scanned_date_of_expiry) {
            return formatDate(
              extraData.ocr_scanned_date_of_expiry,
              DateFormatTypes.DateNumbers,
            )
          }
          return null
        }
        return {
          label: convertIDTypeToHumanReadable(document.idType),
          icon: getDocIcon(document.idType as DocumentIdTypes),
          idType: document.idType as DocumentIdTypes,
          content: getDocumentDetails(
            document,
            t,
            applicantData.applicantDetails.name,
          ),
          vevoData,
          visaIssues,
          documentId: document.documentId,
          ocrExpiryDate: getOcrExpiryDate(document),
          isVerified,
          isVisaExpired,
          checkSource,
          checkCount,
          ...overrideData,
        }
      })
      .sort(doc => sortDocByVerifiedStatus(doc))
  }, [applicantData, kycMethod, t, tDocs])

  return (
    <div className="text-tertiary-grey-700" data-qa={testId?.container}>
      {!!documentInfo.length && (
        <div>
          <div className="text-xl font-[700] mt-8 mb-6">
            {t('documentInfo.title')}
          </div>

          <div>
            {documentInfo.map((doc, idx) => (
              <ApplicantDetailView
                isLast={documentInfo.length === idx + 1}
                key={doc.idType}
                label={doc.label}
                iconName={doc.icon}
                isVerified={doc.isVerified}
                checkSource={doc.checkSource}
                checkCount={doc.checkCount}
                vevoData={doc.vevoData}
                isEntityPassed={isEntityPassed}
                isManualKYCPass={isManualKYCPass}
                isManualKYC={isManualKYC}
                isLegacyProfile={isLegacyProfile}
                type={DetailViewTypes.DOCUMENT_INFO}
                renderAdditionalInfo={
                  <FieldView
                    content={doc.content}
                    documentId={doc.documentId}
                    testId={{
                      button: documentInfoQa.button,
                      overlay: documentInfoQa.document,
                    }}
                  />
                }
                openAdditionalInfo={
                  doc.ocrExpiryDate && (
                    <OcrUpdate
                      ocrExpiryDate={doc.ocrExpiryDate}
                      documentId={doc.documentId}
                      entityId={applicantData.applicantDetails.entityId}
                    />
                  )
                }
                testId={{ container: documentInfoQa.applicantDetailView }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
