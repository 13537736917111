import { useMutation } from '@tanstack/react-query'

import { useGetWorkflowEventsData } from 'entities/entity'
import { useAuditReportData } from 'entities/individual-audit-report-f2'

import { individualResultsAPI } from '../api/individual-results.api'
import { MutateIndividualResultsType } from '../model/individual-results.model'

export enum IndividualResultsTypes {
  IDV = 'idv',
  AML = 'aml',
}

type Args = {
  entityId: string
  type: IndividualResultsTypes
}

export const useUpdateIndividualResultsMutation = ({
  entityId,
  type,
}: Args) => {
  const { refetch } = useGetWorkflowEventsData({ entityId })
  const { refetch: refetchComments } = useAuditReportData({ entityId })

  return useMutation({
    mutationFn: async (data: MutateIndividualResultsType) => {
      const res = await individualResultsAPI.updateIndividualResult(
        entityId,
        type,
        data,
      )
      return res.data
    },
    onSuccess: () => {
      void refetch()
      void refetchComments()
    },
  })
}
