import { useMemo } from 'react'

import { ApplicantId, useApplicantDataQuery } from 'entities/applicant'
import { useGetDocumentScan } from 'entities/document'

import {
  BiometryBase64Scan,
  DocumentBase64Scan,
  convertIDTypeToHumanReadable,
  countDescendants,
} from '../../model/applicant-biometrics-ocr.model'
import { useApplicantBiometricsOcrQuery } from '../applicant-biometrics-ocr-query/applicant-biometrics-ocr.query'

type Args = {
  applicantId?: ApplicantId
  index: number
}

/**
 * Facial recognition biometry state
 */
export const useApplicantBiometryState = ({ applicantId, index }: Args) => {
  const { data: biometryData, isLoading } = useApplicantBiometricsOcrQuery({
    applicantId,
  })

  const biometryReportData = useMemo(() => {
    const biometry = biometryData?.biometry[index]

    if (biometry) {
      biometry.items.sort((a, b) => a.title.localeCompare(b.title))
    }

    return biometry
  }, [biometryData?.biometry, index])

  const { data: biometricFacialScan, isLoading: loadingScan } =
    useGetDocumentScan({
      documentId: biometryReportData?.documentId,
    })

  /**
   * Facial document image or  video
   */
  const biometryBase64 = useMemo(
    () =>
      (biometricFacialScan ?? []).reduce((acc, scan) => {
        if (scan.mimeType && /video/.test(scan.mimeType)) {
          return { ...acc, video: scan }
        }
        return { ...acc, image: scan }
      }, {} as BiometryBase64Scan),
    [biometricFacialScan],
  )

  /**
   * Document Type
   */
  const documentType = useMemo(
    () => convertIDTypeToHumanReadable(biometryReportData?.referenceDocType),
    [biometryReportData?.referenceDocType],
  )

  return {
    data: {
      biometryReportData,
      biometryBase64,
      documentType,
      facialDuplicatesResult: biometryData?.facialDuplicateResult.toLowerCase(),
    },
    loadingScan: loadingScan || isLoading,
    loading: isLoading,
  }
}

/**
 * Document details state
 */
export const useApplicantBiometricsOcrDocumentState = ({
  applicantId,
  index,
}: Args) => {
  const { data: applicantData, isLoading: loadingApplicantData } =
    useApplicantDataQuery({ applicantId })

  const { data: biometryData, isLoading } = useApplicantBiometricsOcrQuery({
    applicantId,
  })

  const biometryReportData = biometryData?.biometry[index]

  const driverLicenseDocument = useMemo(
    () =>
      applicantData?.documents.find(doc => doc.idType === 'DRIVERS_LICENCE'),
    [applicantData?.documents],
  )

  /**
   * Document details data
   */
  const documentDetailsData = useMemo(() => {
    const applicantDocuments = applicantData?.documents ?? []
    const biometricDocuments =
      biometryData?.documentDetails.sort((a, b) =>
        b.checkDate.localeCompare(a.checkDate),
      ) ?? []

    const data = biometricDocuments.find(applicantDoc => {
      const appDoc = applicantDocuments.find(
        biometryDoc => applicantDoc.documentId === biometryDoc.documentId,
      )

      return appDoc
    })

    if (data && !data.checkResultItems) {
      data.checkResultItems = [
        {
          key: 'report.compromised_document',
          value: true,
          title: 'Compromised Document',
          items: [
            {
              key: 'report.compromised_document.title',
              value: true,
              title: 'Document has not been found in our database',
              items: [],
            },
          ],
        },
      ]
    }

    if (data?.checkResultItems) {
      data.checkResultItems.sort(
        (a, b) => countDescendants(b) - countDescendants(a),
      )
    }

    return data
  }, [
    applicantData?.documents,
    biometryData?.documentDetails,
    biometryReportData?.referenceDocType,
  ])

  const { data: ocrDocumentScan, isLoading: loadingScan } = useGetDocumentScan({
    documentId: documentDetailsData?.documentId,
  })

  /**
   * Document front and back base64
   */
  const documentBase64Scan = useMemo(() => {
    const scans = ocrDocumentScan?.sort(
      (a, b) => +new Date(b.scanCreated || 0) - +new Date(a.scanCreated || 0),
    )

    if (!scans?.length) {
      return {} as DocumentBase64Scan
    }

    const getScan = (scanSide: string) =>
      scans.find(
        ({ side, scanName }) =>
          side === scanSide && !scanName?.includes('portrait'),
      )

    return { front: getScan('F'), back: getScan('B') }
  }, [ocrDocumentScan])

  return {
    data: {
      documentDetailsData,
      documentBase64Scan,
      driverLicenseDocument,
    },
    loadingScan: loadingScan || isLoading || loadingApplicantData,
    loadingBiometry: isLoading || loadingApplicantData,
  }
}
