export const individualIdvCheckEn = {
  title: 'Document check',
  noIssues: 'No issues found.',
  manualFail: 'Manually failed',
  manualPass: 'Manually passed',
  resolve: 'Resolve',
  updateCheckStatus: 'Updating check status',
  showFullReport: 'Show full report',
  idScore: 'ID Score',
  ocrScore: 'OCR Score',
  documentIssues: 'Document report summary',
  score: '{{score}}%',
  manualPassStatus: 'Manually passed',
  manualFailStatus: 'Manually failed',
  viewAllOCR: 'View all OCR data',
  ocrSummaryTitle: 'OCR comparison summary',
  noOcrSummary:
    'A comparison was not required for this check. View full OCR extract via the link below.',
  form: {
    title: 'Resolve document check status',
    subTitle: 'This will manually override the status for this check.',
    selectLabel: 'Override outcome to',
    commentLabel: 'Comment',
    cancel: 'Cancel',
    changeStatus: 'Change status',
    successMessage: 'Check status has been successfully updated.',
    errorMessage: 'Check status could not be updated at this time.',
  },
  photoPage: 'Photo Page',
  front: 'Front',
  back: 'Back',
  modal: {
    titleComparison: 'OCR Comparison: {{documentType}}',
    passportPhotoPage: 'Passport: Photo page',
    uploadedOn: 'Image uploaded on {{date}} at {{time}} {{via}}',
    updatedOn: 'OCR results updated on {{date}} at {{time}}',
    ocrOutput: 'OCR output',
    ocrOutputTooltip:
      'Only key OCR data will be compared against entity data. Mismatches for this data will be highlighted in red, and others will be skipped.',
    entityData: 'Entity Data',
  },
  documentType: {
    DRIVERS_LICENSE: 'Driver’s Licence',
    PASSPORT: 'Passport',
    NATIONAL_HEALTH_ID: 'Medicare',
    NATIONAL_ID: 'National ID',
    BIRTH_CERT: 'Birth Certificate',
    TAX_ID: 'Tax ID',
    OTHER: 'Unrecognized document',
  },
}
