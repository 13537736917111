import React, { useState } from 'react'

import { Collapse } from '@mui/material'
import classNames from 'classnames'

import {
  FrankieBadge,
  FrankieButton,
  FrankieIcon,
  FrankieTooltip,
} from 'frankify/src'

import { documentRowQa } from 'features/individual-idv-check/qa/individual-idv-check.qa'

import { DocumentThumbs } from 'shared/document-thumbs'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, useTrackingShowEvents } from 'shared/tracking'

import { ReportSection } from './report-section'
import { INDIVIDUAL_IDV_CHECK_KEY } from '../../individual-idv-check.key'
import { individualIdvCheckEn } from '../../locale/individual-idv-check.en'
import { DocumentInfo } from '../../state/individual-idv-check/individual-idv-check.state'
import { IndividualIdvModal } from '../individual-idv-modal/individiual-idv-modal'

export const REACT_SIDEBAR_CUSTOM_EVENT = 'react-portal-sidebar'
export const REACT_SIDEBAR_CUSTOM_EVENT_DETAIL = 'isOpen'

type Props = {
  documentInfo: DocumentInfo
}

export function IDVDocumentRow({ documentInfo }: Props) {
  const { title, values, report, attachments, failingReports } = documentInfo
  const t = useI18n([INDIVIDUAL_IDV_CHECK_KEY], { keys: individualIdvCheckEn })

  const [open, setOpen] = useState(false)

  useTrackingShowEvents({
    data: open,
    eventsType: TrackingEventsTypes.WorkflowEventsDocumentFullReportShow,
  })

  const failedCount = failingReports.length

  const [createOverlay] = useOverlay()

  const handleExpand = (idx?: number) => {
    createOverlay(
      <IndividualIdvModal documentInfo={documentInfo} defaultIdx={idx} />,
      {
        closeButtonClassName: '!top-6 !right-6',
        className: '!p-6',
      },
    )
  }
  const Thumbnail = (
    <div className="desktop:w-64 w-36">
      <DocumentThumbs
        gallery={attachments || []}
        expandable={documentInfo.flags.ocrSummary}
        handleExpand={handleExpand}
        trackingEvents={[
          TrackingEventsTypes.WorkflowEventsDocumentFront,
          TrackingEventsTypes.WorkflowEventsDocumentBack,
        ]}
      />
    </div>
  )

  return (
    <div className="flex flex-col gap-3">
      <div className="text-md text-tertiary-grey-800 gap-2 font-semibold flex items-center">
        {t(
          `documentType.${
            title as keyof typeof individualIdvCheckEn.documentType
          }`,
        )}
      </div>

      <div className="flex flex-col tablet:flex-row gap-2">
        {documentInfo.flags.ocrSummary ? (
          <div className="flex-[7] flex gap-6">
            {Thumbnail}
            <div className="flex flex-col gap-3">
              <h3 className="font-semibold text-tertiary-grey-800 text-sm mb-1">
                {t('ocrSummaryTitle')}
              </h3>
              {values.length > 0 ? (
                values.map(value => (
                  <div key={value.label} className="flex">
                    <p className="text-tertiary-grey-800 w-40 font-medium shrink-0">
                      {value.label}
                    </p>
                    <div className="inline">
                      <span className="text-tertiary-grey-700 break-words">
                        {value.value}
                        {documentInfo.flags.ocrComparison && (
                          <FrankieIcon
                            name={
                              value.success
                                ? 'mdiCheckCircle'
                                : 'mdiCloseCircle'
                            }
                            size="xs"
                            className={classNames(
                              'inline-block align-text-top ml-1',
                              value.success
                                ? 'text-tertiary-green-600'
                                : 'text-tertiary-red-700',
                            )}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <p className="max-w-[284px]">{t('noOcrSummary')}</p>
              )}
              <FrankieButton
                noStyles
                className="text-left font-semibold self-start text-primary-800 mt-1"
                onClick={() => handleExpand(0)}
              >
                {t('viewAllOCR')}
              </FrankieButton>
            </div>
          </div>
        ) : (
          Thumbnail
        )}
        {documentInfo.flags.reportSummary && (
          <div
            className={classNames(
              'flex-[5] pl-6',
              documentInfo.flags.ocrSummary &&
                'border-tertiary-grey-200 border-l-solid border-l',
            )}
          >
            <p className="font-semibold text-tertiary-grey-800 mb-2">
              {t('documentIssues')}
            </p>

            <div className="flex flex-wrap gap-2">
              {!failedCount && t('noIssues')}

              {failingReports.map(section => (
                <FrankieTooltip
                  key={section.title}
                  position="top"
                  title={section.title}
                >
                  <FrankieBadge
                    theme="red"
                    text={section.title}
                    testId={{
                      badge: documentRowQa.issueBadge(section.key),
                    }}
                  />
                </FrankieTooltip>
              ))}
            </div>
          </div>
        )}
      </div>
      {documentInfo.flags.reportSummary && (
        <>
          <div className="flex gap-2 py-2">
            {!!failedCount && (
              <div className="bg-tertiary-red-700 text-mono-white  text-center rounded-full aspect-square h-5 w-5">
                {failedCount}
              </div>
            )}

            <div className="font-semibold text-tertiary-grey-800">
              {t('showFullReport')}
            </div>

            <FrankieButton
              onClick={() => setOpen(o => !o)}
              noStyles
              singleIcon={{
                name: open ? 'mdiChevronUp' : 'mdiChevronDown',
                size: 'xs',
              }}
            />
          </div>
          <Collapse className={open ? 'mb-2' : '-mb-4'} in={open}>
            <ReportSection report={report} />
          </Collapse>
        </>
      )}
    </div>
  )
}
