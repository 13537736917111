import React, { useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'
import { useNavigate, useOutletContext } from 'react-router-dom'

import {
  FrankieButton,
  FrankieLoader,
  FrankieVerticalTabs,
  Banner,
} from 'frankify/src'

import { CommentHOC, CommentsTypes } from 'entities/comments'
import { useFilteredAttachments } from 'entities/document-f2'
import {
  IDocsToBeDisplayedType,
  useEntityDataQuery,
  useFrankie2R2Customer,
  useGetWorkflowEventsData,
} from 'entities/entity'
import { PermissionTypes } from 'entities/role'
import { useHasFeatureFlag, useHasPermission } from 'entities/session'

import { useTriggerState } from 'shared/hooks'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { INDIVIDUAL_PROFILE_INSIGHT_KEY } from '../../individual-profile-insight.key'
import { individualProfileInsightEn } from '../../locale/individual-profile-insight.en'
import { ProfileTabTypes } from '../../model/individual-profile-insight.model'
import { IndividualProfileInsightQa } from '../../qa/individual-profile-insight.qa'
import { useInvalidationBanner } from '../../state/invalidate-banner.state'
import { IndividualProfileAml } from '../individual-profile-aml/individual-profile-aml'
import { IndividualProfileDocument } from '../individual-profile-document/individual-profile-documents'
import { IndividualProfileDocumentR2 } from '../individual-profile-document/individual-profile-documents-f2-r2'
import { IndividualProfilePersonalInfo } from '../individual-profile-personal-info/individual-profile-personal-info'
import { IndividualProfileSelfie } from '../individual-profile-selfie/indvidual-profile-selfie'
import { IndividualProfileVerify } from '../individual-profile-verify/individual-profile-verify'

type Props = {
  entityId: string
  editEntityPathWithID: string
}

const clickEventMapper = {
  [ProfileTabTypes.personalInfo]:
    TrackingEventsTypes.ProfileInsightsPersonalInfoClickIndividual,
  [ProfileTabTypes.documents]:
    TrackingEventsTypes.ProfileInsightsIdentityDocumentsClickIndividual,
  [ProfileTabTypes.aml]: TrackingEventsTypes.AMLProfileSideNavClick,

  [ProfileTabTypes.selfies]:
    TrackingEventsTypes.ProfileInsightsSelfieClickIndividual,
}

type BannerProps = {
  editEntityPathWithID: string
  isArchived: boolean
  isBannerVisible: boolean
}

function InvalidationBanner({
  editEntityPathWithID,
  isArchived,
  isBannerVisible,
}: BannerProps) {
  const navigate = useNavigate()
  const t = useI18n([INDIVIDUAL_PROFILE_INSIGHT_KEY], {
    keys: individualProfileInsightEn,
  })

  const { editIndividualPermission } = useHasPermission({
    editIndividualPermission: PermissionTypes.ManualIdvUpdateAll,
  })

  if (!isBannerVisible) return null

  const button = editIndividualPermission
    ? {
        name: 'generate-report',
        label: t('banner.button'),
        onClick: () => !isArchived && navigate(editEntityPathWithID),
      }
    : undefined

  return (
    <Banner
      heading={t('banner.title')}
      description={t('banner.description')}
      theme="warning"
      className="border-0"
      button={button}
    >
      <p>{t('banner.description')}</p>
      {!editIndividualPermission && (
        <div className="mt-3 text-tertiary-grey-500 text-xs">
          {t('banner.noPermission')}
        </div>
      )}
    </Banner>
  )
}

function InvalidationBannerR2({
  editEntityPathWithID,
  isArchived,
  isBannerVisible,
  entityId,
}: BannerProps & { entityId: string }) {
  const navigate = useNavigate()
  const [createOverlay] = useOverlay()
  const t = useI18n([INDIVIDUAL_PROFILE_INSIGHT_KEY], {
    keys: individualProfileInsightEn,
  })

  const { editIndividualPermission } = useHasPermission({
    editIndividualPermission: PermissionTypes.ManualIdvUpdateAll,
  })

  if (!isBannerVisible) return null

  const button = editIndividualPermission
    ? {
        name: 'generate-report',
        label: t('banner.modal.buttonVerify'),
        onClick: () =>
          !isArchived &&
          createOverlay(<IndividualProfileVerify entityId={entityId} />, {
            className: '!p-5 ',
            closeButtonClassName: '!top-5 !right-5',
          }),
      }
    : undefined

  const buttonSecondary = editIndividualPermission
    ? {
        name: 'generate-report',
        label: t('banner.buttonEdit'),
        onClick: () => !isArchived && navigate(editEntityPathWithID),
      }
    : undefined

  return (
    <Banner
      heading={t('banner.title')}
      description={t('banner.description')}
      theme="warning"
      className="border-0"
      button={button}
      buttonSecondary={buttonSecondary}
    >
      <p>{t('banner.description')}</p>
      {!editIndividualPermission && (
        <div className="mt-3 text-tertiary-grey-500 text-xs">
          {t('banner.noPermission')}
        </div>
      )}
    </Banner>
  )
}

function UncheckedBanner({
  editEntityPathWithID,
  isArchived,
  isBannerVisible,
  entityId,
}: BannerProps & { entityId: string }) {
  const navigate = useNavigate()
  const [createOverlay] = useOverlay()
  const t = useI18n([INDIVIDUAL_PROFILE_INSIGHT_KEY], {
    keys: individualProfileInsightEn,
  })

  const { editIndividualPermission } = useHasPermission({
    editIndividualPermission: PermissionTypes.ManualIdvUpdateAll,
  })

  const button = editIndividualPermission
    ? {
        className: '!mb-0',
        name: 'verify-profile',
        label: t('banner.modal.buttonVerify'),
        onClick: () =>
          !isArchived &&
          createOverlay(<IndividualProfileVerify entityId={entityId} />, {
            className: '!p-5 ',
            closeButtonClassName: '!top-5 !right-5',
          }),
      }
    : undefined

  const buttonSecondary = editIndividualPermission
    ? {
        className: '!mb-0',
        name: 'generate-report',
        label: t('banner.buttonEdit'),
        onClick: () => !isArchived && navigate(editEntityPathWithID),
      }
    : undefined

  if (!isBannerVisible) return null

  return (
    <Banner
      heading={t('banner.titleUnchecked')}
      description={t('banner.descriptionUnchecked')}
      theme="warning"
      className="border-0 !w-full"
      button={button}
      buttonSecondary={buttonSecondary}
    >
      <p>{t('banner.descriptionUnchecked')}</p>
      {!editIndividualPermission && (
        <div className="mt-3 text-tertiary-grey-500 text-xs">
          {t('banner.noPermission')}
        </div>
      )}
    </Banner>
  )
}

export function IndividualProfileInsight({
  entityId,
  editEntityPathWithID,
}: Props) {
  const t = useI18n([INDIVIDUAL_PROFILE_INSIGHT_KEY], {
    keys: individualProfileInsightEn,
  })

  const { ref } = useOutletContext<{
    ref: React.RefObject<HTMLDivElement | null>
  }>()

  const [activeTab, setActiveTab] = useState(ProfileTabTypes.personalInfo)

  const [open, setOpen] = useState(true)
  const [scrollDisabled, setScrollDisabled] = useTriggerState(500)

  const handleTabChange = (value: string) => {
    trackingManager.trackWithProps(clickEventMapper[value as ProfileTabTypes], {
      tab: value,
    })

    setActiveTab(value as ProfileTabTypes)
    setScrollDisabled()
  }

  const { data, isLoading: dataLoading } = useEntityDataQuery(
    entityId,
    'base64',
  )

  const isFrankie2R2 = useFrankie2R2Customer()
  const { hasIdvCheck } = useHasFeatureFlag({
    hasIdvCheck: ['idvChecks'],
  })

  const { data: workflowData } = useGetWorkflowEventsData({ entityId })

  const {
    isInvalidationBannerVisible,
    isPersonalInfoInvalid,
    isDocumentInvalid,
    isUnchecked,
    isLoading: isBannerLoading,
  } = useInvalidationBanner({ entityId })

  const { selfies } = useFilteredAttachments(
    data?.individual?.documents?.IDENTITY as IDocsToBeDisplayedType[],
  )

  const tabItemsComponent: Record<
    ProfileTabTypes,
    { comp: React.ReactNode; isHidden: boolean; isInvalidated?: boolean }
  > = useMemo(
    () => ({
      [ProfileTabTypes.personalInfo]: {
        comp: (
          <IndividualProfilePersonalInfo
            entityId={entityId}
            isInvalidated={isPersonalInfoInvalid}
          />
        ),
        isHidden: false,
        isInvalidated: isPersonalInfoInvalid,
      },
      [ProfileTabTypes.documents]: {
        comp: isFrankie2R2 ? (
          <IndividualProfileDocumentR2
            entityId={entityId}
            isInvalidated={isDocumentInvalid}
          />
        ) : (
          <IndividualProfileDocument
            entityId={entityId}
            isInvalidated={isDocumentInvalid}
          />
        ),
        isInvalidated: isDocumentInvalid,
        isHidden: (data?.individual?.documents?.IDENTITY?.length ?? 0) === 0,
      },
      [ProfileTabTypes.selfies]: {
        comp: <IndividualProfileSelfie entityId={entityId} />,
        isHidden:
          !hasIdvCheck ||
          !isFrankie2R2 ||
          selfies.length === 0 ||
          !workflowData?.workflowSummaries?.[0]?.workflowResultData?.workflowStepResults?.find(
            step => step.stepName === 'IDV',
          ),
      },
      [ProfileTabTypes.aml]: {
        comp: <IndividualProfileAml entityId={entityId} />,
        isInvalidated: isDocumentInvalid,
        isHidden:
          !workflowData?.workflowSummaries?.[0]?.workflowResultData?.workflowStepResults?.find(
            step => step.stepName === 'AML',
          ) || !isFrankie2R2,
      },
    }),

    [
      entityId,
      isPersonalInfoInvalid,
      isFrankie2R2,
      isDocumentInvalid,
      data?.individual?.documents?.IDENTITY?.length,
      hasIdvCheck,
      selfies.length,
      workflowData?.workflowSummaries,
    ],
  )

  function handleScroll(this: unknown, e: HTMLElementEventMap['scroll']) {
    if (scrollDisabled) return
    // Calculate which section is in view and set it as active
    const scrollPosition = (ref.current?.scrollTop ?? 0) + 100 // adjust offset as needed
    const sections = Object.values(ProfileTabTypes)
    for (const section of sections) {
      const domEl = document.getElementById(section)

      if (domEl) {
        const top = domEl.offsetTop
        const height = domEl.offsetHeight
        if (scrollPosition >= top && scrollPosition < top + height) {
          setActiveTab(section as ProfileTabTypes)
          return
        }
      }
    }
  }

  const isArchived = data?.serviceProfiles?.at(0)?.state === 'ARCHIVED'

  useEffect(() => {
    const divRef = ref.current

    divRef?.addEventListener<'scroll'>('scroll', handleScroll)
    return () => {
      divRef?.removeEventListener('scroll', handleScroll)
    }
  }, [ref, scrollDisabled])

  useEffect(() => {
    trackingManager.trackWithProps(
      TrackingEventsTypes.ProfileInsightsViewIndividual,
      {
        entityId,
        status: data?.serviceProfiles?.at(0)?.state,
      },
    )
  }, [entityId, data])

  const tabItems = useMemo(
    () =>
      Object.keys(ProfileTabTypes)
        .map((key, index) => ({
          value: key,
          label: t(`tab.${key as keyof typeof ProfileTabTypes}`),
          link: `#${ProfileTabTypes[key as keyof typeof ProfileTabTypes]}`,
          component: tabItemsComponent[key as ProfileTabTypes].comp,
          isHidden: tabItemsComponent[key as ProfileTabTypes].isHidden,
          isInvalidated:
            tabItemsComponent[key as ProfileTabTypes].isInvalidated,
          testId: `${IndividualProfileInsightQa.tabItem}-${index}`,
        }))
        .filter(tab => !tab.isHidden),
    [t, tabItemsComponent],
  )

  return (
    <FrankieLoader
      loading={dataLoading || isBannerLoading}
      className="z-20 min-h-full !h-max grow"
    >
      <CommentHOC
        entityId={entityId}
        commentType={CommentsTypes.PROFILE_PERSONAL_INFO}
      >
        <div
          className="items-start gap-6 flex"
          data-qa={IndividualProfileInsightQa.container}
        >
          <div
            className={`sticky top-0 transition-[width] ease-out duration-200 ${
              !open ? 'min-w-[32px] overflow-hidden' : 'w-[190px] '
            }`}
          >
            <div
              className="flex gap-5 text-xl text-secondary-900 font-bold pb-2"
              data-qa={IndividualProfileInsightQa.title}
            >
              {open && t('title')}
              <div className=" ml-auto ">
                <FrankieButton
                  noStyles
                  className={`flex p-1.5 justify-center items-center text-tertiary-grey-500
                    w-8 h-8 rounded-sm
                    active:outline
                    focus-visible:outline
                    outline-mono-white
                    focus-visible:outline-3
                    active:outline-3
                    hover:bg-tertiary-grey-100
                    hover:text-tertiary-grey-800
                    active:bg-primary-50
                    focus-visible:bg-primary-50
                    active:text-tertiary-grey-800
                    focus-visible:text-tertiary-grey-800`}
                  singleIcon={{
                    name: open ? 'mdiArrowCollapseLeft' : 'mdiArrowExpandRight',
                    size: 'sm',
                  }}
                  testId={{ button: IndividualProfileInsightQa.button }}
                  onClick={() => setOpen(o => !o)}
                />
              </div>
            </div>
            {open && (
              <FrankieVerticalTabs
                activeTab={activeTab}
                onTabChange={handleTabChange}
                tabItems={tabItems}
              />
            )}
          </div>
          <div
            className={classNames(
              'flex flex-col',
              open ? 'max-w-[calc(100%-214px)]' : 'max-w-[calc(100%-50px)]',
            )}
          >
            {isArchived && (
              <Banner
                heading={t('banner.archivedTitle')}
                theme="info"
                className="!pb-1.5"
              />
            )}

            {isFrankie2R2 ? (
              <InvalidationBannerR2
                isBannerVisible={isInvalidationBannerVisible}
                editEntityPathWithID={editEntityPathWithID}
                isArchived={isArchived}
                entityId={entityId}
              />
            ) : (
              <InvalidationBanner
                isBannerVisible={isInvalidationBannerVisible}
                editEntityPathWithID={editEntityPathWithID}
                isArchived={isArchived}
              />
            )}

            <UncheckedBanner
              isBannerVisible={isUnchecked && isFrankie2R2}
              editEntityPathWithID={editEntityPathWithID}
              isArchived={isArchived}
              entityId={entityId}
            />

            <div
              className="flex flex-col gap-y-6"
              data-qa={IndividualProfileInsightQa.tabItemContainer}
            >
              {tabItems.map(tab => (
                <div
                  key={tab.value}
                  id={tab.value}
                  className={classNames(
                    'border p-6 pt-5 rounded-sm border-tertiary-grey-200 border-solid min-w-[660px]',
                    tab.isInvalidated && '!border-tertiary-yellow-300',
                  )}
                >
                  {tab.component}
                </div>
              ))}
            </div>
          </div>
        </div>
      </CommentHOC>
    </FrankieLoader>
  )
}
