import React, { ReactNode, memo } from 'react'

import classNames from 'classnames'

import { useFrankie2R2Customer } from 'entities/entity'
import { useRouteMatcher } from 'entities/routing'

import { ExpandedCommentBox } from './expanded-comment-box'
import { CommentsTypes } from '../../model/comments.model'
import {
  useCommentBoxVisibility,
  useCommentData,
} from '../../states/comments-states/comments.state'

export const CommentHOC = memo(
  ({
    children,
    className = '',
    entityId,
    commentType,
    processId,
  }: {
    children: ReactNode
    className?: string
    entityId: string
    commentType: CommentsTypes
    processId?: string
  }) => {
    const { data } = useCommentBoxVisibility({ entityId })
    const isFrankie2R2 = useFrankie2R2Customer()
    const { data: commentData } = useCommentData({
      entityId,
      commentType,
      processId,
    })

    const isWorkflowTab = useRouteMatcher({
      pathKey: 'applicantProfileWorkflowEvent',
      entityId,
    })

    const amlAlertVariants = [
      CommentsTypes.AMLRESULT,
      CommentsTypes.AMLHISTORY,
      CommentsTypes.AMLSCREENING,
    ]
    const shouldAmlAlertCommentShow =
      amlAlertVariants.includes(commentType) && isWorkflowTab

    const isCommentVisible =
      isFrankie2R2 &&
      data?.[commentType] &&
      !shouldAmlAlertCommentShow &&
      commentData.length

    return (
      <div className={`flex gap-4 grow-0 justify-between h-full ${className}`}>
        <div
          className={classNames('w-full grow-0', {
            'min-w-[calc(100%-216px)] desktop:min-w-[calc(100%-282px)] max-w-[calc(100%-266px)]':
              isCommentVisible,
          })}
        >
          {' '}
          {children}
        </div>

        {isCommentVisible ? (
          <ExpandedCommentBox
            commentType={commentType}
            entityId={entityId}
            processId={processId}
          />
        ) : null}
      </div>
    )
  },
)
CommentHOC.displayName = 'CommentHOC'
