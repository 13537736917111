import { useMemo } from 'react'

import { PathArray } from 'shared/typescript'

import { getIsFeatureFlagEnabled, PageData } from '../../model/session.model'
import { useSessionQuery } from '../../state/session/session.query'

/**
 * If the mapped key is available in PageData['features'], then
 * check if it is listed in `UndefinedKeys`, then return as boolean | undefined, else boolean
 * @example
 * // showVerticalNavBar will be boolean | undefined
 * // hasConSardine will be boolean
 * const { showVerticalNavBar, hasConSardine } = useHasFeatureFlag(
 *   {
 *     showVerticalNavBar: 'verticalNavBar',
 *     hasConSardine: ['connectors', 'con_sardine'],
 *   },
 *   { returnUndefinedFor: ['verticalNavBar'] }
 * )
 */
type FeatureFlagsReturnType<
  FeatureMap extends Record<
    string,
    keyof PageData['features'] | PathArray<PageData['features']>
  >,
  UndefinedKeys extends Array<keyof PageData['features']> = [],
> = {
  [K in keyof FeatureMap]: FeatureMap[K] extends keyof PageData['features']
    ? FeatureMap[K] extends UndefinedKeys[number]
      ? boolean | undefined
      : boolean
    : boolean
}

/**
 * Hook for managing feature flags
 * @description If the feature is nested, provide keys as an array; otherwise, provide a string.
 * @template T - Type representing the mapping of feature keys to either a single key or an array of nested keys.
 * @template U - Optional array of keys that should return undefined when not specified
 * @param actionMap - An object mapping feature keys to either a single key or an array of nested keys.
 * @param options - Optional configuration for feature flag behavior
 * @param options.returnUndefinedFor - Array of feature flags that should return undefined when not specified
 * @returns An object with keys from the input object and boolean/undefined values based on returnUndefinedFor option
 *
 * @example
 * const { showVerticalNavBar } = useHasFeatureFlag(
 *   {
 *     showVerticalNavBar: 'verticalNavBar',
 *     hasConSardine: ['connectors', 'con_sardine'],
 *   },
 *   { returnUndefinedFor: ['verticalNavBar'] }
 * )
 */
export function useHasFeatureFlag<
  FeatureFlagMap extends {
    [action: string]:
      | keyof PageData['features']
      | PathArray<PageData['features']>
  },
  UndefinedKeys extends Array<keyof PageData['features']> = never[],
>(
  actionMap: FeatureFlagMap,
  options?: {
    returnUndefinedFor?: UndefinedKeys
  },
): FeatureFlagsReturnType<FeatureFlagMap, UndefinedKeys> {
  const { data: session } = useSessionQuery()

  return useMemo(() => {
    const result = {} as FeatureFlagsReturnType<FeatureFlagMap, UndefinedKeys>
    if (!session?.features) return result

    Object.entries(actionMap).forEach(([key, feature]) => {
      result[key as keyof FeatureFlagMap] = getIsFeatureFlagEnabled(
        session,
        [feature],
        options,
      ) as FeatureFlagMap[typeof key] extends keyof PageData['features']
        ? FeatureFlagMap[typeof key] extends UndefinedKeys[number]
          ? boolean | undefined
          : boolean
        : boolean
    })

    return result
  }, [session, actionMap, options])
}
