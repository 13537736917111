import React, { useEffect, useRef, useState } from 'react'

import { Trans } from 'react-i18next'

import { FrankieButton, FrankieIcon } from 'frankify/src'

import { getDataFileUrl } from 'shared/file'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { DOCUMENT_KEY, documentEn } from '../../locale/document.en'
import {
  defaultSupportedFileTypes,
  scanDateSorter,
  scanSorter,
  SupportedFileTypes,
} from '../../model/document.model'
import { useGetDocumentScan } from '../../mutation/document-scans'
import { useUploadDocumentV2 } from '../../mutation/upload-document.mutation'

const FILENAME_MAX_LENGTH = 80

export type UploadedDocumentData = {
  fileUploadUuid: string
  mimeType: string
  scanCreated: string
  scanName: string
}

export type DocumentUploadV2Props = {
  className?: string
  name?: string
  uploadedOn?: string
  supportedFileTypes?: SupportedFileTypes[]
  fileSize?: number
  onChange: (data: UploadedDocumentData) => void
  error?: boolean
  documentId?: string
  fetchNewScan?: boolean
  testId?: { loader?: string | undefined; upload?: string | undefined }
  isLoading?: boolean
}

type ProgressBarProps = {
  progress: number
  className?: string
  testId?: string
}

export function ProgressBar({
  progress,
  className = '',
  testId,
}: ProgressBarProps) {
  return (
    <div className={`flex flex-col items-center gap-1 ${className}`}>
      <p className="text-xs font-medium text-tertiary-grey-500">{progress} %</p>
      <div className="w-full bg-gray-200 rounded-[2px] h-1.5 bg-tertiary-grey-200 ">
        <div
          data-qa={testId}
          className="bg-blue-600 h-1.5 rounded-[2px] bg-primary-600"
          style={{ width: `${progress}%`, transition: 'width 0.5s ease' }}
        />
      </div>
    </div>
  )
}

/**
 * Document Upload V2
 * @description Supports only pdf and images upload
 */
export function DocumentUploadV2({
  className = '',
  uploadedOn,
  supportedFileTypes = defaultSupportedFileTypes,
  fileSize = 25,
  onChange,
  name,
  error = false,
  documentId,
  fetchNewScan = false,
  isLoading = false,
  testId = {
    loader: '',
    upload: '',
  },
}: DocumentUploadV2Props) {
  const t = useI18n([DOCUMENT_KEY], { keys: documentEn })
  const ref = useRef<HTMLInputElement | null>(null)

  const [fileUrl, setFileUrl] = useState<string | null>()
  const [fileName, setFileName] = useState<string>()
  const { data: scans } = useGetDocumentScan({ documentId, fetchNewScan })

  const {
    uploadProgress,
    isUploading,
    mutate,
    error: uploadError,
  } = useUploadDocumentV2((data, variable) => {
    const { file } = variable
    const { type, name } = file
    const newDate = new Date()

    setFileUrl(URL.createObjectURL(file))
    setFileName(name)

    onChange({
      fileUploadUuid: data.id,
      mimeType: type,
      scanName: name,
      scanCreated: newDate.toISOString(),
    })
  })

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target

    if (!files?.length) return

    const file = files[0]
    const fileType = file.type
    const fileName = file.name

    // File Size Check
    if (file.size > +(fileSize * 1000000).toFixed(0)) {
      notification.error(
        t('message.invalidFileSize', {
          fileSize,
        }),
      )
      return
    }

    // File Type Check
    if (!supportedFileTypes.some(type => RegExp(type).test(fileType))) {
      notification.error(
        t('message.invalidFileTypeV2', {
          fileTypes: supportedFileTypes
            .map(item => item.split('/')[1])
            .join(', '),
        }),
      )

      return
    }

    // Unsafe File name check
    if (fileName.length > FILENAME_MAX_LENGTH || /[<>\\/'"`]/.test(fileName)) {
      notification.error(
        t('message.invalidFileName', { maxLength: FILENAME_MAX_LENGTH }),
      )

      return
    }
    setFileName(`${fileName}`)
    mutate({ file })
  }

  useEffect(() => {
    if (scans?.length) {
      const docScan = scans.sort(scanSorter).sort(scanDateSorter)
      if (!docScan[0].file) return

      setFileName(docScan[0].scanName)
      setFileUrl(getDataFileUrl(docScan[0].mimeType ?? '', docScan[0].file))
    }
  }, [scans])

  const onClickBrowseFile = () => {
    if (ref.current) {
      ref.current.click()
    }
  }

  return (
    <div
      className={`${className} relative w-full h-full min-w-[200px] min-h-[150px] items-center justify-center
       border-2 rounded-1.5 p-4  ${
         fileUrl
           ? 'border-solid border-neutral-40'
           : 'border-dashed border-tertiary-grey-200'
       } rounded-sm 
      ${
        error
          ? 'border-tertiary-red-700 border-solid '
          : ' hover:border-primary-500 hover:border-solid '
      }`}
    >
      <input
        className="absolute inset-0 opacity-0 z-[2] cursor-pointer"
        type="file"
        multiple={false}
        onChange={handleFileUpload}
        accept={supportedFileTypes.join(',')}
        onClick={e => {
          e.currentTarget.value = '' // to reset the file input so it can same file again
        }}
        ref={ref}
        data-qa={testId.upload}
      />
      {!fileUrl && !isUploading && (
        <div className="h-full p-4 gap-4 flex items-center justify-center flex-col">
          <div className="flex items-center justify-center flex-col gap-[6px]">
            <FrankieIcon
              library="mdi"
              name="mdiCloudUploadOutline"
              size="lg"
              className="text-tertiary-grey-400 !text-[40px]"
            />
            <p className="font-normal text-center text-tertiary-grey-500 ">
              <Trans components={{ span: <span className="font-semibold" /> }}>
                {t('v2.placeholder')}
              </Trans>
            </p>
            <p className="text-center text-tertiary-grey-500 text-xs font-semibold">
              <Trans>{t('v2.maxFileSize', { fileSize })}</Trans>
            </p>
          </div>
          <FrankieButton
            onClick={onClickBrowseFile}
            className="z-50"
            size="xs"
            type="button"
            intent="primary"
            startIcon={{ name: 'mdiMagnify', size: 'xs' }}
          >
            {t('v2.browseBtnLabel')}
          </FrankieButton>
        </div>
      )}
      {fileName && !uploadError && (
        <div className="flex flex-col items-center gap-6">
          <ProgressBar progress={uploadProgress} className="w-[278px]" />
          <p className="text-base font-medium text-tertiary-grey-700">
            {t('v2.uploading', { fileName })}
          </p>
        </div>
      )}
    </div>
  )
}
