import React, { useState } from 'react'

import { Document, DocumentProps, Page, PageProps } from 'react-pdf'

// eslint-disable-next-line import/no-unresolved
import 'react-pdf/dist/Page/AnnotationLayer.css'
// eslint-disable-next-line import/no-unresolved
import 'react-pdf/dist/Page/TextLayer.css'

type Props = {
  url: string
  documentProps?: DocumentProps
  pageProps?: PageProps
}

export function PdfPreview({ url, documentProps, pageProps }: Props) {
  const [numPages, setNumPages] = useState<number>()

  /** @see https://frankieone.atlassian.net/browse/FDEL-26473 - Pen test issue */
  const handleLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
    url: string,
  ) => {
    const currentOrigin = window.location.origin
    const parsedUrl = new URL(url, window.location.href)

    const linkDomain = parsedUrl.hostname
    const isExternal =
      !linkDomain.endsWith('frankiefinancial.io') &&
      !linkDomain.endsWith('frankieone.com') &&
      parsedUrl.origin !== currentOrigin

    if (isExternal) event.preventDefault()
  }

  return (
    <Document
      file={url}
      onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      className="grid bg-tertiary-grey-100 gap-4"
      externalLinkTarget="_blank"
      {...documentProps}
    >
      {Array.from(new Array(numPages), (_, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          width={pageProps?.width}
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
            const clickedElement = e.target as HTMLElement
            // tagName property is always returned in uppercase for HTML elements
            if (clickedElement.tagName === 'A') {
              const link = clickedElement as HTMLAnchorElement
              handleLinkClick(e, link.href)
            }
          }}
          {...pageProps}
        />
      ))}
    </Document>
  )
}
